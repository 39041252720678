import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css'; // Import Select2 CSS
import 'select2'; 
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import config from "../../common/Config";
import Swal from 'sweetalert2';

const AddUsers = ({ onUserAdded }) => {


	const selectRef = useRef(null);

	const modalRef = useRef(null);
	const accessToken = localStorage.getItem('accessToken');
  	const [roleOptions, setRoleOptions] = useState([]);
  	const [userRoles, setUserRoles] = useState([]);
  	const [errors, setErrors] = useState({});
  	const navigateToUserList = useNavigate(); 

  	const [formData, setFormData] = useState({
  		avatar 		: '',
		username 	: '',
		email 		: '',
		password 	: '',
		group 		: '',
		first_name 	: '',
		last_name 	: '',
		address 	: '',
		postcode 	: '',
		city 		: '',
		state 		: '',
		mobile 		: '',
		email 		: '',
		confpass 	: '',
  	});

  	const validateForm = () => {
    	const newErrors = {};

	    
	    if (!formData.first_name) {
	      newErrors.first_name = "Mention Name.";
	    } else if (formData.first_name.length < 2) {
	      newErrors.first_name = "name must consist of at least 2 characters.";
	    } else if (formData.first_name.length > 255) {
	      newErrors.first_name = "name must not exceed 255 characters.";
	    }

	    if (!formData.last_name) {
	      newErrors.last_name = "Mention Name.";
	    } else if (formData.last_name.length < 2) {
	      newErrors.last_name = "name must consist of at least 2 characters.";
	    } else if (formData.last_name.length > 255) {
	      newErrors.last_name = "name must not exceed 255 characters.";
	    }

	    // Email validation
	    if (!formData.email) {
	      newErrors.email = "Mention Email Id.";
	    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
	      newErrors.email = "Please enter a valid email.";
	    }

	    // Phone validation
	    if (!formData.mobile) {
	      newErrors.mobile = "Phone is Required.";
	    } else if (!/^\d{10,12}$/.test(formData.mobile)) {
	      newErrors.mobile = "Phone number must be between 10 and 12 digits.";
	    }

	    if (!formData.username) {
	      newErrors.username = "Mention Name.";
	    } else if (formData.username.length < 2) {
	      newErrors.username = "name must consist of at least 2 characters.";
	    } else if (formData.username.length > 255) {
	      newErrors.username = "name must not exceed 255 characters.";
	    }

	    if (!formData.password) {
	      newErrors.password = "Mention Password.";
	    } else if (formData.password.length < 2) {
	      newErrors.password = "Password must consist of at least 2 characters.";
	    } else if (formData.password.length > 255) {
	      newErrors.password = "Password must not exceed 255 characters.";
	    }

	    if (!formData.confpass) {
        	newErrors.confpass = "Please confirm your password.";
	    } else if (formData.confpass !== formData.password) {
	        newErrors.confpass = "Passwords do not match.";
	    }

    	

    	setErrors(newErrors);
    	return Object.keys(newErrors).length === 0;
  	};

  	const handleChange = (e) => {
    	const { name, value } = e.target;
    	setFormData({ ...formData, [name]: value });

    	let fieldErrors = {};
    	switch (name) {
      		case 'first_name':
        		if (!value) {
          			fieldErrors.first_name = "Mention Name.";
        		} else if (value.length < 2) {
          			fieldErrors.first_name = "name must consist of at least 2 characters.";
        		} else if (value.length > 255) {
          			fieldErrors.first_name = "name must not exceed 255 characters.";
        		}
		        if (!fieldErrors.first_name) {
		           fieldErrors.first_name = '';
		        }
        		break;


        	case 'last_name':
        		if (!value) {
          			fieldErrors.last_name = "Mention Name.";
        		} else if (value.length < 2) {
          			fieldErrors.last_name = "name must consist of at least 2 characters.";
        		} else if (value.length > 255) {
          			fieldErrors.last_name = "name must not exceed 255 characters.";
        		}
		        if (!fieldErrors.last_name) {
		           fieldErrors.last_name = '';
		        }
        		break;

      

      		case 'email':
        		if (!value) {
          			fieldErrors.email = "Mention Email Id.";
        		} else if (!/\S+@\S+\.\S+/.test(value)) {
          			fieldErrors.email = "Please enter a valid email.";
        		}
        		if (!fieldErrors.email) {
          			fieldErrors.email = '';
        		}
        		break;


        	case 'mobile':
	        if (!value) {
	          fieldErrors.mobile = "Phone is Required.";
	        } else if (!/^\d{10,12}$/.test(value)) {
	          fieldErrors.mobile = "Phone number must be between 10 and 12 digits.";
	        }
	        if (!fieldErrors.mobile) {
	          
	          fieldErrors.mobile = '';
	        }
	        break;

        	case 'username':
        		if (!value) {
          			fieldErrors.username = "Mention Name.";
        		} else if (value.length < 2) {
          			fieldErrors.username = "name must consist of at least 2 characters.";
        		} else if (value.length > 255) {
          			fieldErrors.username = "name must not exceed 255 characters.";
        		}
		        if (!fieldErrors.username) {
		           fieldErrors.username = '';
		        }
        		break;

        	case 'password':
        		if (!value) {
          			fieldErrors.password = "Mention password.";
        		} else if (value.length < 2) {
          			fieldErrors.password = "password must consist of at least 2 characters.";
        		} else if (value.length > 255) {
          			fieldErrors.password = "password must not exceed 255 characters.";
        		}
		        if (!fieldErrors.password) {
		           fieldErrors.password = '';
		        }
        		break;

        	case 'confpass':
            	if (!value) {
                	fieldErrors.confpass = "Please confirm your password.";
            	} else if (value !== formData.password) {
                	fieldErrors.confpass = "Passwords do not match.";
            	}
            	if (!fieldErrors.confpass) {
		           fieldErrors.confpass = '';
		        }
            	break;

           



      		default:
        	break;
    	}
    	setErrors((prevErrors) => ({
      		...prevErrors,
      		...fieldErrors
    	}));
  	};

  	const fetchUserRoles = async () => {
      
      	const apiUserRolesUrl = `${config.API_URL}/get_users_role`;
      	try {
        	const response = await fetch(apiUserRolesUrl, {
          		method : 'POST',
          		headers : {
            		authorization: `Bearer ${accessToken}`,
          		}
        	});

        	const data = await response.json();
        	console.log(data);
        	const formattedOptions = data.data.map(item => ({
                key: `${item.name}`,
                value: item.id,
            }));
            setRoleOptions(formattedOptions);

      	}catch(error){
        	console.error('api fail error fetching sales routes');
      	}
    };

  	const handleChangeRoute = (event) => {
      	const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
      	setUserRoles(selectedOptions);
  	};

  	useEffect(() => {
      	if (accessToken === null) {
          window.location.href = 'react/login';
          return;
      	}
      	fetchUserRoles();

  	}, []);
  	useEffect(() => {
	    if (selectRef.current) {
	      $(selectRef.current).select2({
	        placeholder: 'Select Role',
	        width: '100%',
	        tags: true,
	        dropdownParent: $('#add-user'),
	      }).on('change', (e) => {
	        setUserRoles($(e.target).val() || []);
	      });
	    }
	    return () => {
	      if (selectRef.current) {
	        $(selectRef.current).select2('destroy');
	      }
	    };
  	}, [roleOptions]);

  	const submitAddUserForm = async (event) => {


  		event.preventDefault();

  		

    	if (!validateForm()) return; 
   		const form = document.getElementById('adduserform');
    	const formData = new FormData(form);
    	try {
      		const response = await axios.post(`${config.API_URL}/add_users`, formData, {
        		headers: {
          			authorization: `Bearer ${accessToken}`,
        		}
      		});
      		const result = response.data;
      		console.log(result);
      		const msg = result.message;
      		
      		if (result.success == true){
          		Swal.fire({
            		title: "Success!",
		            text: "User added successfully.",
		            icon: "success",
		            timer: 1500,
		            showConfirmButton: false,
          		}).then(() => 
          		{
             		setFormData({
             			avatar 		: '',
									username 	: '',
									email 		: '',
									password 	: '',
									group 		: '',
									first_name 	: '',
									last_name 	: '',
									address 	: '',
									postcode 	: '',
									city 		: '',
									state 		: '',
									mobile 		: '',
									email 		: '',
									confpass 	: '',
             		});
             		setUserRoles('');
                onUserAdded();
                const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
          			bsModal.hide();
                window.location.href = config.BASE_URL + '/users/';
            
          		});
      		}else{
		        Swal.fire({
		          	title: "Failed!",
		          	text: msg,
		          	icon: "error",
		          	timer: 1500,
		          	showConfirmButton: false,
		        });
      		}
      	} catch (error) {
        	console.error("Error User add:", error);
      	}
  	};


	return(
		<>

		  <div class="modal fade" id="add-user" tabIndex="-1" ref={modalRef} >
	        <div class="modal-dialog modal-lg">
	          <div class="modal-content">
	            <div class="modal-header">
	              <h5 class="modal-title">Add User</h5>
	              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
	            </div>
	            <div class="modal-body">
	              <div class="user-popup">
	                <form id="adduserform">
	                  <div class="row mb-3 padd-l-13px">
	                    <div class="col-sm-4">
	                    	<div className="input-group">
                          	<div className="col-sm-12">
	                      			<label htmlFor="firstName" class="col-form-label">First Name  *</label>
	                      			<input type="text" class="form-control" name="first_name" id="first_name" value={formData.first_name} onChange={handleChange} />
	                      		</div>
	                      		<div className="col-sm-12">
		                            {errors.first_name && <span className="text-danger">{errors.first_name}</span>}
		                        </div>
	                      	</div>
	                    </div>
	                    <div class="col-sm-4">
	                    	<div className="input-group">
                          		<div className="col-sm-12">
	                      			<label htmlFor="lastName" class="col-form-label">Last Name *</label>
	                      			<input type="text" class="form-control" name="last_name" id="last_name" value={formData.last_name} onChange={handleChange}  />
	                      		</div>
	                      		<div className="col-sm-12">
		                            {errors.last_name && <span className="text-danger">{errors.last_name}</span>}
		                        </div>
	                      	</div>
	                    </div>
	                    <div class="col-sm-4">
	                    	<div className="input-group">
                          <div className="col-sm-12">
	                      		<label htmlFor="phone" class="col-form-label">Phone *</label>
	                      		<input type="number" class="form-control no-arrows" name="mobile" id="mobile" style={{ appearance: 'none', MozAppearance: 'textfield' }} value={formData.mobile} onChange={handleChange}  />
	                      	</div>
	                      	<div className="col-sm-12">
	                            {errors.mobile && <span className="text-danger">{errors.mobile}</span>}
	                        </div>
	                      </div>
	                    </div>
	                  </div>

	                  <div class="row mb-3 padd-l-13px">
	                    
	                    <div class="col-sm-4">
	                    	<div className="input-group">
                          		<div className="col-sm-12">
	                      			<label htmlFor="email" class="col-form-label">Email *</label>
	                      			<input type="text" class="form-control" name="email" id="email" value={formData.email} onChange={handleChange} />
	                      		</div>
	                      		<div className="col-sm-12">
		                            {errors.email && <span className="text-danger">{errors.email}</span>}
		                        </div>
	                      	</div>
	                    </div>
	                  
	                    <div class="col-sm-4">
	                      <label htmlFor="address" class="col-form-label">Address</label>
	                      <input type="text" class="form-control" name="address" id="address" />
	                    </div>
	                    <div class="col-sm-4">
	                      <label htmlFor="city" class="col-form-label">City</label>
	                      <input type="text" class="form-control" name="city" id="city" />
	                    </div>
	                    <div class="col-sm-4">
	                      <label htmlFor="state" class="col-form-label">County</label>
	                      <input type="text" class="form-control" name="state" id="state" />
	                    </div>
	                    <div class="col-sm-4">
	                      <label htmlFor="postcode" class="col-form-label">Postcode</label>
	                      <input type="text" class="form-control" name="postcode" id="postcode" />
	                    </div>
	                  </div>

	                  <div class="row mb-3 padd-l-13px">
	                    
	                    
	                  
	                    <div class="col-sm-4">
	                    	<div className="input-group">
                          		<div className="col-sm-12">
	                      			<label htmlFor="username" class="col-form-label">Username *</label>
	                      			<input type="text" class="form-control" name="username" id="username" value={formData.username} onChange={handleChange} />
	                      		</div>
	                      		<div className="col-sm-12">
		                            {errors.username && <span className="text-danger">{errors.username}</span>}
		                        </div>
	                      	</div>
	                    </div>
	                    <div class="col-sm-4">
	                    	<div className="input-group">
                          		<div className="col-sm-12">
	                      			<label htmlFor="password" class="col-form-label">Password *</label>
	                      			<input type="password" class="form-control" name="password" id="password" value={formData.password} onChange={handleChange} />
	                      		</div>
	                      		<div className="col-sm-12">
		                            {errors.password && <span className="text-danger">{errors.password}</span>}
		                        </div>
	                      	</div>
	                    </div>
	                    <div class="col-sm-4">
	                    	<div className="input-group">
                          		<div className="col-sm-12">
	                      			<label htmlFor="confirmPassword" class="col-form-label">Confirm Password *</label>
	                      			<input type="password" class="form-control" name="confpass" id="confpass" value={formData.confpass} onChange={handleChange} />
	                      		</div>
	                      		<div className="col-sm-12">
		                            {errors.confpass && <span className="text-danger">{errors.confpass}</span>}
		                        </div>
	                      	</div>
	                    </div>
	                  </div>

	                  <div class="row mb-3 padd-l-13px">
	                    
	                    <div class="col-sm-4" style={{postion: 'relative'}}>
	                    	<div className="input-group">
                          		<div className="col-sm-12">
	                      			<label htmlFor="role" class="col-form-label">Role </label>
				                    <select
				                      ref={selectRef}
				                      multiple
				                      value={userRoles}
				                      className="form-control"
				                      name="group[]" id="group"
				                    >
				                      {roleOptions.map(option => (
				                        <option key={option.value} value={option.value}>
				                          {option.key}
				                        </option>
				                      ))}
				                    </select>
				                </div>
				                
				            </div>
	                    </div>
	                  
	                    <div class="col-sm-4">
	                      <label htmlFor="passportImage" class="col-form-label">Passport Size Image</label>
	                      <input type="file"accept="image/png, image/jpg, image/jpeg"  class="form-control" name="avatar" id="avatar" />
	                    </div>
	                  </div>

	                  <hr />
	                  <div class="row padd-l-13px">
	                    <div class="col-sm-12">
	                      <button onClick={submitAddUserForm} type="submit" id="submit" class="float-right-btn common-popup-btn">
	                        Add User
	                      </button>
	                    </div>
	                  </div>
	                </form>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>


		</>

	);
}
export default AddUsers;