import React, { useState, useEffect, useRef  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import CustomDateRangePicker from '../common/CustomDateRangePicker';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import AccountService from '../../services/AccountServices';

const ProductLadger = () =>{

	const accessToken = localStorage.getItem('accessToken');
	const user_id = localStorage.getItem('userId');

	const [dates, setDates] = useState([null, null]);

	const [resStartDate, setResStartDate] = useState('');
	const [resEndDate, setResEndDate] = useState('');
	

	const [reportrange, setReportrange] = useState('');

	const [productOptions, setProductOptions] = useState([]);
    const [productFilteredOptions, setProductFilteredOptions] = useState([]);
    const [productValue, setProductValue] = useState(null);  
    const [productInputValue, setProductInputValue] = useState('');


    const [searchValue, setSearchValue] = useState([]);

    const productLadgerRef = useRef(null);

	const searchFormData = () => {
	    let startDate = dates[0] ? format(dates[0], 'dd/MM/yyyy') : '';
	    let endDate = dates[1] ? format(dates[1], 'dd/MM/yyyy') : '';
	    setReportrange(startDate ? startDate + ' - ' + endDate : '');
	    getProductLadgerList();
	};

	const loadProductList = async () => {
		try {
			const data = await AccountService.fetchProductList(accessToken);
			console.log(data);

			const formattedOptions = data.products.map(item => ({
                label: item.name,
                value: item.id,
            }));
            setProductOptions(formattedOptions);
            setProductFilteredOptions(formattedOptions);
		} catch (error) {
			console.log('Failed to load product list', error);
		}
	};

	const productHandleInputChange = (inputValue) => {
        setProductInputValue(inputValue);
        const newFilteredOptions = productOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setProductFilteredOptions(newFilteredOptions);
    };
    const productHandleChange = (selectedOption) => {
        
        if (selectedOption ) {
            setProductValue(selectedOption);
        } else {
            setProductValue(null); 
        }
    };



	


  	const getProductLadgerList = () => {
    	const $table = $(productLadgerRef.current);

    	let startDate = dates[0] ? format(dates[0], 'dd/MM/yyyy') : '';
	    let endDate = dates[1] ? format(dates[1], 'dd/MM/yyyy') : '';

	    let dateRange = startDate ? startDate + ' - ' + endDate : ''

	   

    	const searchValue = $('#searchValue').val();
    	if ($.fn.DataTable.isDataTable($table)) {
        	$table.DataTable().destroy();
    	}
    	let i = 0;
    	$table.DataTable({
        	processing: true,
        	serverSide: true,
        	ajax: {
            	url: `${config.API_URL}/get_product_ladger_show`,
            	type: 'POST',
            	headers: {
               	authorization : `Bearer ${accessToken}`,
            	},
            	data: function (d) {
                	return $.extend({}, d, {
                    	draw: d.draw,
                    	start: d.start,
                    	length: d.length,
                    	searchValue : searchValue,
                    	product_id : productValue ? productValue.value : '',
                    	reportrange : dateRange ? dateRange : '',
                	});
            	},
            	dataSrc: function (json) {
                	if (json) {
                    	setResStartDate(json.start_date);
                    	setResEndDate(json.end_date); 

                    
                	}
                	return Array.isArray(json.data.product_ledger) ? json.data.product_ledger : [];
            	}
        	},
        	language: {
            	paginate: {
                	first: 'First',
                	last: 'Last',
                	next: 'Next',
                	previous: 'Previous',
            	},
            	search: 'Search:',
        	},
        	columns: [
           		{ data: 'date' },
	        	{ 
	        		data: null,
	        		render : function(data, type, row){
	        			
	        			return `<a href="${config.BASE_URL}/payments/view_product_ledger/${row.id}/${resStartDate}/${resEndDate}/${row.order_type}"><sapn>${row.code}</sapn></a>`;
	        		} 
	        	},
	        	{ 
	        		data: null,
	        		render : function(data, type, row){
	        			return `<span>${row.name}</span>`;
	        		} 
	        	},
	        	{ data: 'order_type' },
	        	{ 
	        		data: null,
	        		render :function(data, type, row){
	        			if(row.order_type == 'box'){
	        				return `<span>${row.actual_box_balance}</span>`;
	        			}else if(row.order_type == 'piece'){
	        				return `<span>${row.actual_piece_balance}</span>`;
	        			}else{
	        				return `<span>${row.actual_box_balance}</span>`;
	        			}
	        		}
	        	},
	        	{ data: 'sin' },
	        	{ data: 'sout' },
	        	{ 
	                data: null, 
	                render: function (data, type, row) {
	                    let sout = Number(row.sout); 
        				let sin = Number(row.sin); 

	                    if (row.order_type == 'box') {
	                    	let actualBoxBalance = Number(row.actual_box_balance);
				            return `<span>${(actualBoxBalance + sout) - sin}</span>`;
				        } else if (row.order_type == 'piece') {
				            let actualPieceBalance = Number(row.actual_piece_balance);  
				            return `<span>${(actualPieceBalance + sout) - sin}</span>`;  
				        } else {
				            return `<span></span>`;
				        }
	                }
	            },
	        
        	],
        	columnDefs: [
            {
                orderable: false, 
                targets: '_all'
            },
            {
                targets: 2, 
                render: function(data) {
                    return data === '1' ? 'Active' : 'Inactive'; 
                }
            }
      	],
      
        	pageLength: 10,
    	});

    	

    	
	};

	useEffect(() => {

  		if (accessToken === null){
            window.location.href = '/login'; 
            return;
        }

        loadProductList();

        getProductLadgerList();

        return () => {
        	if (productLadgerRef.current && $.fn.DataTable.isDataTable(productLadgerRef.current)) {
            	$(productLadgerRef.current).DataTable().destroy();
        	}
    	};

  	},[searchValue, resStartDate, resEndDate]);

	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/account/my_ledger">Account</Link> 
		                  <Link to="/account/product_ledger">Product Ledger</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">
			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>Product Ledger </b></p>

	                    <div className="row" >

	                    	<div className="col-md-3 cp-lg-3 col-sm-3" >
							    
				                <Select className='abovedd'
						            isClearable
						            onInputChange={productHandleInputChange}
						            onChange={productHandleChange}
						            options={productFilteredOptions}  // Pass the filtered options here
						            placeholder="Select Product"
						            value={productValue}
						        />
							</div>

	              			<div className="col-md-8 col-lg-8 col-sm-8">
	                			<CustomDateRangePicker dates={dates} onChange={setDates} onSubmit={searchFormData} />
	              			</div>
		            	</div>


		            	<div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
							<table className="table table-bordered dataTable" id="productLadgerList" ref={productLadgerRef}>
								<thead>
									<tr>
										<th>Date</th>
										<th>Code</th>
										<th>Product</th>
										<th>Type</th>
										<th>O/B</th>
										<th>In</th>
										<th>Out</th>
										<th>Bal</th>
										
									</tr>
								</thead>
								<tbody>

								</tbody>
							</table>
						</div>

	                </div>
	            </div>
	        </div>

	      	<Footer />


		</>

	);

}
export default ProductLadger;