import { API_URL } from '../components/Config';

const fetchPurchaseGrnList = async (draw, start, length, accessToken) => {
    const params = new URLSearchParams();
    params.append('draw', draw);
    params.append('start', start);
    params.append('length', length);

    const apiPurchaseOrderUrl = `${API_URL}/purchase/purchaseHistory?${params.toString()}`;
    try {
        const response = await fetch(apiPurchaseOrderUrl, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch purchase order');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('API Error fetching purchase order:', error);
        throw error;
    }
};

export default {
    fetchPurchaseGrnList,
};
