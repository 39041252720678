import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import config from "./common/Config";

const ModalComponent = (props) => {

  const { manifestId } = useParams();
  const [options, setOptions] = useState([]);
  const [driver, setSelectedDriver] = useState('');
  const [markDeliveryList, setmarkDeliveryList] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const accessToken = localStorage.getItem('accessToken'); 


  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);

  const handleShow = () => {
     if (modalRef.current) {
      const modal = new window.bootstrap.Modal(modalRef.current);
      modal.show();
    }
  };

  useEffect(() => {
      async function fetchDriverOptions() {
          try {

            const response = await axios.get(`${config.API_URL}/getDriver`, {
              headers: {
                  authorization: `Bearer ${accessToken}`,
                },
            });
            if (!response.data || !response.data.data.driver || !Array.isArray(response.data.data.driver)) {
                throw new Error('Invalid response format or empty driver array');
            }
            const formattedOptions = response.data.data.driver.map(item => ({
                key: `${item.first_name} ${item.last_name}`,
                value: item.id,
            }));
    
            setOptions(formattedOptions);
          } catch (error) {
            console.error('Error fetching driver options:', error);
            // Handle error as needed, e.g., show a message to the user
          }
      }
      fetchDriverOptions();
    }, []);

    const fetchData = async () => {
      const formData = new FormData();

      formData.append('id_new', manifestId);
      formData.append('draw', draw);
      formData.append('start', start);
      formData.append('length', length);
      //formData.append('role', selectedRole);

      const apiUrl = `${config.API_URL}/manifest_list/get_mark_delivery`; 
      
      try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          });
          if (!response.ok) {
            throw new Error('Failed to fetch new sales data');
          }

          const data = await response.json();
          //console.log(data.data);
          setmarkDeliveryList(data.data);
      } catch (error) {
        console.error('Error fetching new sales data:', error);
      }
    };

    useEffect(() => {
      fetchData();
    }, []);

    useEffect(() => {
      const table = $('#markDeliverytable').DataTable({
          pageLength: 50,
          paging: true,
          lengthMenu: [10, 20, 50],
          searching: true,
          ordering: true,
          info: true,
          responsive: true,
          autoWidth: false,
          language: {
            paginate: {
                first: 'First',
                last: 'Last',
                next: 'Next',
                previous: 'Previous',
            },
            search: 'Search:',
          },
          data: markDeliveryList,
          columns: [          
            { data: 'date' },    
            { data: 'invoice_num' },
            { data: 'customer' },
            { data: 'invoice_amt' },
            { data: 'sales_person_collected' },
            { data: 'previous_dues' }
        ],
        columnDefs: [
            { targets: [], sortable: true }
        ]
      });
      return () => {
          table.destroy();
      };
    }, [markDeliveryList]);

  return (
    <>
    <Header />
    <div className="breadcrumbs">
          <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-lg-12 col-sm-12"> 
                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
                  <Link to="/ManifestList">Dispatch & Deliveries</Link> 
                  <span className="last-crumb">Manifest List</span> 
                </div>
            </div>
          </div>
      </div>
      {/* Button to trigger the modal */}
      <button type="button" className="btn btn-primary" onClick={handleShow}>
        Launch demo modal
      </button>

      {/* Modal */}
      <div ref={modalRef} className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button type="button" className="btn-close" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Modal body text goes here.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Close button</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalComponent;
