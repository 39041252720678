import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
//import Config from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import config from "../common/Config";

const ViewPicking = () => {
  const { picklistNo } = useParams();
  const [newOrders, setNewOrders] = useState([]);
  const [pickingDetails, setPickingDetails] = useState({});
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [picker, setSelectedPicker] = useState('');
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);

  useEffect(() => {
    async function fetchData() {
      const formData = new FormData();
      formData.append('searchByFromdate', fromDate);
      formData.append('searchByTodate', toDate);
      formData.append('draw', draw);
      formData.append('start', start);
      formData.append('length', length);
      formData.append('pickerId', picker);
      formData.append('picklist_number', picklistNo);

      const accessToken = localStorage.getItem('accessToken'); 
      if (accessToken === null) {
        window.location.href = '/login'; 
        return;
      }

      try {
        // Fetch picking history
        const responseHistory = await fetch(`${config.API_URL}/get_view_picking_history`, {
          method: 'POST',
          headers: {
            'authorization': `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!responseHistory.ok) {
          throw new Error('Failed to fetch picking history');
        }

        const historyData = await responseHistory.json();
        console.log('Picking History Data:', historyData); // Debugging
        setNewOrders(historyData.data || []);

        // Fetch picking details
        const responseDetails = await fetch(`${config.API_URL}/get_view_picklist`, {
          method: 'POST',
          headers: {
            'authorization': `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!responseDetails.ok) {
          throw new Error('Failed to fetch picking details');
        }

        const detailsData = await responseDetails.json();
        console.log('Picking Details Data:', detailsData); // Debugging
        setPickingDetails(detailsData.data || {});
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, [fromDate, toDate, picker, picklistNo, draw, start, length]);

  useEffect(() => {
    // Initialize DataTable for newOrders
    if ($.fn.DataTable.isDataTable('#neworderTable')) {
      $('#neworderTable').DataTable().clear().rows.add(newOrders).draw();
    } else {
      $('#neworderTable').DataTable({
        paging: false,
        lengthChange: false,
        searching: false,
        ordering: false,
        info: false,
        responsive: true,
        autoWidth: false,
        language: {
          paginate: {
            first: 'First',
            last: 'Last',
            next: 'Next',
            previous: 'Previous',
          },
          search: 'Search:',
        },
        data: newOrders,
        columns: [
          { data: 'code', width: '12%' },
          { data: 'name' },
          { data: 'order_type', width: '12%' },
          { data: 'quantity', width: '12%' },
          { data: 'picked_qty', width: '12%' },
          { data: 'confirmed_total', width: '12%', className: 'text-right' },
          { data: 'sale_person', width: '12%' },
        ],
      });
    }

    return () => {
      if ($.fn.DataTable.isDataTable('#neworderTable')) {
        $('#neworderTable').DataTable().destroy();
      }
    };
  }, [newOrders]);

  useEffect(() => {
    // Filter and Initialize DataTable for account details
    const filteredAccounts = (pickingDetails.accound_no || []).filter(row => row.note && row.note.trim() !== '');

    if ($.fn.DataTable.isDataTable('#accountDetailsTable')) {
      $('#accountDetailsTable').DataTable().clear().rows.add(filteredAccounts).draw();
    } else {
      $('#accountDetailsTable').DataTable({
        paging: false,
        lengthChange: false,
        searching: false,
        ordering: false,
        info: false,
        responsive: false,
        autoWidth: false,
        language: {
          paginate: {
            first: 'First',
            last: 'Last',
            next: 'Next',
            previous: 'Previous',
          },
          search: 'Search:',
        },
        data: filteredAccounts,
        columns: [
          { data: 'accound_no', title: 'A/c No' },
          { data: 'note', title: 'Comment' },
          { data: 'created_by', title: 'Comment By' },
        ],
      });
    }

    return () => {
      if ($.fn.DataTable.isDataTable('#accountDetailsTable')) {
        $('#accountDetailsTable').DataTable().destroy();
      }
    };
  }, [pickingDetails.accound_no]);

  // Helper function to join multiple reference numbers
  const getFormattedReferenceNos = () => {
    const refs = pickingDetails.reference_no || [];
    return refs.length > 0 ? refs.map(ref => ref.reference_no).join(', ') : 'N/A';
  };

  // Helper function to join multiple account numbers
  const getFormattedAccountNos = () => {
    const accs = pickingDetails.accound_no || [];
    return accs.length > 0 ? accs.map(acc => acc.accound_no).join(', ') : 'N/A';
  };

  // Print function
const handlePrint = () => {
  const printWindow = window.open('', '', 'height=600,width=800');
  const printContent = `
    <html>
    <head>
      <title>Print Picking Details</title>
      <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/bs5/1.13.4/css/dataTables.bootstrap5.min.css">
      <style>
        body { font-family: Arial, sans-serif; margin: 20px; }
        .container { max-width: 800px; margin: auto; }
        h1 { text-align: center; }
        .details-section { margin-bottom: 20px; }
        .details-section div { margin-bottom: 10px; }
        .details-section b { font-weight: 600; }
        table { width: 100%; border-collapse: collapse; margin-top: 20px; }
        table, th, td { border: 1px solid #ddd; }
        th, td { padding: 10px; text-align: left; }
        th { background-color: #f4f4f4; }
        td.text-right { text-align: right; }
      </style>
    </head>
    <body>
      <div class="container">
        <h1>Picking List: ${picklistNo}</h1>
        <div class="details-section">
          <div><b>Order Number:</b> ${getFormattedReferenceNos()}</div>
          <div><b>Account Number:</b> ${getFormattedAccountNos()}</div>
          <div><b>Route Number:</b> ${pickingDetails.route_number ? pickingDetails.route_number.map(route => route.route_number).join(', ') : 'N/A'}</div>
          <div><b>Delivery Date:</b> ${pickingDetails.deliverydate || 'N/A'}</div>
          <div><b>Print by:</b> kc</div>
          <div><b>Print Date:</b> ${new Date().toLocaleDateString()}</div>
        </div>
        
        <h2>Order Details</h2>
        <table>
          <thead>
            <tr>
              <th>Code</th>
              <th>Product Name</th>
              <th>Type</th>
              <th>Order Qty</th>
              <th>Pick Qty</th>
              <th>Accept Qty</th>
              <th>Sales Person</th>
            </tr>
          </thead>
          <tbody>
            ${newOrders.map(order => `
              <tr>
                <td>${order.code}</td>
                <td>${order.name}</td>
                <td>${order.order_type}</td>
                <td>${order.quantity}</td>
                <td>${order.picked_qty}</td>
                <td class="text-right">${order.confirmed_total}</td>
                <td>${order.sale_person}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>

        <h2>Account Details</h2>
        <table>
          <thead>
            <tr>
              <th>A/c No</th>
              <th>Comment</th>
              <th>Comment By</th>
            </tr>
          </thead>
          <tbody>
            ${pickingDetails.accound_no ? pickingDetails.accound_no.filter(row => row.note && row.note.trim() !== '').map(account => `
              <tr>
                <td>${account.accound_no}</td>
                <td>${account.note}</td>
                <td>${account.created_by}</td>
              </tr>
            `).join('') : ''}
          </tbody>
        </table>
      </div>
    </body>
    </html>
  `;

  printWindow.document.open();
  printWindow.document.write(printContent);
  printWindow.document.close();
  printWindow.focus();
  printWindow.print();
};

  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <a href="#"><i className="fa fa-home"></i></a>
              <span className="last-crumb">View Picking</span>
            </div>
          </div>
        </div>
      </nav>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-lg-12 col-sm-12">
              <div className="row picking-details">
                <label style={{ float: 'left', fontWeight: '500' }}>Picking List:</label>
                <i style={{ float: 'left' }}>{picklistNo}</i>
                <hr/>
                <div className="row details-section">
                  <div className="col-6 col-lg-6 col-sm-6">
                    <b>Order Number:</b> <i style={{ fontStyle: 'normal' }}>{getFormattedReferenceNos()}</i><br />
                    <b>Account Number:</b> <i style={{ fontStyle: 'normal' }}>{getFormattedAccountNos()}</i><br />
                    <b>Route Number:</b> <i style={{ fontStyle: 'normal' }}>
                      {pickingDetails.route_number ? pickingDetails.route_number.map(route => route.route_number).join(', ') : 'N/A'}
                    </i>
                  </div>
                  <div className="col-6 col-lg-6 col-sm-6">
                    <b>Delivery Date:</b> <i style={{ fontStyle: 'normal' }}>{pickingDetails.reference_no && pickingDetails.reference_no[0] && pickingDetails.reference_no[0].deliverydate
          ? pickingDetails.reference_no[0].deliverydate
          : 'N/A'}</i><br />
                    <b>Print by:</b> kc<br />
                    <b>Print Date:</b> {new Date().toLocaleDateString()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="show-order-area dashboard">
                <form id="picking_create">
                  <div>
                    <div style={{ float: 'right' }}>
                      <button type="button" className="acceptbtn" onClick={handlePrint}>
                        Print Picking Details
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="verical-scrl-wrapper newsales-table" id="style-3">
                      <table className="table table-bordered dataTable" id="neworderTable">
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Product Name</th>
                            <th>Type</th>
                            <th>Order Qty</th>
                            <th>Pick Qty</th>
                            <th>Accept Qty</th>
                            <th>Sales Person</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* DataTables will handle rendering of rows */}
                        </tbody>
                      </table>
                    </div>
                    <div className="verical-scrl-wrapper newsales-table" id="style-3">
                      <table className="table table-bordered dataTable" id="accountDetailsTable">
                        <thead>
                          <tr>
                            <th>A/c No</th>
                            <th>Comment</th>
                            <th>Comment By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* DataTables will handle rendering of rows */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewPicking;
