import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';

const Supplier = () => {
  

 


  const supplierTableRef = useRef(null);
  const [searchValue, setSearchValue] = useState([]);

  const accessToken = localStorage.getItem('accessToken');


  const initializeSupplierTable = () => {
      const $table = $(supplierTableRef.current);
      const searchValue = $('#searchValue').val();
      // Destroy existing DataTable instance if it exists
      if ($.fn.DataTable.isDataTable($table)) {
          $table.DataTable().destroy();
      }
  
      let i = 0;
      $table.DataTable({
          processing: true,
          serverSide: true,
          ajax: {
              url: `${config.API_URL}/get_supplier_list`,
              type: 'POST',
              headers: {
                 authorization : `Bearer ${accessToken}`,
              },
              data: function (d) {
                  return $.extend({}, d, {
                      draw: d.draw,
                      start: d.start,
                      length: d.length,
                      searchValue : searchValue
                  });
              },
              dataSrc: function (json) {
                 // return json.data;
                  return Array.isArray(json.data) ? json.data : [];
                  console.log(json);


              }
          },
          language: {
              paginate: {
                  first: 'First',
                  last: 'Last',
                  next: 'Next',
                  previous: 'Previous',
              },
              search: 'Search:',
          },
          columns: [
              { data: null,
                width: "6%",
                render: function(data, type, row, meta) {
                    return meta.row + meta.settings._iDisplayStart + 1;
                }
               },
              { data: 'company',   },
              { 
                data: null,
                render : function(data, type, row){
                  return `<span>${row.name}</span>`;
                }

              },
              { 
                data: null,
                render : function(data, type, row){
                   return `<span class="text-lowercase">${row.email.toLowerCase()}</span>`;
                }   
              },
              { data: 'phone',   },
              { data: 'city',   },
              { data: 'state',   },
              { data: 'vat_no',   },
              { 
                data: null,
                render : function(data, type, row){
                  const checked = row.status == "1" ? "checked" : "";
                  return `<label class="switch" style="margin: 0px auto;">
                      <input data-id="${row.id}" class="switch-input" type="checkbox" name="changeStatus" id="changeStatus_${row.id}" ${checked} />
                      <span class="switch-label" data-on="Active" data-off="Inactive"></span>
                      <span class="switch-handle"></span>
                    </label>`;
                }

              },
              {
                data: null,
                title: 'Action',
                width: "7%",
                render: (data, type, row) => `
                <a href="${config.BASE_URL}/supplier/edit_supplier/${row.id}" class="icon editbtn" title="Edit" >
                  <i class="fa fa-pencil"></i>
                </a>`
              }
              
          ],
          columnDefs: [
            {
                orderable: false, 
                targets: '_all'
            },
            {
                targets: 2, 
                render: function(data) {
                    return data === '1' ? 'Active' : 'Inactive'; 
                }
            }
        ],
        
          pageLength: 10,
      });

      
  

      

      
        
      $table.on('change', '.switch-input', function() {
        const id = $(this).data('id');
        const status = this.checked ? 1 : 0;

        handleChangeStatus(id, status);
    });
  };

  const handleChangeStatus = async(id, status)=>{
    const formData = new FormData();
    formData.append('status', status);
    formData.append('id', id);
    const apiUrl = `${config.API_URL}/update_supplier_status`; 
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
           authorization : `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to change status');
      }

      const data = await response.json();
      console.log(data);
      
      Swal.fire({
        title: 'Success',
        text: 'Status changed successfully.',
        icon: 'success', 
        showConfirmButton: false, 
        timer: 1500,  
      });
      
    } catch (error) {
      console.error('Error status change:', error);
    }
  }

  useEffect(() => {

      if (accessToken === null) {
          window.location.href = 'react/login';
          return;
      }

      initializeSupplierTable();  
      return () => {
          if (supplierTableRef.current && $.fn.DataTable.isDataTable(supplierTableRef.current)) {
              $(supplierTableRef.current).DataTable().destroy();
          }
      };
  }, [searchValue]);
  

  return (
  <div>
  <Header />
  <div className="breadcrumbs">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-12 col-sm-12">
          <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="Dashboard">Master</a> &gt; <span className="last-crumb">Supplier</span>
        </div>
      </div>
    </div>
  </div>

  <div className="main-container">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-12 col-sm-12">
          <div style={{ position: 'relative' }}>
            <div className="row">
              <div className="col-12 col-lg-4 col-sm-4">
                
              </div>
              <div className="col-12 col-lg-8 col-sm-8">
                <div style={{ float: "right" }}>
                  
                  <Link to={`${config.BASE_URL}/supplier/add_supplier`} className="create-catalogue-btn">Add Supplier</Link>
                </div>
              </div>
            </div>
            <div className="show-order-area dashboard">
              <div className="verical-scrl-wrapper common-table supplier-table" id="style-3">
                <table className="table table-bordered dataTable" id="neworderTable" ref={supplierTableRef}>
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Company</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Phone</th>
                      <th>City</th>
                      <th>Country</th>
                      <th>Vat Number</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</div>

);
};

export default Supplier; 