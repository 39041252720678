import React, { useState, useEffect, useRef } from 'react';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';
import AddCustomersForm from './AddCustomersForm';

const Customers = () => {
  const customerTableRef = useRef(null);
  const customerInactiveTableRef = useRef(null);
  const [custSearchValue, setCustSearchValue] = useState([]);
  const [custInactiveSearchValue, setCustInactiveSearchValue] = useState([]);
  const accessToken = localStorage.getItem('accessToken');
  const initializeCustomerTable = () => {
      const $table = $(customerTableRef.current);
      const searchValue = $('#searchValue').val();
      // Destroy existing DataTable instance if it exists
      if ($.fn.DataTable.isDataTable($table)) {
          $table.DataTable().destroy();
      }
  
      let i = 0;
      $table.DataTable({
          processing: true,
          serverSide: true,
          ajax: {
              url: `${config.API_URL}/get_active_customer`,
              type: 'POST',
              headers: {
                 authorization : `Bearer ${accessToken}`,
              },
              data: function (d) {
                  return $.extend({}, d, {
                      draw: d.draw,
                      start: d.start,
                      length: d.length,
                      searchValue : searchValue
                  });
              },
              dataSrc: function (json) {
                  //return json.data;
                  return Array.isArray(json.data) ? json.data : [];
                  console.log(json);
              }
          },
          language: {
              paginate: {
                  first: 'First',
                  last: 'Last',
                  next: 'Next',
                  previous: 'Previous',
              },
              search: 'Search:',
          },
          columns: [
              { data: null,
                width: "6%",
                render: function(data, type, row, meta) {
                    return meta.row + meta.settings._iDisplayStart + 1;
                }
               },
              { data: 'account_no', width : '4%'   },
              { 
                data: null, 
                width : '10%',
                render :function(data, type, row){
                  return `<span style="text-transform: none;">${row.company}</span>`;
                }  
              },
              { 
                data: null,
                render : function(data, type, row){
                  return `<span style="text-transform: none;">${row.name}</span>`;
                }   
              },
              { 
                data: null,
                render : function(data, type, row){
                   return `<span class="text-lowercase">${row.email.toLowerCase()}</span>`;
                }   
              },
              { data: 'phone',   },
              { data: 'vat_no',   },
              { 
                data: null,
                render : function(data, type, row){
                  const checked = row.status == "1" ? "checked" : "";
                  return `<label class="switch" style="margin: 0px auto;">
                      <input data-id="${row.id}" class="switch-input" type="checkbox" name="changeStatus" id="changeStatus_${row.id}" ${checked} />
                      <span class="switch-label" data-on="Active" data-off="Inactive"></span>
                      <span class="switch-handle"></span>
                    </label>`;
                }

              },
              { data: 'product_price_type', width : '5%', className : 'text-center'  },
              {
                data: null,
                title: 'Action',
                width: "7%",
                render: (data, type, row) => `
                  <div class="btn-group"><button type="button" class="action-btn-dd dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                    <ul class="dropdown-menu dropdown-menu-arrow">  
                      <li><a class="deductionbtn reject-btn" href="${config.BASE_URL}/customer/contra_price/${row.id}">Contra Price</a></li>
                      <li><a class="deductionbtn reject-btn" href="${config.BASE_URL}/customer/edit_customer/${row.id}">Edit Customers</a></li>
                    </ul>
                  </div>`
              }
              
          ],
          columnDefs: [
            {
                orderable: false, 
                targets: '_all'
            },
            {
                targets: 2, 
                render: function(data) {
                    return data === '1' ? 'Active' : 'Inactive'; 
                }
            }
        ],
        
          pageLength: 10,
      });

      
  

      $table.on('change', '.switch-input', function() {
        const id = $(this).data('id');
        const status = this.checked ? 1 : 0;

        handleChangeStatus(id, status);
    });
  };

  const initializeCustomerInactiveTable = () => {
      const $table = $(customerInactiveTableRef.current);
      const searchValue = $('#searchValue').val();
      // Destroy existing DataTable instance if it exists
      if ($.fn.DataTable.isDataTable($table)) {
          $table.DataTable().destroy();
      }
  
      let i = 0;
      $table.DataTable({
          processing: true,
          serverSide: true,
          ajax: {
              url: `${config.API_URL}/get_inctive_customer`,
              type: 'POST',
              headers: {
                 authorization : `Bearer ${accessToken}`,
              },
              data: function (d) {
                  return $.extend({}, d, {
                      draw: d.draw,
                      start: d.start,
                      length: d.length,
                      searchValue : searchValue
                  });
              },
              dataSrc: function (json) {
                  //return json.data;
                  return Array.isArray(json.data) ? json.data : [];
                  console.log(json);
              }
          },
          language: {
              paginate: {
                  first: 'First',
                  last: 'Last',
                  next: 'Next',
                  previous: 'Previous',
              },
              search: 'Search:',
          },
          columns: [
              { data: null,
                width: "6%",
                render: function(data, type, row, meta) {
                    return meta.row + meta.settings._iDisplayStart + 1;
                }
               },
              { data: 'account_no', width : '4%'   },
              { 
                data: null, 
                width : '10%',
                render :function(data, type, row){
                  return `<span>${row.company}</span>`;
                }  
              },
              { 
                data: null,
                render : function(data, type, row){
                  return `<span>${row.name}</span>`;
                }   
              },
              { 
                data: null,
                render : function(data, type, row){
                   return `<span class="text-lowercase">${row.email.toLowerCase()}</span>`;
                }   
              },
              { data: 'phone',   },
              { data: 'vat_no',   },
              { 
                data: null,
                render : function(data, type, row){
                  const checked = row.status == "1" ? "checked" : "";
                  return `<label class="switch" style="margin: 0px auto;">
                      <input data-id="${row.id}" class="switch-input" type="checkbox" name="changeStatus" id="changeStatus_${row.id}" ${checked} />
                      <span class="switch-label" data-on="Active" data-off="Inactive"></span>
                      <span class="switch-handle"></span>
                    </label>`;
                }

              },
              { data: 'product_price_type', width : '5%', className : 'text-center'  },
              {
                data: null,
                title: 'Action',
                width: "7%",
                render: (data, type, row) => `
                  <div class="btn-group"><button type="button" class="action-btn-dd dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                      <ul class="dropdown-menu dropdown-menu-arrow">
                        <li><a class="deductionbtn reject-btn" href="${config.BASE_URL}/customer/contra_price/${row.id}">Contra Price</a></li>
                        <li><a class="deductionbtn reject-btn" href="${config.BASE_URL}/customer/edit_customer/${row.id}">Edit Customers</a></li>
                      </ul>
                  </div>`
              }
              
          ],
          columnDefs: [
            {
                orderable: false, 
                targets: '_all'
            },
            {
                targets: 2, 
                render: function(data) {
                    return data === '1' ? 'Active' : 'Inactive'; 
                }
            }
        ],
        
          pageLength: 10,
      });

      
  

      $table.on('change', '.switch-input', function() {
        const id = $(this).data('id');
        const status = this.checked ? 1 : 0;

        handleChangeStatus(id, status);
    });
  };

  const handleChangeStatus = async(id, status)=>{
    const formData = new FormData();
    formData.append('status', status);
    formData.append('id', id);
    const apiUrl = `${config.API_URL}/update_customer_status`; 
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
           authorization : `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to change status');
      }

      const data = await response.json();
      console.log(data);
      
      Swal.fire({
        title: 'Success',
        text: 'Status changed successfully.',
        icon: 'success', 
        showConfirmButton: false, 
        timer: 1500,  
      });

      initializeCustomerTable();
      initializeCustomerInactiveTable();
      
    } catch (error) {
      console.error('Error status change:', error);
    }
  }

  useEffect(() => {
    if (accessToken == null) {
      window.location.href = 'react/login';
      return;
    }
    initializeCustomerTable(); 
    initializeCustomerInactiveTable();
    return () => {
        if (customerTableRef.current && $.fn.DataTable.isDataTable(customerTableRef.current)) {
            $(customerTableRef.current).DataTable().destroy();
        }
        if (customerInactiveTableRef.current && $.fn.DataTable.isDataTable(customerInactiveTableRef.current)) {
            $(customerInactiveTableRef.current).DataTable().destroy();
        }
    };
  }, [custSearchValue, custInactiveSearchValue]);


  




  return (
    <div>
      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="Dashboard">Master</a> &gt; <span class="last-crumb">Customer
            </span> </div>
          </div>
        </div>
      </div>


      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className='bank-pagearea'>
                <div class="">
                  <div>
                    <div>
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#eur" type="button" role="tab" aria-controls="eur" aria-selected="true"> Customers </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#gbp" type="button" role="tab" aria-controls="gbp" aria-selected="false"> Inactive Customers </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#usd" type="button" role="tab" aria-controls="usd" aria-selected="false"> Add Customers </button>
                        </li>
                      </ul>
                      <div class="tab-content fulltable-area" id="myTabContent">
                        <div class="tab-pane fade show active" id="eur" role="tabpanel" aria-labelledby="eur-tab">
                          <div class="backlist-content-area">
                            <div class="row">
                              <div class="col-12 col-lg-4 col-sm-4">
                                {/* <div class="row mb-3">
                                  <label for="inputText" class="col-sm-3 col-form-label">Bank:</label>
                                  <div class="col-sm-6">
                                    <input type="text" className="form-control" name="searchValue" id="searchValue" onChange={initializeDataTable} placeholder="Search Bank" />
                                  </div>
                                </div> */}
                              </div>
                              <div class="col-12 col-lg-8 col-sm-8">
                                
                              </div>
                            </div>
                            <div className="verical-scrl-wrapper common-table customer-table" id="style-3">
                              <table className="table table-bordered dataTable" id="neworderTable" ref={customerTableRef} style={{ width: '100%' }} >
                                <thead>
                                  <tr>
                                    <th>&nbsp;</th>
                                    <th>A/C No.</th>
                                    <th>Company</th>
                                    <th>Name</th>
                                    <th>Email Address</th>
                                    <th>Phone</th>
                                    <th>Vat No.</th>
                                    <th>Status</th>
                                    <th>Product Band</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                </tbody>
                              </table>

                            </div>
                          </div>
                        </div>

                        <div class="tab-pane fade" id="gbp" role="tabpanel" aria-labelledby="gbp-tab">
                          <div class="backlist-content-area">
                            <div class="row">
                              <div class="col-12 col-lg-4 col-sm-4">
                                
                              </div>
                              <div class="col-12 col-lg-8 col-sm-8">
                                {/*<div style={{ float: "right", marginBottom: "20px" }}>
                                  <a href="#" className="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add_Products">Export to Excel</a>
                                </div>*/}
                              </div>
                            </div>

                            <div className="verical-scrl-wrapper common-table customer-table" id="style-3">
                              <table className="table table-bordered dataTable" id="neworderInactiveTable" ref={customerInactiveTableRef} style={{ width: '100%' }} >
                                <thead>
                                  <tr>
                                    <th>&nbsp;</th>
                                    <th>A/C No.</th>
                                    <th>Company</th>
                                    <th>Name</th>
                                    <th>Email Address</th>
                                    <th>Phone</th>
                                    <th>Vat No.</th>
                                    <th>Status</th>
                                    <th>Product Band</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  



                                </tbody>
                              </table>

                            </div>
                          </div>
                        </div>

                        <div class="tab-pane fade" id="usd" role="tabpanel" aria-labelledby="usd-tab">
                          <div class="backlist-content-area">
                            
                            <AddCustomersForm />
                            


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <Footer />
    </div>
  );
};

export default Customers; 