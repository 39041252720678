import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";

const ProfitLossReport = () => {

	const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
	const [dates, setDates] = useState([null, null]);
  const [reportrange, setReportrange] = useState('');
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerInputValue, setCustomerInputValue] = useState('');
	const [plRouteOptions, setPlRouteOptions] = useState([]);
  const [plRoute, setPlRoute] = useState('');
  const [salesRapOptions, setSalesRapOptions] = useState([]);
  const [salesRap, setSalesRap] = useState('');
  const [reportDate, setReportDate] = useState('');
  const [amtReport, setAmtReport] = useState('');
	const [profitLossReport, setProfitLossReport] = useState([]);
	const accessToken = localStorage.getItem('accessToken');   

	/*useEffect(() => {
    if (customerInputValue && customerInputValue.value) {
    }
  }, [customerInputValue]); */

  const fetchCustomerNameOptions = async (inputValue) => {
    if (inputValue.length < 3) {
      return;
    }

    const formData = new FormData();
    formData.append('term', inputValue);
    formData.append('limit', '10');

 
    const apiSalesRapUrl = `${config.API_URL}/suggestions`;
    try {
      const response = await fetch(apiSalesRapUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (Array.isArray(data.results)) {
        const formattedOptions = data.results.map(item => ({
          label: item.text,  
          value: item.id,    
        }));
        setCustomerOptions(formattedOptions);
      }

    } catch (error) {
      console.error('Error fetching customer options:', error);
    }
  };


  const handleInputChange = (inputValue) => {
    fetchCustomerNameOptions(inputValue);
  };

  const handleChange = (selectedOption) => {
    setCustomerInputValue(selectedOption);
  };


	const handleDateChange = (value) => {
    setDates(value);
  };
  
  const handleChangePlRoute = (event) => {
	  const value = event.target.value;
	  setPlRoute(value);
	};

	const handleChangeSalesRap = (event) => {
	  const value = event.target.value;
	  setSalesRap(value);
	};

	const searchFormData = () => {
  	let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
  	let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
  	let searchDate = dates[0] ? (startDate+' - '+endDate) : '';
  	setReportrange(searchDate);
  	fetchReport();
  };

  const fetchSalesRapOptions = async () => {
	
		const apiSalesRapUrl = `${config.API_URL}/getsalesrep_for_profit_and_loss`;
    try {
     	const response = await fetch(apiSalesRapUrl, {
      		method: 'POST',
      		headers: {
        			authorization: `Bearer ${accessToken}`,
      		}
    		});

      	const data = await response.json();
      	const formattedOptions = data.sales_rep.map(item => ({
              key: `${item.name}`,
              value: item.id,
          }));
          setSalesRapOptions(formattedOptions);
    }catch (error) {
      console.error('api fail Error fetching Not Sales Rap:', error);
    }
	};

	const fetchRouteOptions = async () => {
		const apiRouteUrl = `${config.API_URL}/getRoute`;
    try {
     	const response = await fetch(apiRouteUrl, {
      		method: 'POST',
      		headers: {
        			authorization: `Bearer ${accessToken}`,
      		}
    		});

      	const data = await response.json();
      	const formattedOptions = data.data.map(item => ({
              key: `${item.route_number}`,
              value: item.id,
          }));
          setPlRouteOptions(formattedOptions);
    }catch (error) {
      console.error('api fail Error fetching Not Sales Rap:', error);
    }
	};

	const fetchReport = async () => {
		let customerId = customerInputValue ? customerInputValue : '';
		const formData = new FormData();
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('reportrange', reportrange);
    formData.append('customer_id', customerId.value ? customerId.value : '');
    formData.append('sales_rep_id', salesRap);
    formData.append('route_id', plRoute);
   
    const apiUrl = `${config.API_URL}/get_profit_loss_report`;
    try {
     	const response = await fetch(apiUrl, {
      		method: 'POST',
      		headers: {
        		// 'Content-Type': 'application/json',
        		authorization: `Bearer ${accessToken}`,
      		},
      		body: formData,
    		});

      	if (!response.ok) {
        	throw new Error('Failed to fetch Not Order Report');
      	}
      	const data = await response.json();
      	//console.log(data.data);
      	setProfitLossReport(data.data);
      	setReportDate(data.form_date+' To Date : '+data.to_date);
      	setAmtReport(data.netamount+'  | Cost Total : '+data.cost_total+' | Profit Total : '+data.profit_total);
    }catch (error) {
      console.error('api fail Error fetching Not Order Report:', error);
    }

  };

	useEffect(() => {

		if (accessToken === null){
      window.location.href = '/login'; 
      return;
    }

  	fetchReport();
  	fetchSalesRapOptions();
  	fetchRouteOptions();
	},[reportrange]);

	useEffect(() => {
  	const table = $('#profileLossReport').DataTable({
    		pageLength: 50,
	    paging: true,
	    lengthMenu: [10, 20, 50],
	    searching: true,
	    ordering: true,
	    info: true,
	    responsive: true,
	    autoWidth: false,
	    language: {
	        paginate: {
	            first: 'First',
	            last: 'Last',
	            next: 'Next',
	            previous: 'Previous',
	        },
	        search: 'Search:',
	    },
    		data: profitLossReport,
    		columns: [
      		{ data: null },
      		{ data: 'reference_no' },
      		{ data: 'date' },
      		{ data: 'customer' },
      		{ data: 'netamount' },
      		{ data: 'cost' },
      		{ data: 'profit' },
      		{ data: 'status' },
      		
  		],
  		columnDefs: [
	        { 
	            targets: [0, 1, 2, 3, 4], 
	            orderable: false 
	        }
	    ],
  		drawCallback: function (settings) {
      		var api = this.api();
      		var startIndex = api.page.info().start;
	        api.column(0, { page: 'current' }).nodes().each(function (cell, i) {
	            cell.innerHTML = startIndex + i + 1;
	        });
  		}
  	});
  	return () => {
    		table.destroy();
  	};
	}, [profitLossReport]);
	

	return(
		<div>
			<Header />
			<div className="breadcrumbs">
    		<div className="container-fluid">
        	<div className="row">
            <div className="col-12 col-lg-12 col-sm-12"> 
              <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
              <Link to="/reports/profit_loss_report">Reports</Link> 
              <Link to="/reports/profit_loss_report">Profit and Loss</Link> 
            </div>
        	</div>
    		</div>
	    </div>
	    <div className="main-container">
			  <div className="container-fluid">
			    
			  <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="date-range-picker col-md-2 cp-lg-2 col-sm-2" style={{ float: 'left' }}>
                      <label style={{marginBottom: '5px'}} ><b>Select Date</b></label>
					  
					  <DateRangePicker value={dates}
							    onChange={handleDateChange}
							    format="MM/dd/yyyy"
							    character=" - "
							    style={{ width: '100%' }}
							  />
                    </div>
                    <div className="col-md-2 cp-lg-2 col-sm-2" style={{float: 'left', paddingLeft: '20px'}}>    		
		            <label style={{marginBottom: '5px'}} ><b>Customer</b></label>
						    <Select
						      isClearable
						      onInputChange={handleInputChange}
						      onChange={handleChange}
						      options={customerOptions}
						      placeholder="Search..."
						      value={customerInputValue}
						    />
							</div>

							<div className="col-md-2 cp-lg-2 col-sm-2" style={{float: 'left', paddingLeft: '20px'}}>    		
		            <label style={{marginBottom: '5px'}} ><b>Route</b></label>
					<select value={plRoute} onChange={handleChangePlRoute}  className="form-control selectinput" >
				          <option value="">Select Route</option>
				          {plRouteOptions.map(option => (
				              <option key={option.value} value={option.value}>
				              {option.key}
				          </option>
				          ))}
				        </select>
							</div>


							<div className="col-md-2 cp-lg-2 col-sm-2" style={{float: 'left', paddingLeft: '20px'}}>    		
							<label style={{marginBottom: '5px'}} ><b>Sales Rep</b></label>
							<select value={salesRap} onChange={handleChangeSalesRap}  className="form-control  selectinput" name="" id="" >
                    <option value="">Sales Rep</option>
                     {salesRapOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.key}
                            </option>
                      ))}
                </select>
							</div>


							<div className="col-md-2 cp-lg-2 col-sm-2" style={{ float: 'left', paddingLeft: '20px' }} >
                      <button type="submit" className="acceptbtn" style={{ float: 'left', margin: '28px 0 0 0px' }} >Submit</button>
                    </div>

					




                  </div>

                </div>



		      <div className="total-manifests-area dashboard">
	          <p><b>Profit and Loss From : {reportDate}  </b></p>
	          <p className="text-center" style={{border:'none',margin: '0px', padding: '0px;'}} ><b>Net Amount : {amtReport} </b></p>
	          <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
	            <table className="table table-bordered" id="profileLossReport">
	              <thead>
                  <tr>
                    <th>Sr.no</th>
                    <th>Order Id</th>
                    <th>Order Data</th>
                    <th>Customer (A/C No)</th>
                    <th>Net Amount</th>
                    <th>Cost</th>
                    <th>Profit</th>
                    <th>Status</th>
                  </tr>
	              </thead>
                <tbody>
                    
                </tbody>               
	            </table>
	          </div>
	        </div>
			  </div>
			</div>
	   	<Footer />

		</div>

	);
}

export default ProfitLossReport;