import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';

const SalesView = () => {


  return (
    <div>
      <Header />
      <div class="breadcrumbs">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <a href="new-sales.html">New Sales</a> &gt; <span class="last-crumb">Sales View</span></div>
    </div>
  </div>
</div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="show-order-area dashboard">
                <div className="sales-view-area">
                  <h3>
                    <span>Order - 27A07285</span> <a href="#">Print</a>
                  </h3>
                  <div className="row">
                    <div className="col-6">
                      <p>
                        <span>Ramee System Pvt Ltd</span> test 1 abcd<br />
                        London E1 7EA, UK<br />
                        <span>Tel: 9158211122</span>{' '}
                        <span>Email: Kc@gmail.com</span>{' '}
                        <span>Customer A/C Number : 0101</span>
                      </p>
                    </div>
                    <div className="col-6">
                      <ul>
                        <li><span>Order :</span> 27A07285</li>
                        <li><span>Order Date :</span> 01-07-2024 09:16</li>
                        <li><span>Sale Status :</span> Accept</li>
                        <li><span>Route Number :</span> JR1</li>
                        <li><span>Payment Status :</span> Pending</li>
                      </ul>
                    </div>
                  </div>

                  <div className="sales-view-table">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Description</th>
                          <th>Quantity</th>
                          <th>Order Type</th>
                          <th>Rate</th>
                          <th>VAT</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>01</td>
                          <td>0869 - ALOEVERA BLUEBERRYY 12x500ml</td>
                          <td>1.00</td>
                          <td>box</td>
                          <td>£8.95</td>
                          <td>£1.79</td>
                          <td>£10.74</td>
                        </tr>
                        <tr>
                          <td>02</td>
                          <td>1098 - AQUA SPARKLING WATER 24x500ml</td>
                          <td>1.00</td>
                          <td>box</td>
                          <td>£8.85</td>
                          <td>£1.77</td>
                          <td>£10.62</td>
                        </tr>
                        <tr>
                          <td>03</td>
                          <td>0869 - ALOEVERA BLUEBERRYY 12x500ml</td>
                          <td>1.00</td>
                          <td>box</td>
                          <td>£5.90</td>
                          <td>£1.18</td>
                          <td>£7.08</td>
                        </tr>
                        <tr>
                          <td>04</td>
                          <td>0869 - ALOEVERA BLUEBERRYY 12x500ml</td>
                          <td>1.00</td>
                          <td>box</td>
                          <td>£8.95</td>
                          <td>£1.79</td>
                          <td>£10.74</td>
                        </tr>
                        <tr>
                          <td>05</td>
                          <td>0869 - ALOEVERA BLUEBERRYY 12x500ml</td>
                          <td>1.00</td>
                          <td>box</td>
                          <td>£8.95</td>
                          <td>£1.79</td>
                          <td>£10.74</td>
                        </tr>
                        <tr>
                          <td colSpan="6"><span className="right">Total Std Goods</span></td>
                          <td><span>£43.84</span></td>
                        </tr>
                        <tr>
                          <td colSpan="6"><span className="right">VAT Amount</span></td>
                          <td><span>£8.77</span></td>
                        </tr>
                        <tr>
                          <td colSpan="6"><span className="right">Total Zero Goods</span></td>
                          <td><span>0.00</span></td>
                        </tr>
                        <tr>
                          <td colSpan="6"><span className="right">Order Discount</span></td>
                          <td><span>0.00</span></td>
                        </tr>
                        <tr>
                          <td colSpan="6"><span className="right">Total Amount</span></td>
                          <td><span>£52.61</span></td>
                        </tr>
                      </tbody>
                    </table>
                    <p>Ordered By: k c Chandak</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SalesView;
