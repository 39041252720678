import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL } from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import Swal from 'sweetalert2';
import config from "../common/Config";
import { Link } from 'react-router-dom';



const Save_Exit = () => {

return (            
           
  <div class="container">
  <div class="modal fade" id="Save-exit" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">&nbsp;</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="saveexit-popup"><p>Do you want to Save Current Order</p>	</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="closebtn" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="printbtn">Save</button>
      </div>
    </div>
  </div>
</div>
  </div>

           
      
    );
};

export default Save_Exit;
