import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import moment from 'moment';

const DateRangePickerComponent = () => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: moment().subtract(6, 'days').toDate(),
    endDate: new Date(),
    key: 'selection',
  });

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const predefinedRanges = [
    {
      label: 'Today',
      startDate: moment(),
      endDate: moment(),
    },
    {
      label: 'Yesterday',
      startDate: moment().subtract(1, 'days'),
      endDate: moment().subtract(1, 'days'),
    },
    {
      label: 'Last 7 Days',
      startDate: moment().subtract(6, 'days'),
      endDate: moment(),
    },
    {
      label: 'Last 30 Days',
      startDate: moment().subtract(29, 'days'),
      endDate: moment(),
    },
    {
      label: 'This Month',
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
    },
    {
      label: 'Last Month',
      startDate: moment().subtract(1, 'month').startOf('month'),
      endDate: moment().subtract(1, 'month').endOf('month'),
    },
  ];

  return (
    <div>
      <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelect}
        rangeColors={['#007bff']}
        editableDateInputs={true}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        direction="horizontal"
        showDateDisplay={true}
        maxDate={new Date()}
        ranges={predefinedRanges.map(range => ({
          ...range,
          startDate: range.startDate.toDate(),
          endDate: range.endDate.toDate()
        }))}
      />
    </div>
  );
};

export default DateRangePickerComponent;
