import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';

const Edit_Supplier = () => {
  
  const { supplierId } = useParams();

  const accessToken = localStorage.getItem('accessToken');  
  const navigateToSupplierList = useNavigate(); 
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    id : '',
    company: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    vat_no: '',
    contact_person: '',
    contact_person_mob: '',
    contact_person_email: '',
    city: '',
    state: '',
    country: '',
    postal_code: '',
    po_delivery_days: '',
    payment_clearance_days: '',
    tobacco: '',
    tpd_license_no: '',
    tpd_license_validity: '',
    alcohol: '',
    awrs_license_no: '',
    awrs_license_validity: '',
    finance_person: '',
    finance_person_mob: '',
    finance_person_email: '',
    supplier_account_number: '',
    supplier_branch_name: '',
    supplier_bank_name: '',
    supplier_branch_code: '',
  });
  const validateForm = () => {
    const newErrors = {};

    // Company validation
    if (!formData.company) {
      newErrors.company = "Mention company Name.";
    } else if (formData.company.length < 2) {
      newErrors.company = "Company name must consist of at least 2 characters.";
    } else if (formData.company.length > 255) {
      newErrors.company = "Company name must not exceed 255 characters.";
    }

    // Name validation
    if (!formData.name) {
      newErrors.name = "Mention Name.";
    } else if (formData.name.length < 2) {
      newErrors.name = "Name must consist of at least 2 characters.";
    } else if (formData.name.length > 255) {
      newErrors.name = "Name must not exceed 255 characters.";
    }

    // Phone validation
    if (!formData.phone) {
      newErrors.phone = "Phone is Required.";
    } else if (!/^\d{10,12}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be between 10 and 12 digits.";
    }

    // Email validation
    if (!formData.email) {
      newErrors.email = "Mention Email Id.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email.";
    }

    // Contact person validation
    if (!formData.contact_person) {
      newErrors.contact_person = "Mention person Name.";
    } else if (formData.contact_person.length < 2) {
      newErrors.contact_person = "Contact person name must consist of at least 2 characters.";
    } else if (formData.contact_person.length > 255) {
      newErrors.contact_person = "Contact person name must not exceed 255 characters.";
    }

    // Contact person mobile validation
    if (!formData.contact_person_mob) {
      newErrors.contact_person_mob = "Contact person mobile is Required.";
    } else if (!/^\d{10,12}$/.test(formData.contact_person_mob)) {
      newErrors.contact_person_mob = "Contact person mobile must be between 10 and 12 digits.";
    }

    // Contact person email validation
    if (!formData.contact_person_email) {
      newErrors.contact_person_email = "Mention Contact Person Email.";
    } else if (!/\S+@\S+\.\S+/.test(formData.contact_person_email)) {
      newErrors.contact_person_email = "Please enter a valid contact person email.";
    }

    // Finance person validation
    if (!formData.finance_person) {
      newErrors.finance_person = "Mention Finance Person Name.";
    } else if (formData.finance_person.length < 2) {
      newErrors.finance_person = "Finance person name must consist of at least 2 characters.";
    } else if (formData.finance_person.length > 255) {
      newErrors.finance_person = "Finance person name must not exceed 255 characters.";
    }

    
    if (!formData.finance_person_mob) {
      newErrors.finance_person_mob = "finance mobile is Required.";
    } else if (!/^\d{10,12}$/.test(formData.finance_person_mob)) {
      newErrors.finance_person_mob = "finance person mobile must be between 10 and 12 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let fieldErrors = {};

    
    switch (name) {
      case 'company':
        if (!value) {
          fieldErrors.company = "Mention company Name.";
        } else if (value.length < 2) {

          fieldErrors.company = "Company name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.company = "Company name must not exceed 255 characters.";
        }
         
        
        if (!fieldErrors.company) {
           fieldErrors.company = '';
        }
        break;

      case 'name':
        if (!value) {
          fieldErrors.name = "Mention Name.";
        } else if (value.length < 2) {
          fieldErrors.name = "Name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.name = "Name must not exceed 25 characters.";
        }
        if (!fieldErrors.name) {
          fieldErrors.name = '';
        }
        break;

      case 'phone':
        if (!value) {
          fieldErrors.phone = "Phone is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.phone = "Phone number must be between 10 and 12 digits.";
        }
        if (!fieldErrors.phone) {
          
          fieldErrors.phone = '';
        }
        break;

      case 'email':
        if (!value) {
          fieldErrors.email = "Mention Email Id.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          fieldErrors.email = "Please enter a valid email.";
        }
        if (!fieldErrors.email) {
          fieldErrors.email = '';
        }
        break;

      case 'contact_person':
        if (!value) {
          fieldErrors.contact_person = "Mention person Name.";
        } else if (value.length < 2) {
          fieldErrors.contact_person = "Contact person name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.contact_person = "Contact person name must not exceed 255 characters.";
        }
        if (!fieldErrors.contact_person) {
          fieldErrors.contact_person = '';
        }
        break;

      case 'contact_person_mob':
        if (!value) {
          fieldErrors.contact_person_mob = "Contact person mobile is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.contact_person_mob = "Contact person mobile must be between 10 and 12 digits.";
        }
        if (!fieldErrors.contact_person_mob) {
          fieldErrors.contact_person_mob = '';
        }
        break;

      case 'contact_person_email':
        if (!value) {
          fieldErrors.contact_person_email = "Mention Contact Person Email.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          fieldErrors.contact_person_email = "Please enter a valid contact person email.";
        }
        if (!fieldErrors.contact_person_email) {
          fieldErrors.contact_person_email = '';
        }
        break;

      case 'finance_person':
        if (!value) {
          fieldErrors.finance_person = "Mention Finance Person Name.";
        } else if (value.length < 2) {
          fieldErrors.finance_person = "Finance person name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.finance_person = "Finance person name must not exceed 255 characters.";
        }
        if (!fieldErrors.finance_person) {
          fieldErrors.finance_person = '';
        }
        break;

      case 'finance_person_mob':
        if (!value) {
          fieldErrors.finance_person_mob = "Contact person mobile is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.finance_person_mob = "Contact person mobile must be between 10 and 12 digits.";
        }
        if (!fieldErrors.finance_person_mob) {
          fieldErrors.finance_person_mob = '';
        }
        break;

      default:
        break;
    }

    // Update errors state for the specific field
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors
    }));
  };

  const submitUpdateSupplierForm = async () => {

    if (!validateForm()) return; // Validate before submission

    const form = document.getElementById('editsupplierform');

    const formData = new FormData(form);
    try {
      const response = await axios.post(`${config.API_URL}/update_supplier`, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        }
      });

      const result = response.data;
     
      console.log(result);
      if (result.success == true){
          Swal.fire({
            title: "Success!",
            text: "Supplier updated successfully.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => 
          {
            
             navigateToSupplierList(`/supplier/`);
            
          });

      }else{
        Swal.fire({
          title: "Failed!",
          text: "Supplier updated Failed. Try Again",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }


      } catch (error) {
        console.error("Error accepting order:", error);
      }
  };


  const fetchEditSupplierData = async () => {
      const formData = new FormData();
      formData.append('id', supplierId);
      const apiUrl = `${config.API_URL}/edit_supplier`;
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            // 'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to fetch supplier data');
        }

        const data = await response.json();

       

        setFormData({
          id : data.data.id,
          company: data.data.company,
          name: data.data.name,
          email: data.data.email,
          phone: data.data.phone,
          address: data.data.address,
          vat_no: data.data.vat_no,
          contact_person: data.data.contact_person,
          contact_person_mob: data.data.contact_person_mob,
          contact_person_email: data.data.contact_person_email,
          city: data.data.city,
          state: data.data.state,
          country: data.data.country,
          postal_code: data.data.postal_code,
          po_delivery_days: data.data.po_delivery_days,
          payment_clearance_days: data.data.payment_clearance_days,
          tobacco: data.data.tobacco,
          tpd_license_no: data.data.tpd_license_no,
          tpd_license_validity: data.data.tpd_license_validity,
          alcohol: data.data.alcohol,
          awrs_license_no: data.data.awrs_license_no,
          awrs_license_validity: data.data.awrs_license_validity,
          finance_person: data.data.finance_person,
          finance_person_mob: data.data.finance_person_mob,
          finance_person_email: data.data.finance_person_email,
          supplier_account_number: data.data.supplier_account_number,
          supplier_branch_name: data.data.supplier_branch_name,
          supplier_bank_name: data.data.supplier_bank_name,
          supplier_branch_code: data.data.supplier_branch_code,
        });

     
      } catch (error) {
        console.error('Error fetching supplier data:', error);
      }
  };

  
  useEffect(() => {

    if (accessToken === null) {
      window.location.href = 'react/login';
      return;
    }

    fetchEditSupplierData();

    //navigateToSupplierList(`/supplier/`);

      
  }, []);
  
  return (
    <div>
      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="Dashboard">Master</a> &gt; <span class="last-crumb">Edit Supplier</span> </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div style={{ position: 'relative' }}>
                <div className='add-supplier-popup'>
                  <form id='editsupplierform'>

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="company" className="col-form-label">Company *</label>
                            <input type="text" className="form-control" name="company" id="company" value={formData.company} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.company && <span className="text-danger">{errors.company}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                          <label htmlFor="name" className="col-form-label">Name *</label>
                            <input type="text" className="form-control" name="name" id="name" value={formData.name} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.name && <span className="text-danger">{errors.name}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="email" className="col-form-label">Email Address *</label>
                            <input type="email" className="form-control" name="email" id="email" value={formData.email} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.email && <span className="text-danger">{errors.email}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="phone" className="col-form-label">Phone *</label>
                            <input type="number" className="form-control no-arrows" name="phone" id="phone" value={formData.phone} onChange={handleChange} style={{ appearance: 'none', MozAppearance: 'textfield' }} />
                          </div>
                          <div className="col-sm-12">
                            {errors.phone && <span className="text-danger">{errors.phone}</span>}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <label htmlFor="address" className="col-form-label">Address</label>
                        <input type="text" className="form-control" name="address" id="address" value={formData.address} onChange={handleChange} />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="vat_no" className="col-form-label">VAT Number</label>
                        <input type="number" className="form-control" name="vat_no" id="vat_no" value={formData.vat_no} onChange={handleChange} />
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="contact_person" className="col-form-label">Contact Person Name *</label>
                            <input type="text" className="form-control" name="contact_person" id="contact_person" value={formData.contact_person} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.contact_person && <sapn className="text-danger">{errors.contact_person}</sapn>}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="contact_person_mob" className="col-form-label">Contact Person Mobile *</label>
                            <input type="number" className="form-control no-arrows" name="contact_person_mob" id="contact_person_mob"  value={formData.contact_person_mob} onChange={handleChange} style={{ appearance: 'none', MozAppearance: 'textfield' }} />
                          </div>
                          <div className="col-sm-12">
                            {errors.contact_person_mob && <span className="text-danger">{errors.contact_person_mob}</span>}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="contact_person_email" className="col-form-label">Contact Person Email *</label>
                            <input type="email" className="form-control" name="contact_person_email" id="contact_person_email" value={formData.contact_person_email} onChange={handleChange}  />
                          </div>
                          <div className="col-sm-12">
                            {errors.contact_person_email && <span className="text-danger">{errors.contact_person_email}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="city" className="col-form-label">City</label>
                        <input type="text" className="form-control" name="city" id="city" value={formData.city} onChange={handleChange} />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="state" className="col-form-label">State</label>
                        <input type="text" className="form-control" name="state" id="state"  value={formData.state} onChange={handleChange} />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="country" className="col-form-label">Country</label>
                        <input type="text" className="form-control" name="country" id="country" value={formData.country} onChange={handleChange}  />
                      </div>
                    </div>

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <label htmlFor="postal_code" className="col-form-label">Postal Code</label>
                        <input type="text" className="form-control" name="postal_code" id="postal_code" value={formData.postal_code} onChange={handleChange} />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="po_delivery_days" className="col-form-label">Standard PO Delivery (in days)</label>
                        <input type="text" className="form-control" name="po_delivery_days" id="po_delivery_days" value={formData.po_delivery_days} onChange={handleChange} />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="payment_clearance_days" className="col-form-label">Invoice Payment Clearance after (in days)</label>
                        <input type="text" className="form-control" name="payment_clearance_days" id="payment_clearance_days" value={formData.payment_clearance_days} onChange={handleChange} />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="tobacco" className="col-form-label">Allowed for Tobacco Product Supply</label>
                        <select name="tobacco" className="form-select orgselect"  value={formData.tobacco} onChange={handleChange} >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                    <input type="hidden" className="form-control" name="sid" value={formData.id}  />

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <label htmlFor="tpd_license_no" className="col-form-label">TPD License Number</label>
                        <input type="text" className="form-control" name="tpd_license_no" id="tpd_license_no" value={formData.tpd_license_no} onChange={handleChange} />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="tpd_license_validity" className="col-form-label">TPD License Validity Till Date</label>
                        <input type="date" className="form-control" name="tpd_license_validity" id="tpd_license_validity" value={formData.tpd_license_validity} onChange={handleChange} />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="alcohol" className="col-form-label">Allowed for Alcohol Storage</label>
                        <select name="alcohol" className="form-select orgselect" value={formData.alcohol} onChange={handleChange} >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="awrs_license_no" className="col-form-label">AWRS License Number</label>
                        <input type="text" className="form-control" name="awrs_license_no" id="awrs_license_no" value={formData.awrs_license_no} onChange={handleChange} />
                      </div>
                    </div>

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <label htmlFor="awrs_license_validity" className="col-form-label">AWRS License Validity Till Date</label>
                        <input type="date" className="form-control" name="awrs_license_validity" id="awrs_license_validity" value={formData.awrs_license_validity} onChange={handleChange} />
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="finance_person" className="col-form-label">Financial Person Name *</label>
                            <input type="text" className="form-control" name="finance_person" id="finance_person" value={formData.finance_person} onChange={handleChange}  />
                          </div>
                          <div className="col-sm-12">
                            {errors.finance_person && <div className="text-danger">{errors.finance_person}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="finance_person_mob" className="col-form-label">Financial Person Mobile *</label>
                            <input type="number" className="form-control no-arrows" name="finance_person_mob" id="finance_person_mob" value={formData.finance_person_mob} onChange={handleChange} style={{ appearance: 'none', MozAppearance: 'textfield' }} />
                          </div>
                          <div className="col-sm-12">
                            {errors.finance_person_mob && <div className="text-danger">{errors.finance_person_mob}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="finance_person_email" className="col-form-label">Financial Person Email</label>
                        <input type="email" className="form-control" name="finance_person_email" id="finance_person_email" value={formData.finance_person_email} onChange={handleChange} />
                      </div>
                    </div>

                    <hr />
                    <h5>Banking Details</h5>

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <label htmlFor="supplier_account_number" className="col-form-label">Bank Account Number</label>
                        <input type="text" className="form-control" name="supplier_account_number" id="supplier_account_number" value={formData.supplier_account_number} onChange={handleChange} />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="supplier_branch_name" className="col-form-label">Branch Name</label>
                        <input type="text" className="form-control" name="supplier_branch_name" id="supplier_branch_name" value={formData.supplier_branch_name} onChange={handleChange}  />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="supplier_bank_name" className="col-form-label">Bank Name</label>
                        <input type="text" className="form-control" name="supplier_bank_name" id="supplier_bank_name" value={formData.supplier_bank_name} onChange={handleChange} />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="supplier_branch_code" className="col-form-label">Branch Code</label>
                        <input type="text" className="form-control" name="supplier_branch_code" id="supplier_branch_code" value={formData.supplier_branch_code} onChange={handleChange} />
                      </div>
                    </div>

                    <div className='col-12 col-lg-12 col-sm-12'>
                      <hr />
                    </div>

                    <div className='row mb-3 padd-l-13px'>
                      <div className='col-12 col-lg-12 col-sm-12'>
                        <button onClick={submitUpdateSupplierForm} type="button" className="float-right-btn common-popup-btn">Update Supplier</button>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Edit_Supplier; 