import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Header';
import Footer from '../../Footer';
import config from "../../common/Config";
import ActiveProductList from './ActiveProductList';
import InactiveProductList from './InactiveProductList';
import AllProductList from './AllProductList';

const Products = () => {
  const [activeTab, setActiveTab] = useState('eur'); // Track active tab

  // Handle tab change
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt;
              <a href="Dashboard">Master</a> &gt;
              <span className="last-crumb">Product</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="bank-pagearea">
                <div>
                  
                <div style={{ float: "right" }}>
                    <Link to={`${config.BASE_URL}/products/add_product`} className="create-catalogue-btn" style={{ marginBottom: "-10px" }}>
                      Add Product
                    </Link>
                </div>
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab === 'eur' ? 'active' : ''}`}
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#eur"
                        type="button"
                        role="tab"
                        aria-controls="eur"
                        aria-selected={activeTab === 'eur'}
                        onClick={() => handleTabChange('eur')}
                      >
                        Active Products
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab === 'gbp' ? 'active' : ''}`}
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#gbp"
                        type="button"
                        role="tab"
                        aria-controls="gbp"
                        aria-selected={activeTab === 'gbp'}
                        onClick={() => handleTabChange('gbp')}
                      >
                        Inactive Products
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab === 'usd' ? 'active' : ''}`}
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#usd"
                        type="button"
                        role="tab"
                        aria-controls="usd"
                        aria-selected={activeTab === 'usd'}
                        onClick={() => handleTabChange('usd')}
                      >
                        All Products
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content fulltable-area" id="myTabContent">
                    <div className={`tab-pane fade ${activeTab === 'eur' ? 'show active' : ''}`} id="eur" role="tabpanel" aria-labelledby="eur-tab">
                      <div className="backlist-content-area">
                        
                        <div className="verical-scrl-wrapper common-table" id="style-3">
                          <ActiveProductList refresh={activeTab === 'eur'} />
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'gbp' ? 'show active' : ''}`} id="gbp" role="tabpanel" aria-labelledby="gbp-tab">
                      <div className="backlist-content-area">
                        
                        <div className="verical-scrl-wrapper common-table" id="style-3">
                          <InactiveProductList refresh={activeTab === 'gbp'} />
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'usd' ? 'show active' : ''}`} id="usd" role="tabpanel" aria-labelledby="usd-tab">
                      <div className="backlist-content-area">
                       
                        <div className="verical-scrl-wrapper common-table" id="style-3">
                          <AllProductList refresh={activeTab === 'usd'} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Products;
