import React, { useState, useEffect, useRef } from 'react';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';


const Customer_Credit_Facility = () => {
    
  const [priceAllocation, setPriceAllocation] = useState([
    { value: '', label: 'Select' },
    { value: '0', label: 'Price 1' },
    { value: '1', label: 'Price 2' },
    { value: '2', label: 'Price 3' },
    { value: '3', label: 'Price 4' },
    { value: '4', label: 'Price 5' },
    { value: '5', label: 'Price 6' },
  ]);

  const [creditType, setCreditType] = useState([
    { value: '', label: 'Select' },
    { value: 'amount_wise', label: 'Cash (Amount Wise)' },
    { value: 'day_wise', label: 'Credit (Credit Wise)' },
    { value: 'invoice_wise', label: 'TC (Invoice Wise)' },
  ]);

  const creditFacilityTableRef = useRef(null);
  const [searchValue, setSearchValue] = useState([]);
  const accessToken = localStorage.getItem('accessToken');
  const [checkedItems, setCheckedItems] = useState({});

  const fetchCreditFacility = () => {
    const $table = $(creditFacilityTableRef.current);
    const searchValue = $('#searchValue').val();
    if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
    }
  
    let i = 0;
    $table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
            url: `${config.API_URL}/customers_credit_facility`,
            type: 'POST',
            headers: {
               authorization : `Bearer ${accessToken}`,
            },
            data: function (d) {
                return $.extend({}, d, {
                    draw: d.draw,
                    start: d.start,
                    length: d.length,
                    searchValue : searchValue
                });
            },
            dataSrc: function (json) {
               
                return Array.isArray(json.data) ? json.data : [];
                console.log(json);
            }
        },
        language: {
            paginate: {
                first: 'First',
                last: 'Last',
                next: 'Next',
                previous: 'Previous',
            },
            search: 'Search:',
        },
        columns: [
            
            {
              data: null,
              width : '4%',
              className : 'text-center',
              render: function (data, type, row) { 
                const isChecked = !!checkedItems[row.id];
                  return `
                    <label class="checkbox" style="margin: 0px auto;float: none;">
                      <input 
                          type="checkbox" 
                          value="${row.id}" 
                          class="dataTableCheckbox"
                          ${isChecked ? 'checked' : ''}
                          onclick="handleCheckboxChange(event, ${row.id})"
                      />
                      <span class="default"></span>
                    </label>
                  `;
                }
            },
            { 
              data: null,
              render : function(data, type, row){
                return `<span>${row.company}</span>`;
              }   
            },
            { 
              data: null,
              render : function(data, type, row){
                return `<span>${row.name}</span>`;
              }   
            },
            { 
              data: null,
              render : function(data, type, row){
                return `<span>${row.postal_code}</span>`;
              }   
            },
            { 
              data: null,
              render : function(data, type, row){
                return `<span>${row.route_number}</span>`;
              }   
            },
            { 
              data: null,
              render : function(data, type, row){
                return `<span>${row.credit_type_name}</span>`;
              }   
            },
            
        ],
        columnDefs: [
          {
              orderable: false, 
              targets: '_all'
          },
         
      ],
      
        pageLength: 10,
    });
        
     window.handleCheckboxChange = (event, id) => {
        const newCheckedItems = { ...checkedItems };
        if (event.target.checked) {
            newCheckedItems[id] = true;
        } else {
            delete newCheckedItems[id];
        }
        setCheckedItems(newCheckedItems);
    };
  };

  useEffect(() => {
    if (accessToken == null) {
      window.location.href = 'react/login';
      return;
    }
    fetchCreditFacility(); 
    return () => {
        if (creditFacilityTableRef.current && $.fn.DataTable.isDataTable(creditFacilityTableRef.current)) {
            $(creditFacilityTableRef.current).DataTable().destroy();
        }
       
    };
  }, [searchValue]);

  const handleCreditFacility = async () => {
      const selectedIds = Object.keys(checkedItems);
      if (selectedIds.length === 0) {
        Swal.fire({
          title: 'Error',
          text: 'Please select customer.',
          icon: 'error',
          showConfirmButton: false, 
          timer: 2000,               
          timerProgressBar: true,     
        });
          return;
      }

      const formData = new FormData();
      selectedIds.forEach(id => formData.append('ids[]', id));

     /* try {
          const response = await fetch(`${config.API_URL}/ws-complete`, {
              method: 'POST',
              headers: {
                  'authorization': `Bearer ${accessToken}`
              },
              body: formData,
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const data = await response.json();
          console.log(data);
      } catch (error) {
          console.error('Error:', error);
      }*/
    };

  return (
    <div>
      <Header />
        <div className="breadcrumbs">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-12 col-sm-12">
                <a href="#"><i className="fa fa-home"></i></a> &gt; 
                <a href="Dashboard">Master</a> &gt; 
                <span className="last-crumb">Customer Credit Facility</span>
              </div>
            </div>
          </div>
        </div>

        <div className="main-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-12 col-sm-12">
                <div style={{ position: 'relative' }}>
                  <div className="row">
                    <div className="col-12 col-lg-4 col-sm-4">
                      <div className="row">
                        <label htmlFor="price_allocation" className="col-sm-4 col-form-label" style={{textAlign: 'left'}}>Price Allocation:</label>
                        <div className="col-sm-8">
                          <select name="price_allocation" id="price_allocation" className="form-select">
                            {priceAllocation.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4 col-sm-4">
                      <div className="row">
                        <label htmlFor="stock_type" className="col-sm-4 col-form-label" style={{textAlign: 'left'}}>Credit Type:</label>
                        <div className="col-sm-8">
                          <select name="stock_type" id="stock_type" className="form-select" aria-label="Default select example">
                            {creditType.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-2 col-sm-2"> &nbsp; </div>

                    <div className="col-12 col-lg-2 col-sm-2">
                      <div style={{ float: 'right' }}>
                        <a onClick={handleCreditFacility} className="create-catalogue-btn">Add Credit Facility</a>
                      </div>
                    </div>
                  </div>
                  
                  <div className="show-order-area dashboard" >
                    <div className="verical-scrl-wrapper common-table price-variatn-table" id="style-3">
                      <table className="table table-bordered dataTable" id="neworderTable" ref={creditFacilityTableRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Company Name (Ac No)</th>
                            <th>Contact Person</th>
                            <th>Post Code</th>
                            <th>Route Name</th>
                            <th>Credit Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <label className="checkbox">
                                <input type="checkbox" />
                                <span className="default"></span>
                              </label>
                            </td>
                            <td>LITTLE CHALFONT(90178)</td>
                            <td>CHALFONT ST GILES POST OFFICE</td>
                            <td>HP7 9PH</td>
                            <td>JR 8</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
    </div>
  );
};

export default Customer_Credit_Facility; 