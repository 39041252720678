// src/components/Config.js
//export const API_URL = 'https://apidev.ebusso.com';
export const API_URL = 'https://api.ebusso.com';
//export const BASE_URL = 'http://localhost:3000/react';


export const BASE_URL = 'https://daninternational.rameesystems.com/react';

//export const BASE_URL = 'https://demo.ebusso.com/react';
//export const BASE_URL = 'https://aytac.rameesystems.com/react';

//export const BASE_URL = 'https://kmfood.co.uk/react';

export const DEMO_URL = 'https://demo.ebusso.com';

 