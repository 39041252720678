import React, { useState, useEffect } from 'react';
import { API_URL } from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const Customer_Legder = () => {



  return (            

    <div class="container">
    <div class="modal fade" id="Customer-ledger" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">&nbsp;</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        
		  <div class="customer-ledger-table">
			<h5>Period From : 01-05-2024 To : 25-10-2024</h5> 
			  
			 <div class="customer-ledger-detail">
			  	<table class="table table-bordered dataTable">
				 	<thead>
						<tr>
							<th>JIGNESH MANDORA MANDORA(0197)</th>
							<th>&nbsp;</th>
							<th>0 to 30 Days</th>
							<th>0.00</th>
						</tr>
					</thead>
					
					<tbody>
						<tr>
							<td>Current O/S</td>
							<td>0.00	</td>
							<td>30 + Days	</td>
							<td>0.00</td>
						</tr>
						<tr>
							<td>Current O/S	</td>
							<td>0.00</td>
							<td>30 + Days	</td>
							<td>0.00</td>
						</tr>
						<tr>
							<td>Total Amount	</td>
							<td>0.00</td>
							<td>60 + Days	</td>
							<td>0.00</td>
						</tr>
						<tr>
							<td>Unallocated Amount	</td>
							<td><a href="#">2456.48</a></td>
							<td>90 + Days	</td>
							<td>0.00</td>
						</tr>
					</tbody>
					
				 </table>
			  </div>
			  
			  <div class="customer-ledger-list">
			  
			  <table class="table table-bordered dataTable">
  <thead>
    <tr>
      <th>Date</th>
      <th>Inv No</th>
		<th>Status</th>
		<th>Type</th>
		<th>Amount	</th>
		<th>Balance</th>
    </tr>
	  </thead>
	  <tbody>
    <tr>
      <td>03-05-2024</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>cash</td>
      <td>10.00</td>
      <td> -10.00</td>
    </tr>
		  
	<tr>
      <td>04-09-2024</td>
      <td>Opening Balance</td>
      <td>&nbsp;</td>
      <td>cash</td>
      <td>20.00</td>
      <td>80.00</td>
    </tr>
		  
		  <tr>
      <td>03-05-2024</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>cash</td>
      <td>10.00</td>
      <td> -10.00</td>
    </tr>
		  
	<tr>
      <td>04-09-2024</td>
      <td>Opening Balance</td>
      <td>&nbsp;</td>
      <td>cash</td>
      <td>20.00</td>
      <td>80.00</td>
    </tr>
    
  </tbody>
</table>
</div>
		  	
		  </div>
      </div>
      {/* <div class="modal-footer">
        <button type="button" class="closebtn" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="printbtn">Save</button>
      </div> */}
    </div>
  </div>
</div>	
    </div>



    );
  };

  export default Customer_Legder;
