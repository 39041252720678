import React, { useState } from 'react';

const TableComponent = () => {
  // Initial rows of the table
  const [rows, setRows] = useState([
    { id: 1, name: 'Row 1' },
    { id: 2, name: 'Row 2' },
    { id: 3, name: 'Row 3' },
  ]);

  // Function to remove a row by its id
  const removeRow = (id) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  const deleteRows = () => {
    try {
      setRows((prevRows) => prevRows.filter((row) => !row.checked));
    } catch (e) {
      alert(e);
    }
  };


  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              <td>{row.name}</td>
              <td>
                <button onClick={() => removeRow(row.id)}>Remove</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
