import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { API_URL } from '../Config';
import jsPDF from 'jspdf';  // Library to generate PDF
import 'jspdf-autotable';  // Plugin for auto table

const PurchaseHistoryViewPrint = (props) => {
  const { orderId } = useParams();

  const [productData, setProductData] = useState([]);
  const [shopDetails, setShopDetails] = useState([]);
  const [commonData, setCommonData] = useState([]);
  const [totalVal, setTotalVal] = useState({
    grandTotal: 0,
  });

  const accessToken = localStorage.getItem('accessToken');

  const fetchProductInfo = async () => {
    const formData = new FormData();
    formData.append('id', orderId);
    const apiUrl = `${API_URL}/purchases/grn_view_details`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch Not Order Report');
      }
      const data = await response.json();
      setCommonData(data.grn_details.grn_details[0]);
      setShopDetails(data.grn_details.shop_details);
      setProductData(data.grn_details.grn_items);

      const grandTotal = data.grn_details.grn_items.reduce((acc, item) => {
        const quantity = parseFloat(item.quantity);
        const unitCost = item.type === '1' ? parseFloat(item.unit_cost) : parseFloat(item.piece_cost);
        const tax = (unitCost * quantity) * parseFloat(item.rate) / 100;
        const stotal = unitCost * quantity;
        const pdtTotal = stotal + tax;
        return acc + pdtTotal;
      }, 0);

      setTotalVal({ grandTotal: formatNumber(grandTotal) });
    } catch (error) {
      console.error('API fail Error fetching Not Order Report:', error);
    }
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = '/login';
      return;
    }
    fetchProductInfo();
  }, [accessToken]);

  const formatNumber = (num) => {
    return parseFloat(num).toFixed(2);
  };

  useEffect(() => {
    const table = $('#productDetails').DataTable({
      pageLength: 50,
      paging: false,
      lengthMenu: [10, 20, 50],
      searching: false,
      ordering: false,
      info: false,
      responsive: true,
      autoWidth: false,
      data: productData,
      columns: [
        {
          data: null,
          render: function (data, type, row) {
            return `<span>${row.product_code} - ${row.product_name}</span>`;
          },
        },
        {
          data: null,
          className: 'text-center',
          render: function (data, type, row) {
            return `<span>${parseInt(row.quantity)}</span>`;
          },
        },
        {
          data: null,
          className: 'text-center',
          render: function (data, type, row) {
            return row.type === '1' ? 'Box' : 'Piece';
          },
        },
        {
          data: null,
          className: 'text-center',
          render: function (data, type, row) {
            const unitCost = row.type === '1' ? parseFloat(row.unit_cost) : parseFloat(row.piece_cost);
            return `<span>£ ${formatNumber(unitCost)}</span>`;
          },
        },
        {
          data: 'rate',
          className: 'text-center',
          render: function (data, type, row) {
            const quantity = parseFloat(row.quantity);
            const unitCost = row.type === '1' ? parseFloat(row.unit_cost) : parseFloat(row.piece_cost);
            const tax = (unitCost * quantity) * parseFloat(row.rate) / 100;
            return `<span>£ ${formatNumber(tax)}</span>`;
          },
        },
        {
          data: null,
          className: 'text-center',
          render: function (data, type, row) {
            const quantity = parseFloat(row.quantity);
            const unitCost = row.type === '1' ? parseFloat(row.unit_cost) : parseFloat(row.piece_cost);
            const stotal = unitCost * quantity;
            const tax = (unitCost * quantity) * parseFloat(row.rate) / 100;
            const pdtTotal = stotal + tax;
            return `<span>£ ${formatNumber(pdtTotal)}</span>`;
          },
        },
      ],
      columnDefs: [
        {
          targets: [0],
          orderable: false,
        },
      ],
    });
    return () => {
      table.destroy();
    };
  }, [productData]);

 /* const generatePDF = () => {
    const doc = new jsPDF();
    doc.text('Purchase History', 14, 20);

    const tableData = productData.map((item) => {
      const quantity = parseFloat(item.quantity);
      const unitCost = item.type === '1' ? parseFloat(item.unit_cost) : parseFloat(item.piece_cost);
      const tax = (unitCost * quantity) * parseFloat(item.rate) / 100;
      const stotal = unitCost * quantity;
      const pdtTotal = stotal + tax;
      return [
        item.product_code + ' - ' + item.product_name,
        item.quantity,
        item.type === '1' ? 'Box' : 'Piece',
        `£ ${formatNumber(unitCost)}`,
        `£ ${formatNumber(tax)}`,
        `£ ${formatNumber(pdtTotal)}`,
      ];
    });

    doc.autoTable({
      head: [
        ['Description', 'Quantity', 'Type', 'Unit Cost', 'Tax', 'Subtotal'],
      ],
      body: tableData,
      startY: 30,
      theme: 'grid',
    });

    doc.text(`Total: £ ${totalVal.grandTotal}`, 14, doc.lastAutoTable.finalY + 10);
    doc.save(`PurchaseHistory_${orderId}.pdf`);
  };*/

  const generatePDF = () => {
	    const doc = new jsPDF();

	    // Header
	    const header = [
	        [`Order Summary for ${shopDetails.shop_name}`, `Order Date: ${commonData.reference_no}`],
	        [`Order : INV${commonData.po_id}`, `Status: ${commonData.name}`],
	    ];

	    // Define columns for autoTable
	    const columns = [
	        { title: 'Description', dataKey: 'description' },
	        { title: 'Quantity', dataKey: 'quantity' },
	        { title: 'Unit Cost', dataKey: 'unit_cost' },
	        { title: 'Tax', dataKey: 'tax' },
	        { title: 'Subtotal', dataKey: 'subtotal' },
	    ];

	    // Prepare data for the table
	    const tableData = productData.map(item => ({
	        description: `${item.product_code} - ${item.product_name}`,
	        quantity: parseInt(item.quantity, 10),
	        unit_cost: `£ ${formatNumber(item.unit_cost)}`,
	        tax: `£ ${formatNumber(item.tax)}`,
	        subtotal: `£ ${formatNumber(item.total)}`
	    }));

	    // Add header to the document
	    doc.autoTable({
	        head: header,
	        startY: 10,
	        headStyles: { fontSize: 10, textColor: '#666', fillColor: '#eee' },
	        margin: { top: 20 },
	        showHead: 'firstPage',
	    });

	    // Add table body
	    const itemsPerPage = 20;
	    const totalPages = Math.ceil(tableData.length / itemsPerPage);

	    for (let i = 0; i < totalPages; i++) {
	        const startIndex = i * itemsPerPage;
	        const endIndex = startIndex + itemsPerPage;
	        const pageData = tableData.slice(startIndex, endIndex);

	        if (i > 0) {
	            doc.addPage();
	        }

	        doc.autoTable({
	            columns: columns,
	            body: pageData,
	            startY: doc.previousAutoTable.finalY + 10 || 20,
	            styles: { fontSize: 10 },
	        });

	        // Add footer only on the last page
	        if (i === totalPages - 1) {
	            const endY = doc.previousAutoTable.finalY;
	            doc.autoTable({
	                startY: endY + 10,
	                body: [[`Total : £ ${totalVal.grandTotal}`]],
	                styles: { fontSize: 10, halign: 'right' },
	            });
	        }
	    }

	    // Save the PDF
	    doc.save(`invoice_${commonData.supplierId}.pdf`);
	};

  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <span className="last-crumb">Purchases GRN</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="show-order-area dashboard">
            <div className="sales-view-area">
              <div className="row">
                <div className="col-7">
                  {/*<button onClick={generatePDF}>Generate PDF</button>*/}
                </div>
              </div>
              <div className="row page-header" style={{ paddingTop: '10px' }}>
                <div className="col-4">
                  <h6>
                    <span>{commonData.name}</span>
                  </h6>
                  <p>
                    <span>{commonData.note}</span>
                  </p>
                  <p>
                    <span>Tel : {commonData.phone}</span>
                  </p>
                  <p>
                    <span>Email : {commonData.email}</span>
                  </p>
                </div>
                <div className="col-4">
                  <h6>
                    <span>{shopDetails.shop_name}</span>
                  </h6>
                  <p>
                    <span>{shopDetails.address}</span>
                  </p>
                </div>
                <div className="col-4">
                  <b>
                    <span>Reference : {commonData.reference_no}</span>
                  </b>
                </div>
              </div>

              <table className="table table-bordered dataTable" id="productDetails">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Type</th>
                    <th>Unit Cost</th>
                    <th>Tax</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  	<tr>
	                    <td colSpan="5" className="text-end">
	                      <span className="text-end">Grand Total</span>
	                    </td>
	                    <td>
	                      <span>£ {totalVal.grandTotal}</span>
	                    </td>
                  	</tr>
                </tfoot>
              </table>
              <h3>
                <span>Created by : </span>
                <span>Date : </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PurchaseHistoryViewPrint;
