// FlashMessage.js
import React, { useEffect } from 'react';

const FlashMessage = ({ message, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 1000); // Message will be visible for 3 seconds

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [message, onClose]);

  return (
    message && (
      <div className={`flash-message ${type}`} style={{zIndex: '9999', right: '3%'}} >
        {message}
      </div>
    )
  );
};

export default FlashMessage;
