import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import config from "../common/Config";

const DeliveryConfirmed = () => {
  const userTableRef = useRef(null);
  const accessToken = localStorage.getItem("accessToken");

  let dateRange = '';
  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/login";
      return;
    }
    initializeDeliveryConfirmTable();
    // fetchPageData();
  }, []);

//   const fetchPageData = async () => {
//     const s = localStorage.getItem('startDate');
//     const e = localStorage.getItem('endDate');
//     const formData = new FormData();
//     formData.append("draw", draw);
//     formData.append("start", start);
//     formData.append("length", length);
//     formData.append("start_date", s ? s : "");
//     formData.append("end_date", e ? e : "");

//     const apiUrl = `${config.API_URL}/get_deliveredInvoice`;
//     try {
//       const response = await fetch(apiUrl, {
//         method: "POST",
//         headers: {
//           authorization: `Bearer ${accessToken}`,
//         },
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error("Failed to fetch Not Order Report");
//       }
//       const data = await response.json();
//       //	console.log(data.data);
//       setNewSales(data.data);
//     } catch (error) {
//       console.error("api fail Error fetching Not Order Report:", error);
//     }
//   };

//   const handleSubmit = () => {
//     fetchPageData();
//   };

  
//   useEffect(() => {
//     const table = $("#neworderTable").DataTable({
//       pageLength: 50,
//       paging: true,
//       lengthMenu: [10, 20, 50],
//       searching: true,
//       ordering: false,
//       info: true,
//       responsive: true,
//       autoWidth: false,
//       language: {
//         paginate: {
//           first: "First",
//           last: "Last",
//           next: "Next",
//           previous: "Previous",
//         },
//         search: "Search:",
//       },
//       data: newSales,
//       columns: [
//         { data: "date",
//           render: function(data, type, row){
//             const [year, month, day] = row.date.split("-");
//             const formattedDate = `${day}-${month}-${year}`;  
//             return formattedDate;
//           },
//           createdCell: function (td) {
//             $(td).css("text-align", "center");
//           }
//          },
//          {
//           data: "reference_no",
//           render: function (data, type, row) 
//           {
//             return (
//               `
//                 ${row.reference_no}
//               `
//             );
//           },
//           createdCell: function (td) 
//           {
//             $(td).css("text-align", "center");
//           }
//         },        
//         { data: "customer" },
//         { data: "grand_total" },
//         { data: "spc" },
//         { data: "prev_due" },
//         { data: "route_number",
//           createdCell: function (td) {
//             $(td).css("text-align", "center");
//           }
//          },
//         { data: "name" },
//       ],
//       columnDefs: [
//         {
//           targets: [0], // Make sure the checkbox column is non-orderable
//           orderable: false,
//         },
//         {
//           targets: [1, 2, 3, 4, 5, 6, 7], // Allow sorting on other columns
//           orderable: true,
//         },
//       ],
//     });
//     return () => {
//       table.destroy();
//     };
//   }, [newSales]);


    const s = localStorage.getItem('startDate');
    const e = localStorage.getItem('endDate');


  const initializeDeliveryConfirmTable = () => {
    const $table = $(userTableRef.current);
    const searchValue = $('#searchValue').val();
    if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
    }
  
    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
          url: `${config.API_URL}/get_deliveredInvoice`,
          type: 'POST',
          headers: {
             authorization : `Bearer ${accessToken}`,
          },
          data: function (d) {
              return $.extend({}, d, {
                  draw : d.draw,
                  start : d.start,
                  length : d.length,
                  start_date : s ? s : '',
                  end_date : e ? e : ''
              });
          },
          dataSrc: function (json) {
            return Array.isArray(json.data) ? json.data : [];
          }
      },
      language: {
          paginate: {
              first: 'First',
              last: 'Last',
              next: 'Next',
              previous: 'Previous',
          },
          search: 'Search:',
      },
      columns: [
        { data: "date",
          render: function(data, type, row){
            const [year, month, day] = row.date.split("-");
            const formattedDate = `${day}-${month}-${year}`;  
            return formattedDate;
          },
          createdCell: function (td) {
            $(td).css("text-align", "center");
          }
         },
         {
          data: "reference_no",
          render: function (data, type, row) 
          {
            return (
              `
                ${row.reference_no}
              `
            );
          },
          createdCell: function (td) 
          {
            $(td).css("text-align", "center");
          }
        },        
        { data: "customer",
          render:function(data , type, row)
          {
            return row.customer;
          }
         },
        { data: "grand_total",
            createdCell: function (td) {
              $(td).css("text-align", "right");
            }},
        { data: "spc",
            createdCell: function (td) {
              $(td).css("text-align", "right");
            } },
        { data: "prev_due",
            createdCell: function (td) {
              $(td).css("text-align", "right");
            } },
        { data: "route_number",
          createdCell: function (td) {
            $(td).css("text-align", "center");
          }
         },
        { data: "name" },
      ],
      columnDefs: [
        {
          orderable: false, 
          targets: '_all'
        },
        {
          targets: 2, 
          render: function(data) {
            return data === '1' ? 'Active' : 'Inactive'; 
          }
        }
      ],
    
      pageLength: 10,
    });
  };




  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="#">Sales</Link>
              <Link to="#">Delivered Invoice</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div  className="total-manifests-area dashboard"   style={{ position: "relative" }}    >
            <div  className="verical-scrl-wrapper common-table autoheight-table"   id="style-3"  >
              <table className="table table-bordered" id="neworderTable" ref={userTableRef} >
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>Order Date</th>
                    <th style={{textAlign:'center'}}>Order Id</th>
                    <th style={{textAlign:'center'}}>Customers (A/C No)</th>
                    <th style={{textAlign:'center'}}>Order Amt</th>
                    <th style={{textAlign:'center'}}>Rep Cash</th>
                    <th style={{textAlign:'center'}}>Previous Dues</th>
                    <th style={{textAlign:'center'}}>Route</th>
                    <th style={{textAlign:'center'}}>Order By</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DeliveryConfirmed;
