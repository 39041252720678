import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../../Config';
import config from "../../common/Config";
import Swal from 'sweetalert2';

const ContraPrice = () => {
  	const [productOptions, setProductOptions] = useState([]);
    const [productFilteredOptions, setProductFilteredOptions] = useState([]);
    const [productValue, setProductValue] = useState(null);  
    const [productInputValue, setProductInputValue] = useState('');
  	const accessToken = localStorage.getItem('accessToken'); 
  	const [newProductList, setNewProductList] = useState([]);
    const [tableRows, setTableRows] = useState([]);
 /////////////////////////////////////////////////////////////////////
    const [data, setData] = useState([]);
    const [productData, setProductData] = useState([]);
    const id = useParams();
    const navigateToCustomerList = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
 /////////////////////////////////////////////////////////////////////
const fetchPageData = async () => {
    // alert(id.customerId);
    const formData = new FormData();
    formData.set('id', id.customerId);
    const apiUrl = `${config.API_URL}/ws_get_customer_details_id`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Not Order Report");
      }
      const data = await response.json();
      //	console.log(data.data);
      setData(data.data);
    } catch (error) {
      console.error("api fail Error fetching Not Order Report:", error);
    }
  };


  const fetchProductData = async () => {
    const formData = new FormData();
    formData.append('customer_id', id.customerId);
    formData.append('platform', 'web');
    formData.append('authorization', accessToken);
    const apiUrl = `${config.API_URL}/ws-get-products`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          
        },
        body : formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Not Order Report");
      }

      const data = await response.json();
    //   console.log(data);
      const formattedOptions = data.product_details.map(item => ({
        label: item.product_name,
        value: item.id,
        
    }));
        setProductOptions(formattedOptions);
        setProductFilteredOptions(formattedOptions);
        setProductData(data.product_details);
    } catch (error) {
      console.error("api fail Error fetching Not Order Report:", error);
    }
  };


   useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/login";
      return;
    }
        fetchProductData();
        fetchPageData();
    }, []);




  //////////////////////////////////////////////////////////


















    const productHandleChange = (selectedOption) => {
        
        if (selectedOption ) {
            setProductValue(selectedOption);
            fetchAddProductInfo(selectedOption.value);
        } else {
            setProductValue(null); 
        }
    };

    const productHandleInputChange = (inputValue) => {
        setProductInputValue(inputValue);
        const newFilteredOptions = productOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setProductFilteredOptions(newFilteredOptions);
    };
  	
  	const formatNumber = (num) => {
	    return parseFloat(num).toFixed(2);
	};
  
    const fetchAddProductInfo = async (selectedOption) => {
        const formData = new FormData();

        if(selectedOption){
            formData.append('product_id', selectedOption);
            const existingProduct = tableRows.find(item => item.id === selectedOption);
            if (existingProduct) {
               
               Swal.fire({
                  title: 'Error',
                  text: 'Product already exists in the list.',
                  icon: 'error',
                  showConfirmButton: false, 
                  timer: 2000,               
                  timerProgressBar: true,     
                });
                return;
            }
        }
            
            const productData1 = productData.find(item => item.id === selectedOption);
            console.log(productData1);
            const newRow = {
                code: productData1.product_code,
                id: productData1.id,
                name: productData1.product_name
            };
            setTableRows((prevRows) => [...prevRows, newRow]);
    };

    const submitFormData = async () => {
        if (tableRows.length < 1) {
            Swal.fire({
               title: 'Error',
               text: 'Please add a product.',
               icon: 'error',
               showConfirmButton: false, 
               timer: 1000,               
               timerProgressBar: true,     
             });
             return;
         }

        setIsSubmitting(true);
        const form = document.getElementById('addcontraform');
        const formData = new FormData(form);
        let a = formData.getAll('product_price[]'); 
        let nonEmptyPrices = a.filter(price => price.trim() !== ''); 

        if(nonEmptyPrices.length !== tableRows.length )
        {
            Swal.fire({
                title: 'Error',
                text: 'Please enter price for each product.',
                icon: 'error',
                showConfirmButton: false, 
                timer: 1500,               
                timerProgressBar: true,     
              });
              setIsSubmitting(false);
              return;
        }
        formData.append('customer_id', id.customerId);
        const apiSalesRouteUrl = `${config.API_URL}/add_customer_contraPrice`;
        try {
            const response = await fetch(apiSalesRouteUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            const data = await response.json();
            if(data.success)
            {
                Swal.fire({
                    title: "Success!",
                    text: "Contra price added successfully.",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                  })
                navigateToCustomerList(`/customers`);
            }
            else
            {
                setIsSubmitting(false);
            }

        } catch (error) {
            console.error('api fail error fetching products');
        }
    };

  const handleRemoveRow = (rowId) => {
    const updatedRows = tableRows.filter((row) => row.id !== rowId);
    setTableRows(updatedRows); 
  };

	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/reports/mis_report">Customer</Link> 
		                  <Link to="/reports/mis_report">Contra Price</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">
			    	<div className="row">
			    		<div className="col-md-9 col-lg-9 col-sm-12">
			    			<div className="col-md-4 cp-lg-4 col-sm-4" style={{float: 'left',  padding: '17px 0px 0px 0px'}}>
                    <input type='text' name="supplier" value={data.company} />
							</div>
							<div className="col-md-4 cp-lg-4 col-sm-4" style={{float: 'left',  padding: '17px 0px 0px 15px'}}>
				                <Select className='abovedd'
						            isClearable
						            onInputChange={productHandleInputChange}
						            onChange={productHandleChange}
						            options={productFilteredOptions} 
						            placeholder="Select Product"
						            value={productValue}
						        />
							</div>
			    		</div>
			    	</div>
			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>Order Items </b></p>
	                    <form id='addcontraform'>
    	                    <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
    	                        <table className="table table-bordered dataTable" id="newProductTable">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Product Name</th>
                                            <th>Price</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableRows.length === 0 ? (
                                            <tr style={{ textAlign: "center" }}>
                                                <td colSpan={9}>No product added</td>
                                            </tr>
                                        ) : (
                                            tableRows.map((row,i)=> (
                                                <tr key={row.id}>
                                                    <td>{i+1}<input type='hidden' name='product_id[]' value={row.id} /></td>
                                                    <td>{row.name}({row.code})</td>
                                                    <td><input type='text' name='product_price[]' className='form-control' /></td>
                                                    <td>
                                                        <input
                                                        type="button"
                                                        className="btn btn-danger btn1"
                                                        value="X"
                                                        onClick={() => handleRemoveRow(row.id)} 
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
    	                    </div>
                        </form>
                        <div className="row">
                            <div className="col-12" style={{paddingTop: '20px'}}>
                            <button
                                    onClick={submitFormData}
                                    type="button"
                                    className="acceptbtn"
                                    disabled={isSubmitting} 
                                >
                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                        </div>
	                </div>
			    </div>
			</div>

	      	<Footer />
		</>
	);
}
export default ContraPrice;