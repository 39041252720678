import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL } from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import Swal from 'sweetalert2';
import config from "../common/Config";
import { Link } from 'react-router-dom';



const Catalogue = () => {

return (
<div>
<div class="modal fade" id="create-catalogue" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create Catalogue</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="catalogue-popup">
          <form>
            <h4>Shift</h4>
            <div class="row mb-3 padd-l-13px">
              <label for="desc" class="col-sm-3 col-form-label">Product Type *:</label>
              <div class="col-sm-9">
                <ul>
                  <li>
                    <input class="" type="radio" name="gridRadios" value="option1" checked="" />
                    <label class="form-check-label" for="gridRadios1"> Products Wise </label>
                  </li>
                  <li>
                    <input class="" type="radio" name="gridRadios" value="option1" />
                    <label class="form-check-label" for="gridRadios1"> Categories Wise </label>
                  </li>
                  <li>
                    <input class="" type="radio" name="gridRadios" value="option1" />
                    <label class="form-check-label" for="gridRadios1"> Brands Wise </label>
                  </li>
                  <li>
                    <input class="" type="radio" name="gridRadios" value="option1" />
                    <label class="form-check-label" for="gridRadios1"> Promoted Wise </label>
                  </li>
                  <li>
                    <input class="" type="radio" name="gridRadios" value="option1" />
                    <label class="form-check-label" for="gridRadios1"> New Arrivals </label>
                  </li>
                </ul>
              </div>
            </div>
            <hr/>
            <div class="row mb-3 padd-l-13px">
              <label for="desc" class="col-sm-3">&nbsp;</label>
              <div class="col-sm-9">
                <div>
                  <label class="checkbox">
                  <input type="checkbox" />
                  <span class="default"></span></label>
                  <label class="form-check-label" for="gridRadios1"> Pack size </label>
                </div>

                <div>
                  <label class="checkbox">
                  <input type="checkbox" />
                  <span class="default"></span></label>
                  <label class="form-check-label" for="gridRadios1"> Barcode</label>
                </div>


              </div>
            </div>
            <hr/>
			<div class="row mb-3 padd-l-13px">
              <label for="desc" class="col-sm-3 col-form-label">Size :</label>
              <div class="col-sm-9">
                	<ul>
                  <li>
                    <input class="" type="radio" name="gridRadios" value="option1" checked="" />
                    <label class="form-check-label" for="gridRadios1"> 2 X 3 </label>
                  </li>
                  <li>
                    <input class="" type="radio" name="gridRadios" value="option1" />
                    <label class="form-check-label" for="gridRadios1"> 3 X 3 </label>
                  </li>
                  <li>
                    <input class="" type="radio" name="gridRadios" value="option1" />
                    <label class="form-check-label" for="gridRadios1"> 3 X 4 </label>
                  </li>
                  
                </ul>
              </div>
            </div>  
			  
			   
			 <div class="row mb-3 padd-l-13px">
              <label for="desc" class="col-sm-3 col-form-label">Front Page (A4):</label>
              <div class="col-sm-9">
                <input class="form-control" type="file" id="formFile" />
              </div>
				
            </div> 
			  
			  <div class="row mb-3 padd-l-13px">
              
				 <label for="desc" class="col-sm-3 col-form-label">Catalogue Date:</label>
              <div class="col-sm-9">
               <input type="date" name="name" value="" class="form-control" />
              </div>
            </div> 
			  
			  
			  
			   <div class="row mb-3 padd-l-13px">
              
				 <label for="desc" class="col-sm-3 col-form-label">Select Product *</label>
              <div class="col-sm-9">
               		<div id="productlist" class="checklistContainer" showselecteditems="false">
						<div class="findInList" id="productlist_findInListDiv">
							<input type="text" value="Type here to search list..." id="productlist_findInList" class="blurred" /></div>
						<div id="productlist_checklist" class="checklist" style={{ position: 'relative', height: '80px' }} >
							<ul class="checklist">
								<li tabindex="0"><input type="checkbox" value="1656" name="productlist[]" id="productlist_1656" /><label for="productlist_1656" class="leaveRoomForCheckbox">KOMBUCHA TROPICAL TWIST -10x20mg(1915)</label></li><li tabindex="0"><input type="checkbox" value="1659" name="productlist[]" id="productlist_1659" /><label for="productlist_1659" class="leaveRoomForCheckbox">warrior shots -12 (1918)</label></li>
								<li tabindex="0"><input type="checkbox" value="2009" name="productlist[]" id="productlist_2009" /><label for="productlist_2009" class="leaveRoomForCheckbox">WOW HYDRATE SUMMER FRUITS -12x500ml(0720)</label></li>
								<li tabindex="0"><input type="checkbox" value="2008" name="productlist[]" id="productlist_2008" /><label for="productlist_2008" class="leaveRoomForCheckbox">FRAPPUCCINO VANILLA -8x250ml(0708)</label></li>
								<li tabindex="0"><input type="checkbox" value="1820" name="productlist[]" id="productlist_1820" /><label for="productlist_1820" class="leaveRoomForCheckbox">HUEL CHOCOLATE 8x500ml -8x500ml(2210)</label></li>
								<li tabindex="0"><input type="checkbox" value="1821" name="productlist[]" id="productlist_1821" /><label for="productlist_1821" class="leaveRoomForCheckbox">HUEL VANILLA 8x500ml -8x500ml(2211)</label></li>
								<li tabindex="0"><input type="checkbox" value="1822" name="productlist[]" id="productlist_1822" /><label for="productlist_1822" class="leaveRoomForCheckbox">HUEL BERRY 8x500ml -8x500ml(2212)</label></li>
								<li tabindex="0"><input type="checkbox" value="1823" name="productlist[]" id="productlist_1823" /><label for="productlist_1823" class="leaveRoomForCheckbox">HUEL BANANA 8x500ml -8x500ml(2213)</label></li>
								
								</ul>
						</div>
						<div class="actionButtons" id="productlist_actionButtons"><span data-action="checkAll">Check all</span> | <span data-action="clearAll">Uncheck all</span> | <span data-action="invert">Invert select</span></div></div>
              </div>
            </div> 
			   <hr />
			  <div class="form-group">
        <button type="button" class="float-right-btn common-popup-btn">Create Catalogue</button>
							</div>
			  
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


            <Header />
            <nav className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <a href="#"><i className="fa fa-home"></i> </a>
                            <span className="last-crumb">Slider Settings</span>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="main-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                           
                            <div style={{ float: 'right' }} >
              <button type="submit" id="submit" class="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#create-catalogue">Create Catalogue</button>
            </div>


            <div className="show-order-area dashboard" style={{ position: 'relative' }}>
            <div class="verical-scrl-wrapper common-table catalogue-tbl" id="style-3">
              <table class="table table-bordered dataTable" id="neworderTable">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Date</th>
                    <th>Reference No</th>
                    <th>Catalogue Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>28-06-2024</td>
                    <td>15011199</td>
                    <td>Categories Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>27-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>27-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>04</td>
                    <td>27-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>05</td>
                    <td>27-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>06</td>
                    <td>25-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>07</td>
                    <td>25-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>08</td>
                    <td>19-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>09</td>
                    <td>19-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>28-06-2024</td>
                    <td>15011199</td>
                    <td>New Arrivals</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>27-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>25-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>25-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>19-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>19-06-2024</td>
                    <td>15011199</td>
                    <td>Products Wise Wise</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                  <tr>
                    <td>16</td>
                    <td>28-06-2024</td>
                    <td>15011199</td>
                    <td>New Arrivals</td>
                    <td><a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a></td>
                    <td><a href="#" class="pdfdwnld"><i class="fa fa-download"></i>Download PDF</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Catalogue;
