import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';


const Products_Price_Variation = () => {
  
  const priceVariationRef = useRef(null);
  const [searchValue, setSearchValue] = useState([]);
  const accessToken = localStorage.getItem('accessToken');
  const userId = localStorage.getItem('userId');
  
  const initializeDataTable = () => {
      const $table = $(priceVariationRef.current);
      const searchValue = $('#searchValue').val();
      // Destroy existing DataTable instance if it exists
      if ($.fn.DataTable.isDataTable($table)) {
          $table.DataTable().destroy();
      }
  
      let i = 0;
      $table.DataTable({
          processing: true,
          serverSide: true,
          ajax: {
              url: `${config.API_URL}/get_price_variation`,
              type: 'POST',
              headers: {
                 authorization : `Bearer ${accessToken}`,
              },
              data: function (d) {
                  return $.extend({}, d, {
                      draw: d.draw,
                      start: d.start,
                      length: d.length,
                      searchValue : searchValue
                  });
              },
              dataSrc: function (json) {
                  return json.data;
                  console.log(json.data);
              }
          },
          language: {
              paginate: {
                  first: 'First',
                  last: 'Last',
                  next: 'Next',
                  previous: 'Previous',
              },
              search: 'Search:',
          },
          columns : [
              { data: 'date',  },
              { 
                data: null, 
                render : function(data, type, row){
                  return `<span>${row.percentage2}</span>`;
                } 
              },
              { 
                data: null, 
                render : function(data, type, row){
                  return `<span>${row.percentage3}</span>`;
                } 
              },
              { 
                data: null, 
                render : function(data, type, row){
                  return `<span>${row.percentage4}</span>`;
                } 
              },
              { 
                data: null, 
                render : function(data, type, row){
                  return `<span>${row.percentage5}</span>`;
                } 
              },
              { 
                data: null, 
                render : function(data, type, row){
                  return `<span>${row.percentage6}</span>`;
                } 
              },
              { 
                data: null, 
                render : function(data, type, row){
                  return `<span>${row.username}</span>`;
                } 
              },
          ],
          columnDefs: [
            {
                orderable: false, 
                targets: '_all'
            },
            {
                targets: 2, 
                render: function(data) {
                    return data === '1' ? 'Active' : 'Inactive'; 
                }
            }
        ],
        
          pageLength: 10,
      });

      
  

      // Event delegation for edit and view actions
      /*$table.on('click', '.editbtn', function() {
        const rowId = $(this).data('id'); 
        const rowData = $table.DataTable().row($(this).closest('tr')).data(); // Access DataTable instance
        
        if (rowData && Object.keys(rowData).length > 0) {
            //console.log(rowData);
            handleEditClick(rowData); 
        } 
      });*/
  };

  useEffect(() => {

      if (accessToken === null) {
          window.location.href = 'react/login';
          return;
      }

      initializeDataTable();  
      return () => {
          if (priceVariationRef.current && $.fn.DataTable.isDataTable(priceVariationRef.current)) {
              $(priceVariationRef.current).DataTable().destroy();
          }
      };
  }, [searchValue]);

const [isSubmitting, setIsSubmitting] = useState(false);
  const [percentage1, setPercentage1] = useState('');
  const [percentage2, setPercentage2] = useState('');
  const [percentage3, setPercentage3] = useState('');
  const [percentage4, setPercentage4] = useState('');
  const [percentage5, setPercentage5] = useState('');

  const [calculatedPrice1, setCalculatedPrice1] = useState(0);
  const [calculatedPrice2, setCalculatedPrice2] = useState(0);
  const [calculatedPrice3, setCalculatedPrice3] = useState(0);
  const [calculatedPrice4, setCalculatedPrice4] = useState(0);
  const [calculatedPrice5, setCalculatedPrice5] = useState(0);

  const samplePrice = 18.99;

  const calculatePrice = (percentage) => {
    const percentValue = parseFloat(percentage);
    if (isNaN(percentValue)) return 0;
    return ((percentValue / 100) * samplePrice).toFixed(2);
  };

  const handlePercentageChange = (index, value) => {
    const newValue = value;
    const calculatedPrice = calculatePrice(newValue);
    
    switch (index) {
      case 1:
        setPercentage1(newValue);
        setCalculatedPrice1(calculatedPrice);
        break;
      case 2:
        setPercentage2(newValue);
        setCalculatedPrice2(calculatedPrice);
        break;
      case 3:
        setPercentage3(newValue);
        setCalculatedPrice3(calculatedPrice);
        break;
      case 4:
        setPercentage4(newValue);
        setCalculatedPrice4(calculatedPrice);
        break;
      case 5:
        setPercentage5(newValue);
        setCalculatedPrice5(calculatedPrice);
        break;
      default:
        break;
    }
  };

  const submitPriceVariationForm = async (event) => {
      event.preventDefault();
      const form = document.getElementById('addPriceVariation');
      const formData = new FormData(form);
      formData.append('sample_price', samplePrice);
      formData.append("user_id", userId);
      /*for (let i = 1; i <= 5; i++) {
        formData.append(`percentage${i}`, eval(`percentage${i}`));
        formData.append(`calculated_price${i}`, eval(`calculatedPrice${i}`));
      }*/
      setIsSubmitting(true);
      try {
          const response = await axios.post(`${config.API_URL}/add_price_variation`, formData, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            }
          });
          const result = response.data;
          //console.log(result);
          
          if (result.status == "success"){
              Swal.fire({
                title: "Success!",
                text: "Price Variation added successfully.",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              }).then(() => 
              {
                
                
                window.location.href = config.BASE_URL + '/products_price_variation/';
            
              });
          }else{
            Swal.fire({
                title: "Failed!",
                text: "Price Variation Failed. Try Again",
                icon: "error",
                timer: 1500,
                showConfirmButton: false,
            });
          }
        } catch (error) {
          console.error("Error Price Variation:", error);
        } finally {
        setIsSubmitting(false); // Re-enable the button
      }
    };

  return (
    <div>

    <div class="modal fade" id="add-price" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Price Variation</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="popup-area price-variatn-popup">
                
              <form id="addPriceVariation">


              <div class="row mb-3 padd-l-13px">
                <div class="col-sm-6"><label for="inputEmail5" class="col-form-label">Sample Price</label><input type="text" class="form-control" name="pack" id="pack" value={samplePrice} disabled /></div>
                
                
              </div>

               {[...Array(5)].map((_, index) => {
                const idx = index + 1;
                return (
                  <div className="row mb-3 padd-l-13px" key={idx}>
                    <div className="col-sm-6">
                      <label className="col-form-label">Enter Percentage for price {idx + 1} *</label>
                      <input
                        type="text"
                        className="form-control"
                        name={`percentage${idx}`}
                        value={eval(`percentage${idx}`)}
                        onChange={(e) => handlePercentageChange(idx, e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="col-form-label">Calculated Price {idx + 1}</label>
                      <input
                        type="text"
                        className="form-control"
                        name={`calculated_price${idx}`}
                        value={eval(`calculatedPrice${idx}`)}
                        disabled
                      />
                    </div>
                  </div>
                );
              })}

              
               

               
                
                <hr />
                <div class="row padd-l-13px">
                  <div class="col-sm-12">
                    <button type="submit" disabled={isSubmitting} onClick={submitPriceVariationForm} id="submit" class="float-right-btn common-popup-btn">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>




      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="Dashboard">Master</a> &gt; <span class="last-crumb">Products Price Variation</span> </div>
          </div>
        </div>
      </div>


      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div style={{ position: 'relative' }}>
                <div class="row">
                  <div class="col-12 col-lg-4 col-sm-4">
                    
                  </div>
                  <div class="col-12 col-lg-8 col-sm-8">
                    <div style={{ float: "right" }}>
                      

                      <a href="Add_Products" class="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add-price">Add Price</a>
                    </div>
                  </div>
                </div>
                <div className="show-order-area dashboard">
                  <div className="verical-scrl-wrapper common-table price-variatn-table" id="style-3">

                    <table className="table table-bordered dataTable" id="ProductPriceTable" ref={priceVariationRef}>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Percentage 2	</th>
                          <th>Percentage 3	</th>
                          <th>Percentage 4	</th>
                          <th>Percentage 5	</th>
                          <th>Percentage 6	</th>
                          <th>AddBy </th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>2024-03-08 08:41:24	</td>

                          <td>95</td>
                          <td>90</td>
                          <td>85</td>
                          <td>80</td>

                          <td>75</td>
                          <td>jignesh</td>
                          
                        </tr>


                        

                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <Footer />
    </div>
  );
};

export default Products_Price_Variation; 