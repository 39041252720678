import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import config from "../../common/Config";

const ExportProductList = ({ totalActiveProduct, urlLink, pdtStatus }) => {
  
  const accessToken = localStorage.getItem('accessToken');

  const exportActiveProduct = async () => {
    const formData = new FormData();
    formData.append('draw', '1');
    formData.append('start', '0');
    formData.append("length", totalActiveProduct);

    const apiActivePdtUrl = `${config.API_URL}/${urlLink}`; 
    
    try {
      const response = await fetch(apiActivePdtUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch Products');
      }

      const data = await response.json();

      const exportData = data.data.map(product => ({
        Name: product.name || '',
        Code: product.code || '',
        Brand: product.brand || '', 
        Category: product.categories || '',
        Size: product.size || '',
        "Unit" : product.unit || '',
        "Bx Cost": product.box_cost || '',
        "Bx Price": product.price || '',
        "Pc Cost": product.piece_cost || '',
        "Pc Price": product.piece_price || '',
        "Box Quantity": product.quantity || '',
        "PC Quantity": product.split_quantity || '',
        "Tax Rate": product.rate || '',
        Status: product.status === '1' ? 'Active' : 'Inactive' 
      }));

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Active Products');

      // Get current date and time for the filename (e.g., '2024-11-07-14-30-45')
      const now = new Date();
      const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
      const formattedTime = `${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
      const filename = `${pdtStatus}-product-list-${formattedDate}-${formattedTime}.xlsx`;

      
      XLSX.writeFile(wb, filename);
    } catch (error) {
      console.error('Error exporting Active Product:', error);
    }
  };

  return (
    <div>
      <button className="create-catalogue-btn" onClick={exportActiveProduct} style={{textTransform : 'capitalize'}}>
        Export {pdtStatus} Products
      </button>
    </div>
  );
};

export default ExportProductList;
