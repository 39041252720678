import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import CustomDateRangePicker from '../common/CustomDateRangePicker';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import AccountService from '../../services/AccountServices';


const SupplierLedger = () =>{

	const accessToken = localStorage.getItem('accessToken');
	const [dates, setDates] = useState([null, null]);
	const [reportrange, setReportrange] = useState('');

	const [supplierOptions, setSupplierOptions] = useState([]);
    const [supplierFilteredOptions, setSupplierFilteredOptions] = useState([]);
    const [supplierValue, setSupplierValue] = useState('');
    const [supplierInputValue, setSupplierInputValue] = useState('');
    const [flashMessage, setFlashMessage] = useState(null);

    const [supplierLedger, setSupplierLedger] = useState([]);

    const [supplier, setSupplier] = useState({
    	company : '',
    	accoundNo : '',
    	phone : '',
    	startDate : '',
    	endDate : ''
    });
    const [nodataRow, setNodataRow] = useState('');

	const handleCloseMessage = () => {
		setFlashMessage(null);
	};

	const searchFormData = () => {
	    let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
	    let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
	    setReportrange(startDate ? startDate + ' - ' + endDate : '');
	    
	    let supplierId = supplierValue ? supplierValue.value : '';

	    //console.log(supplierId);
	   

	   	if (!supplierId) {
	        setFlashMessage({ message: 'Please select customer', type: 'error' });
	        return;
	    }
	    loadSupplierLadger(supplierId, startDate, endDate);

	   
	    
	};

	const loadSupplierLadger = async (supplierId, startDate, endDate) => {
		try {
			const data = await AccountService.fetchSupplierLadger(supplierId, startDate, endDate, accessToken);
			console.log(data);

			if (Array.isArray(data) && data.length === 0) {
	            console.log('No data available.');
	            setSupplier({ company: '', accoundNo: '', phone: '', startDate : '', endDate : '' });
	            setSupplierLedger([]); 
	            setNodataRow('showinfo');
	            return; 
	        }
	        if (data.supplier.company) {
				setSupplier({
			        company : data.supplier.company,
			        accoundNo : data.supplier.accound_no,
			        phone: data.supplier.phone,   
			        startDate : data.start_date,   
			        endDate : data.end_date
			    });
	      		setSupplierLedger(data.supplier_ledger); 
	      		setNodataRow('');
      		}
		} catch (error) {
			console.log('Failed to load customer ledger', error);
		}
	};

	const supplierHandleInputChange = (inputValue) => {
        setSupplierInputValue(inputValue);
        const newFilteredOptions = supplierOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setSupplierFilteredOptions(newFilteredOptions);
    };

    const supplierHandleChange = (selectedOption) => {
        setSupplierValue(selectedOption);
    };

    const fetchSupplierList = async () => {
  		
  		const apiSalesRouteUrl = `${API_URL}/purchases/getSupplier`;
  		try {
  			const response = await fetch(apiSalesRouteUrl, {
  				method : 'POST',
  				headers : {
  					authorization : `Bearer ${accessToken}`,
  				}
  			});
  			const data = await response.json();
  			//console.log(data);
  			const formattedOptions = data.supplier.map(item => ({
                label: item.company,  
          		value: item.id,    
            }));
            setSupplierOptions(formattedOptions);
            setSupplierFilteredOptions(formattedOptions);

  		}catch(error){
  			console.error('api fail error fetching sales routes');
  		}
  	};
	

	useEffect(() => {

		if (accessToken === null) {
	    	window.location.href = BASE_URL;
	    	return;
	  	}
	  	 fetchSupplierList();

	    document.title = "Supplier Ledger";
	    return () => {
	      document.title = "Ebusso";
	    };

	   
	}, []);

	const renderRow = (item) => {
	    const {
	      id,
	      invoice_date,
	      payment_type,
	      amount,
	      balance,
	      payment_status,
	      reference_no,
	      grn_id,
	      particulars,
	      paid_by,
	    } = item;

	    const formattedAmount = parseFloat(amount).toFixed(2);
	    const formattedBalance = parseFloat(balance).toFixed(2);
	    const name1 = payment_type === 'c' ? '' : (
	      <a href={`./purchases/view_grn/${grn_id}`}>{reference_no}</a>
	    );

	    let particular1 = payment_type === 'c' ? 'Pay' : 'Inv';
	    let status = '';

	    if (payment_type !== 'c') {
	      status = payment_status === 'Paid' ? 'Paid' : payment_status === 'Due' || payment_status === 'pending' ? 'UnPaid' : 'Partial';
	    }

	    return (
	      <tr style={particular1 === 'Pay' ? { backgroundColor: '#b1edb1' } : {}}>
	        <td>{invoice_date}</td>
	        <td>{name1}</td>
	        <td>{payment_type !== 'c' ? status : ''}</td>
	        <td>{particular1}</td>
	        <td style={{ textAlign: 'right' }}>{formattedAmount}</td>
	        <td style={{ textAlign: 'right' }}>{formattedBalance}</td>
	      </tr>
	    );
	};

	const [showModal, setShowModal] = useState(false);
	const modalRef = useRef(null);

	const handleShow = () => {
     	if (modalRef.current) {
      		const modal = new window.bootstrap.Modal(modalRef.current);
      		modal.show();
    	}
  	};

	const [payDate, setPayDate] = useState(new Date().toISOString().split('T')[0]);
  	const [paymentType, setPaymentType] = useState('');
  	const [chequeNo, setChequeNo] = useState('');
  	const [payAmount, setPayAmount] = useState('');
  	const [reference, setReference] = useState('');
  	const [isChequeVisible, setChequeVisible] = useState(false);

	const handlePaymentTypeChange = (e) => {
	    const selectedType = e.target.value;
	    setPaymentType(selectedType);
	    setChequeVisible(selectedType === 'Cheque');
	};

	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/account/supplier_ledger">Account</Link> 
		                  <Link to="/account/supplier_ledger">Supplier Ledger</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">

			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>Supplier Ledger </b></p>

	                    {supplier.company && (
	                   	<div>
		                    <button  onClick={handleShow} type="button" class="acceptbtn" style={{position: 'absolute', right: '88px', top: '6px'}}>Add Payment</button>
		                    <button type="button" class="acceptbtn" style={{position: 'absolute', right: '18px', top: '6px'}}>Print</button>
	                    </div>
	                    )}

	                    <div className="row" >
	                    	<div className="col-md-3 cp-lg-3 col-sm-3" style={{float: 'left', paddingLeft: '20px'}}>    		
		            			<Select
						            isClearable
						            onInputChange={supplierHandleInputChange}
						            onChange={supplierHandleChange}
						            options={supplierFilteredOptions}
						            placeholder="Select Supplier"
						            value={supplierValue}
						        />
							    
							</div>

	              			<div className="col-md-9 col-lg-9 col-sm-12">
	                			<CustomDateRangePicker dates={dates} onChange={setDates} onSubmit={searchFormData} />
	              			</div>
		            	</div>


		            	{supplier.company && (
		            	<div className="row customerInfo" >
		            		<div className="col-md-8 col-lg-8 col-sm-12">
				            	<div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
				            		<span><b>Period From : </b> 01-05-2024 To : 21-09-2024{supplier.company}</span>
			                        <table className="table table-bordered dataTable">
			                           
			                            <tbody>
			                             	<tr>
			                                	<td style={{width: '35%'}}><b style={{fontWeight : '800'}}>{supplier.company}</b></td>
			                                </tr>
			                                
			                            </tbody>
			                          
			                        </table>
			                    </div>
		                    </div>
	                    </div>
	                    )}

	                    {supplierLedger.length > 0 && (
	                    <div className="row customerInfo">
	                    	<div className="col-md-9 col-lg-9 col-sm-12">
	                    		<div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
			                        <table className="table table-bordered dataTable" >
			                            <thead>
			                                <tr>
			                                    <th>Date</th>
			                                    <th>Inv No</th>
			                                    <th>Status</th>
			                                    <th>Type</th>
			                                    <th>Amount</th>
			                                    <th>Balance</th>

			                                </tr>
			                            </thead>
			                            <tbody>
									    	{supplierLedger.map((item) => renderRow(item))}
										</tbody>

			                        </table>
			                    </div>
	                    	</div>
	                    </div>
	                    )}

	                    {nodataRow && (
						<div className="row text-center">
							<div className="col-md-8 col-lg-8 col-sm-12" style={{paddingTop: '15px'}}>
								<p className="text-danger">NO Transaction Available.</p>
							</div>
						</div>
						)}

	                </div>
	            </div>
	        </div>

	        <div ref={modalRef} className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	        	<div className="modal-dialog" role="document">
	          		<div className="modal-content">
	            		<div className="modal-header">
	              			<h5 className="modal-title">Add Payment</h5>
	              			<button type="button" className="btn-close" aria-label="Close"></button>
	            		</div>
	            		<div className="modal-body">
	            			<div className="row">
						      <div className="col-md-4">
						        <div className="form-group">
						          <label htmlFor="date" className="col-form-label">Date:</label>
						          <input 
						            type="date" 
						            className="form-control" 
						            id="date" 
						            value={payDate} 
						            onChange={(e) => setPayDate(e.target.value)} 
						          />
						        </div>
						      </div>
						      <div className="col-md-4">
						        <div className="form-group">
						          <label className="col-form-label" htmlFor="paid_by_1">Paying by *</label>
						          <select 
						            name="paid_by" 
						            id="paid_by_1" 
						            className="form-control paid_by" 
						            required 
						            value={paymentType} 
						            onChange={handlePaymentTypeChange}

						            style={{height: '35px'}}
						          >
						            <option value="">Select Type</option>
						            <option value="cash">Cash</option>
						            <option value="CC">Credit Card</option>
						            <option value="Cheque">Cheque</option>
						          </select>
						        </div>
						      </div>
						      {isChequeVisible && (
						        <div className="col-md-4">
						          <div className="form-group">
						            <label htmlFor="cheque_1" className="col-form-label">Cheque No:</label>
						            <input 
						              type="text" 
						              name="cheque" 
						              className="form-control" 
						              id="cheque_1" 
						              value={chequeNo} 
						              onChange={(e) => setChequeNo(e.target.value)} 
						            />
						          </div>
						        </div>
						      )}
						      <div className="col-md-4">
						        <div className="form-group">
						          <label htmlFor="amount" className="col-form-label">Amount:</label>
						          <input 
						            type="number" 
						            className="form-control" 
						            id="amount" 
						            value={payAmount} 
						            onChange={(e) => setPayAmount(e.target.value)} 
						          />
						        </div>
						      </div>
						      <div className="col-md-4">
						        <div className="form-group">
						          <label htmlFor="reference" className="col-form-label">Reference:</label>
						          <input 
						            type="text" 
						            className="form-control" 
						            id="reference" 
						            name="reference" 
						            value={reference} 
						            onChange={(e) => setReference(e.target.value)} 
						          />
						        </div>
						      </div>
						    </div>

		            	</div>
	            		<div className="modal-footer">
	              			<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
	              			<button type="button" className="btn btn-primary" data-bs-dismiss="modal" >Submit</button>
	            		</div>
	          		</div>
	        	</div>
	      	</div>

	        <FlashMessage
				message={flashMessage?.message}
				type={flashMessage?.type}
				onClose={handleCloseMessage}
			/>
	      	<Footer />

		</>
	);

}

export default SupplierLedger;