import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL } from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';

const SliderSettings = () => {
    const [data, setData] = useState([]);
    const accessToken = localStorage.getItem("accessToken");

    const handleUpdateSetting = async () => {
        const form = document.getElementById("add_slider_setting_form");
        const formData = new FormData(form);

        try {
            const response = await axios.post(`${API_URL}/ws_demo_api`, formData, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.data.success) {
                Swal.fire({
                    title: "Success!",
                    text: "Promotion updated successfully.",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                });
            } else {
                throw new Error("Update failed");
            }
        } catch (error) {
            console.error("Error updating slider setting:", error);
            Swal.fire({
                title: "Failed!",
                text: "Promotion update failed. Try Again",
                icon: "error",
                timer: 1500,
                showConfirmButton: false,
            });
        }
    };

    const getSliderSettings = async () => {
        try {
            const response = await axios.get(`${API_URL}/ws-get-slider`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            });
            setData(response.data.data[0]?.slider || []);
        } catch (error) {
            console.error("Error fetching slider settings:", error);
            Swal.fire({
                title: "Error!",
                text: "Failed to fetch slider settings.",
                icon: "error",
            });
        }
    };

    useEffect(() => {
        getSliderSettings();
    }, []);

    return (
        <div>
            <Header />
            <nav className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <a href="#"><i className="fa fa-home"></i></a>
                            <span className="last-crumb">Slider Settings</span>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="main-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="show-order-area dashboard">
                                <div className='slider-setting-page'>
                                    <p>&nbsp;</p>
                                    <form id='add_slider_setting_form'>
                                        {data.map((item, index) => (
                                            <div className="row mb-3" key={index}>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor={`img${index + 1}`} className='col-form-label'>Image {index + 1}</label>
                                                        <input type="file" className="form-control" name={`img${index + 1}`} />
                                                    </div>
                                                    <a href={`/assets/uploads/${item?.image || ''}`} target="_blank" rel="noopener noreferrer">{item?.image || ''}</a>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor={`link${index + 1}`} className='col-form-label'>Link {index + 1}</label>
                                                        <input type="text" className="form-control" name={`link${index + 1}`} defaultValue={item?.link || ''} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor={`caption${index + 1}`} className='col-form-label'>Caption {index + 1}</label>
                                                        <input type="text" className="form-control" name={`caption${index + 1}`} defaultValue={item?.caption || ''} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className='row mb-3'>
                                            <div className='col-12'>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col-12'>
                                                <button type="button" onClick={handleUpdateSetting} className="btn btn-primary float-right">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SliderSettings;
