import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";

const BrandSuggestionBox = ({brand_id}) =>{

	const accessToken = localStorage.getItem('accessToken');

	const [brandOptions, setBrandOptions] = useState([]);
	const [brandFilteredOptions, setBrandFilteredOptions] = useState([]);
	const [brandValue, setBrandValue] = useState(null);
	const [brandInputValue, setBrandInputValue] = useState('');

	const brandHandleChange = (selectedOption) => {
	    setBrandValue(selectedOption);
	};

	const brandHandleInputChange = (inputValue) => {
	    setBrandInputValue(inputValue);
	    const newFilteredOptions = brandOptions.filter(option =>
	        option.label.toLowerCase().includes(inputValue.toLowerCase())
	    );
	    setBrandFilteredOptions(newFilteredOptions);
	};

	const fetchBrandList = async () => {
	  		
		const apibrandUrl = `${config.API_URL}/get_brands`;
		try {
			const response = await fetch(apibrandUrl, {
				method : 'POST',
				headers : {
					authorization : `Bearer ${accessToken}`,
				}
			});
			const data = await response.json();
			//console.log(data);
			const formattedOptions = data.data.map(item => ({
	        label: item.name,  
	  		value: item.id,    
	    }));
	    setBrandOptions(formattedOptions);
	    setBrandFilteredOptions(formattedOptions);

		}catch(error){
			console.error('api fail error fetching brand');
		}
	};

	useEffect(() => {

		if (accessToken === null){
	   		window.location.href = '/login'; 
	    	return;
		}
		fetchBrandList();

	},[]);

	useEffect(() => {
        if (brand_id) {
            const selectedBrand = brandOptions.find(option => option.value === brand_id);
            setBrandValue(selectedBrand || null);
        }
    }, [brand_id, brandOptions]);

	return(
		<>
			<Select className='abovedd promoselect'
			    isClearable
			    onInputChange={brandHandleInputChange}
			    onChange={brandHandleChange}
			    options={brandFilteredOptions}
			    placeholder="Select Brand"
			    value={brandValue}
			    name="brand"
			/>

		</>
	);

}

export default BrandSuggestionBox;
