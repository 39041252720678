import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';

const RoutePlaningInvoice = () => {
  useEffect(() => {
    $(document).ready(function() {
      $('table').DataTable();
    });
  }, []);

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="Dashboard">Dashboard</a> &gt; <a href="CreateManifest">Create Menifest</a> &gt; <span className="last-crumb">Proforma Invoice</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="show-order-area dashboard">
                <div className="sales-view-area">
                  <a href="#">Back</a>
                  <hr />
                  <div className="row">
                    <div className="col-6">
                      <p>
                        <span>Ebusso</span><br />
                        Unt F1 Larkfield Trading Estate New Hythe Lane,<br />
                        Larkfield, Aylesford, England, ME20 6SW<br />
                        <span>Tel: +44 75855 91189</span><br />
                        <span>VAT NO VAT01,</span><br />
                        <span>COMPANY REG NO AWRSXEAW000000116976</span>
                      </p>
                    </div>
                    <div className="col-6">
                      <ul>
                        <li>
                          PLEASE PHONE THE OFFICE TO PAY BY CREDIT/DEBIT CARD<br />
                          <span>Bank A/C: </span> 111111111<br />
                          <span>Sort Code: </span> 222222
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-6">
                      <p>
                        <span>TK Trading Co.</span><br />
                        London 181397,<br />
                        UK
                      </p>
                    </div>
                    <div className="col-6">
                      <ul>
                        <li><span>Invoice No: </span> INV27A07130</li>
                        <li><span>Invoice Date: </span> 05-06-2024</li>
                        <li><span>Cust Order Id: </span> 27A07130</li>
                        <li><span>Cust Account No: </span> 01</li>
                        <li><span>Trip No: </span> TRIP0000</li>
                      </ul>
                    </div>
                  </div>
                  <div className="sales-view-table">
                    <table width="100%" border="0" cellspacing="0" cellpadding="0" className="table table-bordered">
                      <tbody>
                        <tr>
                          <th>No.</th>
                          <th>Description</th>
                          <th>Quantity</th>
                          <th>Unit Price</th>
                          <th>VAT Amount</th>
                          <th>Net Amount</th>
                        </tr>
                        <tr>
                          <td>0862</td>
                          <td>ALOEVERA PINEAPPLE-12x500ML</td>
                          <td>1.00</td>
                          <td>£8.95</td>
                          <td>1.79</td>
                          <td>£8.95</td>
                        </tr>
                        <tr>
                          <td>5027</td>
                          <td>Aqueous Lined Compostable Kraft P-750ml</td>
                          <td>1.00</td>
                          <td>£7.15</td>
                          <td>1.43</td>
                          <td>£7.15</td>
                        </tr>
                        <tr>
                          <td colSpan="5"><span className="right">Total Std Goods</span></td>
                          <td><span>£16.10</span></td>
                        </tr>
                        <tr>
                          <td colSpan="5"><span className="right">VAT Amount</span></td>
                          <td><span>£3.22</span></td>
                        </tr>
                        <tr>
                          <td colSpan="5"><span className="right">Total Zero Goods</span></td>
                          <td><span>0.00</span></td>
                        </tr>
                        <tr>
                          <td colSpan="5"><span className="right">Promotion Discount</span></td>
                          <td><span>0.00</span></td>
                        </tr>
                        <tr>
                          <td colSpan="5"><span className="right">Order Discount</span></td>
                          <td><span>0.00</span></td>
                        </tr>
                        <tr>
                          <td colSpan="5"><span className="right">Invoice Total</span></td>
                          <td><span>£19.32</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default RoutePlaningInvoice;
