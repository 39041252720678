import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import CustomDateRangePicker from '../common/CustomDateRangePicker';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import AccountService from '../../services/AccountServices';

const PaymentLadger = () => {
	const accessToken = localStorage.getItem('accessToken');
	const [dates, setDates] = useState([null, null]);
	const [reportrange, setReportrange] = useState('');

	const [salesRapOptions, setSalesRapOptions] = useState([]);
  	const [salesRap, setSalesRap] = useState('');
  	const [paymentDetails, setPaymentDetails] = useState({
    	name : '',
    	startDate : '',
    	endDate : ''
    });
    const [paymentLedger, setPaymentLedger] = useState([]);
    const [nodataRow, setNodataRow] = useState('');

  	const handleOptionChange = (event) => {
	    const value = event.target.value;
	    setSalesRap(value);
	};

	const [userOptions, setUserOptions] = useState([]);
    const [userFilteredOptions, setUserFilteredOptions] = useState([]);
    const [userValue, setUserValue] = useState(null);  
    const [userInputValue, setUserInputValue] = useState('');


	const fetchSalesRapOptions = async () => {
  		const apiSalesRapUrl = `${API_URL}/getSalesRep`;
	    try {
	     	const response = await fetch(apiSalesRapUrl, {
        		method: 'POST',
        		headers: {
          			// 'Content-Type': 'application/json',
          			authorization: `Bearer ${accessToken}`,
        		}
      		});

	      	const data = await response.json();
	      	
            const formattedOptions = data.data.map(item => ({
                label: `${item.first_name} ${item.last_name}`,
                value: item.id,
            }));

            setUserOptions(formattedOptions);
            setUserFilteredOptions(formattedOptions);

	    }catch (error) {
	      console.error('api fail Error fetching Not Order Report:', error);
	    }
  	};

  	const userHandleChange = (selectedOption) => {
        
        if (selectedOption ) {
            setUserValue(selectedOption);
        } else {
            setUserValue(null); 
        }
    };

    const userHandleInputChange = (inputValue) => {
        setUserInputValue(inputValue);
        const newFilteredOptions = userOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setUserFilteredOptions(newFilteredOptions);
    };

	const searchFormData = () => {
	    let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
	    let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
	    setReportrange(startDate ? startDate + ' - ' + endDate : '');
	    
	    let salesRapId = userValue ? userValue.value : '';

	    //console.log(salesRapId);
	   

	   	if (!salesRapId) {
	        setFlashMessage({ message: 'Please select customer', type: 'error' });
	        return;
	    }
	    loadPaymentLadger(salesRapId, startDate, endDate);
	    //console.log(salesRapId+'--'+startDate+'--'+endDate);
	   
	    
	};

	const loadPaymentLadger = async (salesRapId, startDate, endDate) => {
		try {
			const data = await AccountService.fetchPaymentLadger(salesRapId, startDate, endDate, accessToken);
			//console.log(data);
			if (Array.isArray(data.data) && data.data.length === 0) {
	           // console.log('No data available.');
			    setPaymentDetails({ name: '', startDate : '', endDate : '' });
	            setPaymentLedger([]); 
	            setNodataRow('showinfo');
	            return; 
	        }
	        if (data.data.customer_credit.id) {
				setPaymentDetails({
			        name : data.data.customer_credit.first_name+' '+data.data.customer_credit.last_name,
			        startDate : data.start_date,   
			        endDate : data.end_date
			    });
	      		setPaymentLedger(data.data.customer_ledger); 
	      		setNodataRow('');
				  console.log(data);
      		}
		} catch (error) {
			console.log('Failed to load Payment ledger', error);
		}
	};
	const [flashMessage, setFlashMessage] = useState(null);
	const handleCloseMessage = () => {
		setFlashMessage(null);
	};

	useEffect(() => {

  		if (accessToken === null){
            window.location.href = '/login'; 
            return;
        }
        
    	fetchSalesRapOptions();

  	},[]);

	const renderRow = (item) => {
	    const {
			id,
			date,
			name,
			sales_person_id,
			driver_id,
			collected_amount,
			allocated_amount,
			customer_id,
			payment_mode,
			is_assign,
			account_no,
	    } = item;
	    const formattedAmount = parseFloat(collected_amount).toFixed(2);
	    const formattedPaymentMode = payment_mode ? payment_mode : '';
		const formattedDate = date ? date : '';
		const formattedCompany = name ? name : '';
	    return (
	      <tr >
	        <td>{formattedDate}</td>
	        <td>{formattedCompany}</td>
	        <td style={{ textAlign: 'right' }}>{formattedAmount}</td>
	        <td style={{ textAlign: 'right' }}>{formattedPaymentMode}</td>
	      </tr>
	    );
	};

	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/account/payment_ladger">Account</Link> 
		                  <Link to="/account/payment_ladger">Payment Ledger</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">
			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>Payment Ledger </b></p>
						{paymentDetails.name && (
	                   	<div>
		                    
		                    <button type="button" class="acceptbtn" style={{position: 'absolute', right: '18px', top: '6px'}}>Print</button>
	                    </div>
	                    )}

	                    <div className="row" >
	                    	<div className="col-md-3 cp-lg-3 col-sm-3" style={{float: 'left', paddingLeft: '20px'}}>    		
		            			
		            			{/*<select value={salesRap} onChange={handleOptionChange} className="form-control selectinput" name="" id="" >
				                    <option value="">Select user</option>
				                      {salesRapOptions.map(option => (
				                            <option key={option.value} value={option.value}>
				                              {option.key}
				                            </option>
				                      ))}
				                </select> */}

				                <Select className='abovedd'
						            isClearable
						            onInputChange={userHandleInputChange}
						            onChange={userHandleChange}
						            options={userFilteredOptions}  // Pass the filtered options here
						            placeholder="Select User"
						            value={userValue}
						        />
							    
							</div>

	              			<div className="col-md-9 col-lg-9 col-sm-12">
	                			<CustomDateRangePicker dates={dates} onChange={setDates} onSubmit={searchFormData} />
	              			</div>

	              			
		            	</div>

						{paymentDetails.name && (
		            	<div className="row customerInfo" >
		            		<div className="col-md-8 col-lg-8 col-sm-12">
				            	<div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
				            		<p style={{textAlign : 'left', margin: '0px', border: 'none'}}><b>From Date : </b> {paymentDetails.startDate} To : {paymentDetails.endDate}</p>
			                        <table className="table table-bordered dataTable">
			                           
			                            <tbody>
			                             	<tr>
			                                	<td style={{width: '35%'}}><b style={{fontWeight : '800'}}>{paymentDetails.name}</b></td>
			                                </tr>
			                                
			                            </tbody>
			                          
			                        </table>
			                    </div>
		                    </div>
	                    </div>
	                    )}

						{paymentLedger.length > 0 && (
	                    <div className="row customerInfo">
	                    	<div className="col-md-9 col-lg-9 col-sm-12">
	                    		<div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
			                        <table className="table table-bordered dataTable" >
			                            <thead>
			                                <tr>
			                                    <th>Date</th>
			                                    <th>Customer</th>
			                                    <th>Amount</th>
			                                    <th>Payment Mode</th>

			                                </tr>
			                            </thead>
			                            <tbody>
									    	{paymentLedger.map((item) => renderRow(item))}
										</tbody>

			                        </table>
			                    </div>
	                    	</div>
	                    </div>
	                    )}

						{nodataRow && (
						<div className="row text-center">
							<div className="col-md-8 col-lg-8 col-sm-12" style={{paddingTop: '15px'}}>
								<p className="text-danger">NO Transaction Available.</p>
							</div>
						</div>
						)}

	                </div>
	            </div>
	        </div>

	      	<FlashMessage
				message={flashMessage?.message}
				type={flashMessage?.type}
				onClose={handleCloseMessage}
			/>
	      	<Footer />

		</>
	);

}

export default PaymentLadger;