import React, { useState, useEffect, useRef } from 'react';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';
import ExportProductList from './ExportProductList';

const ActiveProductList = ({refresh}) => {
	const activePdtRef = useRef(null);
	const [searchValue, setSearchValue] = useState([]);
	const accessToken = localStorage.getItem('accessToken');
	const [totalActiveProduct, setTotalActiveProduct] = useState('');

	const getAcivepdtList = () => {
    	const $table = $(activePdtRef.current);
    	const searchValue = $('#searchValue').val();
    	if ($.fn.DataTable.isDataTable($table)) {
        	$table.DataTable().destroy();
    	}
    	let i = 0;
    	$table.DataTable({
        	processing: true,
        	serverSide: true,
        	ajax: {
            	url: `${config.API_URL}/products/getactive_product`,
            	type: 'POST',
            	headers: {
               	authorization : `Bearer ${accessToken}`,
            	},
            	data: function (d) {
                	return $.extend({}, d, {
                    	draw: d.draw,
                    	start: d.start,
                    	length: d.length,
                    	searchValue : searchValue
                	});
            	},
            	dataSrc: function (json) {
                	if (json && json.data && Array.isArray(json.data)) {
                    setTotalActiveProduct(json.recordsFiltered); 
                    
                	}
                	return Array.isArray(json.data) ? json.data : [];
            	}
        	},
        	language: {
            	paginate: {
                	first: 'First',
                	last: 'Last',
                	next: 'Next',
                	previous: 'Previous',
            	},
            	search: 'Search:',
        	},
        	columns: [
           	{ data: 'code' },
	        { data: 'name' },
	        { 
	        	data: null,
	        	render : function(data, type, row){
	        		return `<span>${row.categories}</span>`;
	        	}
	        },
	        { data: 'brand' },
	        { data: 'size' },
	        { data: 'box_cost', className : 'text-end' },
	        { data: 'price', className : 'text-end' },
	        { data: 'box_diff', className : 'text-center' },
	        { data: 'quantity', className : 'text-end' },
	        { data: 'piece_cost', className : 'text-end' },
	        { data: 'piece_price', className : 'text-end' },
	        { data: 'piece_diff', className : 'text-center' },
	        { data: 'split_quantity' },
            	{
	          data: 'code',
	          render: function(data, type, row) {
	            const checked = row.status == "1" ? "checked" : "";
	            return `
	              <label class="switch">
	                <input data-id="${row.id}" class="switch-input" type="checkbox" name="changeStatus" id="changeStatus_${row.id}" ${checked} />
	                <span class="switch-label" data-on="Yes" data-off="No"></span>
	                <span class="switch-handle"></span>
	              </label>`;
	          }
	        },
	        {
	          data: 'code',
	          render: function(data, type, row) {
	            return `<div class="btn-group">
	                      <button type="button" class="action-btn-dd dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
	                      <ul class="dropdown-menu dropdown-menu-arrow">
	                        <li><a class="deductionbtn reject-btn" href="${config.BASE_URL}/products/edit/${row.id}" >Edit Product</a></li>
	                        <li><a class="deductionbtn reject-btn" href="${config.BASE_URL}/products/previous_pricing/${row.id}" >Previous Pricing</a></li>
	                      </ul>
	                    </div>`;
	          }
	        },
        	],
        	columnDefs: [
            {
                orderable: false, 
                targets: '_all'
            },
            {
                targets: 2, 
                render: function(data) {
                    return data === '1' ? 'Active' : 'Inactive'; 
                }
            }
      	],
      
        	pageLength: 10,
    	});

    	

    	$table.on('change', '.switch-input', function() {
	        const id = $(this).data('id');
	        const status = this.checked ? 1 : 0;

	        handleChangeStatus(id, status);
	    });
	};

	useEffect(() => {
    	if (accessToken === null) {
        	window.location.href = 'react/login';
        	return;
    	}
    	//getAcivepdtList();  

    	if (refresh) {
	      getAcivepdtList();
	      
	    }

    	return () => {
        	if (activePdtRef.current && $.fn.DataTable.isDataTable(activePdtRef.current)) {
            	$(activePdtRef.current).DataTable().destroy();
        	}
    	};
	}, [searchValue, refresh]);

	const handleChangeStatus = async(id, status)=>{
    const formData = new FormData();
    formData.append('productId', id);
    formData.append('status', status);
    const apiUrl = `${config.API_URL}/products/update_status`; 
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
           authorization : `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to change status');
      }

      const data = await response.json();
      console.log(data);
      
      Swal.fire({
        title: 'Success',
        text: 'Status changed successfully.',
        icon: 'success', 
        showConfirmButton: false, 
        timer: 1500,  
      });

      getAcivepdtList();
      
    } catch (error) {
      console.error('Error status change:', error);
    }
  }

  

	return(
		<>
			
		<div style={{ margin: "7px 0 0 0", float: "right"  }}>
			<ExportProductList totalActiveProduct={totalActiveProduct} urlLink="products/getactive_product" pdtStatus="active" />
		</div>
		
			<table class="table table-bordered dataTable active-tbl" id="activePdtListTable" ref={activePdtRef}>
        <thead>
          <tr>
            <th colspan="5" className='dark-head'>&nbsp;</th>
            <th colspan="4" className='dark-head' style={{ textAlign: 'center' }}>Box</th>
            <th colspan="4" className='dark-head' style={{ textAlign: 'center' }}>Piece</th>
            <th colspan="2" className='dark-head'>&nbsp;</th>
          </tr>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Category</th>
            <th>Brand</th>
            <th>Size</th>
            <th className='minitable-one'>Cost P</th>
            <th className='minitable-one'>Sale P</th>
            <th className='minitable-one'>Profit %</th>
            <th className='minitable-one'>Qty</th>
            <th className='minitable-two'>Cost P</th>
            <th className='minitable-two'>Sale P</th>
            <th className='minitable-two'>Profit %</th>
            <th className='minitable-two'>Qty</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          
        </tbody>
      </table>


		</>

	);


}
export default ActiveProductList;