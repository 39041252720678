import React, { useState, useEffect } from 'react';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import * as XLSX from 'xlsx';


const MisReport = () => {

	const [reportrange, setReportrange] = useState('');
	const [fromDate, setFromDate] = useState('');
  	const [toDate, setToDate] = useState('');
	const [draw, setDraw] = useState(1);
  	const [start, setStart] = useState(0);
  	const [length, setLength] = useState(50);
  	const [dates, setDates] = useState([null, null]);
  	const [reportDate, setReportDate] = useState('');
  	const [misReport, setMisReport] = useState([]);

  	const [selectedValue, setSelectedValue] = useState('');

  	const [salesRapOptions, setSalesRapOptions] = useState([]);
  	const [salesRap, setSalesRap] = useState('');

  	const [routeOptions, setRouteOptions] = useState([]);
  	const [salesRoute, setSalesRoute] = useState('');

  	const accessToken = localStorage.getItem('accessToken'); 
  	


  	const handleDateChange = (value) => {
    	setDates(value);
  	};

  	const formatDateRange = (dates) => {
    	if (dates[0] && dates[1]) {
      		const startDate = dates[0].toDateString();
      		const endDate = dates[1].toDateString();
      		return `${startDate} - ${endDate}`;
    	}
    	return 'Not selected';
  	};

  	const fetchSalesRapOptions = async () => {
  		
  		const apiSalesRapUrl = `${config.API_URL}/getSalesRep`;
	    try {
	     	const response = await fetch(apiSalesRapUrl, {
        		method: 'POST',
        		headers: {
          			// 'Content-Type': 'application/json',
          			authorization: `Bearer ${accessToken}`,
        		}
      		});

	      	const data = await response.json();
	      	const formattedOptions = data.data.map(item => ({
                key: `${item.first_name}`,
                value: item.id,
            }));
            setSalesRapOptions(formattedOptions);
	    }catch (error) {
	      console.error('api fail Error fetching Not Order Report:', error);
	    }
  	};

  	const fetchSalesRoute = async () => {
  		
  		const apiSalesRouteUrl = `${config.API_URL}/getRoute`;
  		try {
  			const response = await fetch(apiSalesRouteUrl, {
  				method : 'POST',
  				headers : {
  					authorization : `Bearer ${accessToken}`,
  				}
  			});

  			const data = await response.json();
  			const formattedOptions = data.data.map(item => ({
                key: `${item.route_number}`,
                value: item.id,
            }));
            setRouteOptions(formattedOptions);

  		}catch(error){
  			console.error('api fail error fetching sales routes');
  		}
  	};

  	const fetchReport = async () => {

  		const now = new Date();
		const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
		const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  		let reportData = reportrange ? reportrange : startOfMonth+'-'+endOfMonth;
  		const formData = new FormData();
	    formData.append('draw', draw);
	    formData.append('start', start);
	    formData.append('length', length);
	    formData.append('reportrange', reportrange ? reportrange : '');
	    formData.append('sales_rep', salesRap);
	    formData.append('route_id', salesRoute);

	    
	    const apiUrl = `${config.API_URL}/mis_report`;
	    try {
	     	const response = await fetch(apiUrl, {
        		method: 'POST',
        		headers: {
          			// 'Content-Type': 'application/json',
          			authorization: `Bearer ${accessToken}`,
        		},
        		body: formData,
      		});

	      	if (!response.ok) {
	        	throw new Error('Failed to fetch Not Order Report');
	      	}
	      	const data = await response.json();
	      //	console.log(data.data);
	      	setMisReport(data);
	      	setReportDate(data.startdate+' To Date : '+data.enddate);
	    }catch (error) {
	      console.error('api fail Error fetching Not Order Report:', error);
	    }

  	};

  	useEffect(() => {

  		if (accessToken === null){
            window.location.href = '/login'; 
            return;
        }
        
    	fetchReport();
    	fetchSalesRapOptions();
    	fetchSalesRoute();
  	},[reportrange]);


  	useEffect(() => {
    	const table = $('#misOrderReport').DataTable({
      		pageLength: 50,
		    paging: true,
		    lengthMenu: [10, 20, 50],
		    searching: true,
		    ordering: true,
		    info: true,
		    responsive: true,
		    autoWidth: false,
		    language: {
		        paginate: {
		            first: 'First',
		            last: 'Last',
		            next: 'Next',
		            previous: 'Previous',
		        },
		        search: 'Search:',
		    },
      		data: misReport.data,
      		columns: [
        		{ data: null },
        		{ data: 'date' },
        		{ 
        			data: null, 
        			render : function(data, type, row){
        				
        				return '<a href="' + config.BASE_URL + '/reports/mis_report/print/' + row.id + '">' + row.reference_no + '</a>';
        			}
        		},
        		{ data: 'order_amt' },
        		{ data: 'accept_amt' },
        		{ data: 'picking_amt' },
        		{ data: 'deliver_amt' },
        		{ data: 'invoice_amt' },
        		{ data: 'total_tax' },
        		{ data: 'sale_status' },
        		
    		],
    		columnDefs: [
		        { 
		            targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], 
		            orderable: false 
		        }
		    ],
    		drawCallback: function (settings) {
        		var api = this.api();
        		var startIndex = api.page.info().start;
		        api.column(0, { page: 'current' }).nodes().each(function (cell, i) {
		            cell.innerHTML = startIndex + i + 1;
		        });
		        $('tfoot').empty();
		        var footerRow  = $('<tr>')
		            .append("<td></td>")
		            .append('<td></td>')
		            .append("<td><b style='font-weight: 700 !important;' >Total</b></td>")
		            .append("<td style='text-align: right !important;font-weight: 700 !important;'>"+misReport.order_amt+"</td>")
		            .append("<td style='text-align: right !important;font-weight: 700 !important;'>"+misReport.accept_amt+"</td>")
		            .append("<td style='text-align: right !important;font-weight: 700 !important;'>"+misReport.picking_amt+"</td>")
		            .append("<td style='text-align: right !important;font-weight: 700 !important;'>"+misReport.deliver_amt+"</td>")
		            .append("<td></td>")
		            .append("<td style='font-weight: 700 !important;text-align: right !important;'>VAT</td>")
		            .append('<td></td>'); 
		        $('tfoot').append(footerRow);
    		}
    	});
    	return () => {
      		table.destroy();
    	};
  	}, [misReport]);


  	const searchFormData = () => {
  		let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
  		let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
  		setReportrange(startDate ? startDate+' - '+endDate : '');
  		fetchReport();
  	};

  	const handleOptionChange = (event) => {
	    const value = event.target.value;
	    setSalesRap(value);
	};

	const handleChangeRoute = (event) => {
		const value = event.target.value;
		setSalesRoute(value);
	};


	const exportToExcel = () =>{
		const exportData = misReport.data.map(product => ({
	        Date: product.date || '',
	        "Order Number": product.reference_no || '',
	        "Order": product.order_amt || '', 
	        "Accept": product.accept_amt || '',
	        "Picking": product.picking_amt || '',
	        "Delivered": product.deliver_amt || '',
	        "Invoice": product.invoice_amt || '',
	        "Status": product.sale_status || ''
	    }));

	    const ws = XLSX.utils.json_to_sheet(exportData);
      	const wb = XLSX.utils.book_new();
      	XLSX.utils.book_append_sheet(wb, ws, 'Mis Report');

      	// Get current date and time for the filename (e.g., '2024-11-07-14-30-45')
      	const now = new Date();
      	const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
      	const formattedTime = `${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
      	const filename = `mis-report-${formattedDate}-${formattedTime}.xlsx`;

      
      	XLSX.writeFile(wb, filename);

	}



  	return (
		<div>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/reports/mis_report">Reports</Link> 
		                  <Link to="/reports/mis_report">mis report</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>

	      	<div className="main-container">
			    <div className="container-fluid">
			    
			        <div className="row">
		                
		                	<div className="col-md-3 col-lg-3 col-sm-3" style={{float: 'left'}}>
							<label  style={{marginBottom: '5px'}} ><b>Select Date</b></label>
		                		
				          <DateRangePicker value={dates}
							        onChange={handleDateChange}
							        format="MM/dd/yyyy"
							        character=" - "
							        style={{ width: '100%' }}
							    />
							</div>
							<div className="col-md-3 col-lg-3 col-sm-3" style={{float: 'left'}}>
								
							<label  style={{marginBottom: '5px'}} ><b>Select Driver</b></label>
							    <select value={salesRap} onChange={handleOptionChange} className="form-control selectinput" name="" id="" >
				                    <option value="">Select a Sales Rep</option>
				                      {salesRapOptions.map(option => (
				                            <option key={option.value} value={option.value}>
				                              {option.key}
				                            </option>
				                      ))}
				                </select>

							</div>

							
							<div className="col-md-3 col-lg-3 col-sm-3" style={{float: 'left'}}>
							<label  style={{marginBottom: '5px'}} ><b>Select Route</b></label>
							   
								<select value={salesRoute} onChange={handleChangeRoute} className="form-control selectinput" name="" id="" >
				                    <option value="">Select Route</option>
				                      {routeOptions.map(option => (
				                            <option key={option.value} value={option.value}>
				                              {option.key}
				                            </option>
				                      ))}
				                </select>

							</div>



							<div className="col-md-3 col-lg-3 col-sm-3" style={{float: 'left', paddingTop: '26px' }} >
								<button type="button" onClick={searchFormData} className="acceptbtn" style={{float: 'left'}}>Submit</button>
							</div>
					      
		            
		                
	                </div>

	                <div className="total-manifests-area dashboard">
	                    <p>
	                    	<b>MIS Report From Date : {reportDate}</b>
	                    	{misReport?.data?.length > 0 && (
  <button className="create-catalogue-btn" onClick={exportToExcel} style={{textTransform: 'capitalize'}}>
    Export
  </button>
)}

	                    </p>



	                    <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
	                        <table className="table table-bordered" id="misOrderReport">
	                            <thead>
	                                <tr>
	                                    <th>Sr.no</th>
	                                    <th>Date</th>
	                                    <th>Order No</th>
	                                    <th>Order</th>
	                                    <th>Accept</th>
	                                    <th>Priking</th>
	                                    <th>Deliverd</th>
	                                    <th>Invoice</th>
	                                    <th>VAT</th>
	                                    <th>Status</th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                
	                            </tbody>
	                            <tfoot>

					            </tfoot>
	                        </table>
	                    </div>
	                </div>
			           
			    </div>
			</div>

			<Footer />
		</div>
	);

}

export default MisReport;
