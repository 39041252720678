import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";

const BarcodeSuggestionBox = ({barcode_id}) =>{

	const accessToken = localStorage.getItem('accessToken');

	const [barcodeOptions, setBarcodeOptions] = useState([]);
	const [barcodeFilteredOptions, setBarcodeFilteredOptions] = useState([]);
	const [barcodeValue, setBarcodeValue] = useState(null);
	const [barcodeInputValue, setBarcodeInputValue] = useState('');

	const staticBarcodeData = [
        { id: 'code25', name: 'Code25' },
        { id: 'code39', name: 'Code39' },
        { id: 'code128', name: 'Code128' },
        { id: 'ean8', name: 'EAN8' },
        { id: 'ean13', name: 'EAN13' },
        { id: 'upca', name: 'UPC-A' },
        { id: 'upce', name: 'UPC-E' }
    ];


	const barcodeHandleChange = (selectedOption) => {
	    setBarcodeValue(selectedOption);
	};

	const barcodeHandleInputChange = (inputValue) => {
	    setBarcodeInputValue(inputValue);
	    const newFilteredOptions = barcodeOptions.filter(option =>
	        option.label.toLowerCase().includes(inputValue.toLowerCase())
	    );
	    setBarcodeFilteredOptions(newFilteredOptions);
	};


	const fetchBarcodeList = () => {

		const formattedOptions = staticBarcodeData.map(({ id, name }) => ({
            label: name,
            id: id,
            value: id
        }));
        
        setBarcodeOptions(formattedOptions);
        setBarcodeFilteredOptions(formattedOptions);
	  		
		
	};

	useEffect(() => {

		if (accessToken === null){
	   		window.location.href = '/login'; 
	    	return;
		}
		fetchBarcodeList();

	},[]);

	useEffect(() => {
        if (barcode_id) {
            const selectedBarcode = barcodeOptions.find(option => option.value === barcode_id);
            setBarcodeValue(selectedBarcode || null);
        }
    }, [barcode_id, barcodeOptions]);

	return(
		<>
			<Select className='abovedd'
			    isClearable
			    onInputChange={barcodeHandleInputChange}
			    onChange={barcodeHandleChange}
			    options={barcodeFilteredOptions}
			    placeholder="Select"
			    value={barcodeValue}
			    name="barcode_symbology" className="promoselect"
			/>


		</>
	);

}
export default BarcodeSuggestionBox;