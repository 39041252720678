import { API_URL } from '../components/Config';
import  config  from '../components/common/Config';

export const fetchPushSageList = async (draw, start, length, accessToken) => {

	const formData = new FormData();
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);

    const apiPushSageUrl = `${config.API_URL}/sage/sagelist`;
    try {
        const response = await fetch(apiPushSageUrl, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch push sage');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('API Error fetching push sage:', error);
        throw error;
    }

};

export const fetchAllPushInvoiceList = async (draw, start, length, accessToken) => {

    const params = new URLSearchParams();
    params.append('draw', draw);
    params.append('start', start);
    params.append('length', length);
    const apiAllPushInvoiceUrl = `${config.API_URL}/pushall/invoices?${params.toString()}`;
    try {
        const response = await fetch(apiAllPushInvoiceUrl, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch push sage');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('API Error fetching push sage:', error);
        throw error;
    }

};

export const fetchReceivedPaymentList = async (draw, start, length, reportrange, accessToken) => {

    const formData = new FormData();
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('reportrange', reportrange ? reportrange : '');
    const apiReceivedPaymentUrl = `${config.API_URL}/payment/receive`;
    //const apiReceivedPaymentUrl = `https://dev.ebusso.com/payment/receive`;
    try {
        const response = await fetch(apiReceivedPaymentUrl, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch received payment');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('API Error fetching received payment:', error);
        throw error;
    }

};
export const fetchPaymentHistoryList = async (draw, start, length, reportrange, accessToken) => {

    const formData = new FormData();
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('reportrange', reportrange ? reportrange : '');
    const apiReceivedPaymentUrl = `${config.API_URL}/payment/history`;
    try {
        const response = await fetch(apiReceivedPaymentUrl, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch payment history');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('API Error fetching payment history:', error);
        throw error;
    }

};

export const fetchCustomerLadger = async (customerId, startDate, endDate, accessToken) => {

    const formData = new FormData();
    formData.append('driver', "0");
    formData.append('sale_rep', "0");
    formData.append('customer_id', customerId ? customerId : '');
    formData.append('start_date', startDate ? startDate : '');
    formData.append('end_date', endDate ? endDate : '');
    const apiCustomerLadgerUrl = `${config.API_URL}/get_customer_ladger`;
    //const apiCustomerLadgerUrl = `https://dev.ebusso.com/admin/payments/get_customer_ladger`;
    try {
        const response = await fetch(apiCustomerLadgerUrl, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch customer ledger');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('API Error fetching customer ledger:', error);
        throw error;
    }

};

export const fetchSupplierLadger = async (supplierId, startDate, endDate, accessToken) => {

    const formData = new FormData();
    formData.append('driver', "0");
    formData.append('sale_rep', "0");
    formData.append('supplier_id', supplierId ? supplierId : '');
    formData.append('start_date', startDate ? startDate : '');
    formData.append('end_date', endDate ? endDate : '');
    const apiSupplierLadgerUrl = `${config.API_URL}/get_supplier_ladger`;
    //const apiSupplierLadgerUrl = `https://dev.ebusso.com/admin/payments/get_supplier_ladger`;
    try {
        const response = await fetch(apiSupplierLadgerUrl, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch customer ledger');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('API Error fetching customer ledger:', error);
        throw error;
    }

};

export const fetchPaymentLadger = async (salesRapId, startDate, endDate, accessToken) => {

    const formData = new FormData();
    formData.append('driver', "0");
    formData.append('customer_id', "0");
    formData.append('sale_rep', salesRapId ? salesRapId : '');
    formData.append('start_date', startDate ? startDate : '');
    formData.append('end_date', endDate ? endDate : '');

    //console.log(formData);
    const apiPaymentLadgerUrl = `${config.API_URL}/get_customer_ladger`;
    //const apiPaymentLadgerUrl = `https://dev.ebusso.com/admin/payments/get_customer_ladger`;
    try {
        const response = await fetch(apiPaymentLadgerUrl, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch customer demo ledger');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('API Error fetching customer ledger:', error);
        throw error;
    }

};

export const fetchMyLadger = async (startDate, endDate, accessToken, user_id) => {

    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('driver', "0");
    formData.append('customer_id', "0");
    formData.append('sale_rep', "0");
    formData.append('start_date', startDate ? startDate : '');
    formData.append('end_date', endDate ? endDate : '');

    const apiMyLadgerUrl = `${config.API_URL}/get_manager_ledger`;
    //const apiMyLadgerUrl = `https://dev.ebusso.com/admin/payments/get_manager_ledger`;
    try {
        const response = await fetch(apiMyLadgerUrl, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch customer demo ledger');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('API Error fetching customer ledger:', error);
        throw error;
    }

};

export const fetchProductList = async(accessToken) =>{
    const formData = new FormData();
    

    const apiMyLadgerUrl = `${config.API_URL}/purchases/getProducts`;
    try {
        const response = await fetch(apiMyLadgerUrl, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch product list');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('API Error fetching product list:', error);
        throw error;
    }
};

export default { fetchPushSageList, fetchAllPushInvoiceList, fetchReceivedPaymentList, fetchPaymentHistoryList, fetchCustomerLadger, fetchSupplierLadger, fetchPaymentLadger, fetchMyLadger, fetchProductList };