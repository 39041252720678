import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import { useParams } from 'react-router-dom';
import config from "../common/Config";
import Swal from 'sweetalert2';


const AddPurchasesGRN = (props) => {
	const { orderId } = useParams();
	const accessToken = localStorage.getItem('accessToken');
	const userId = localStorage.getItem('userId');

	
	const [newProductList, setNewProductList] = useState([]);
    const [tableRows, setTableRows] = useState([]); 
    const [productData, setProductData] = useState(null);

    const [grnnumber, setGrnnumber] = useState('');
    const [supplierName, setSupplierName] = useState('');
    const [supplierId, setSupplierId] = useState('');

    const [errors, setErrors] = useState({});

	/*const fetchPurchesItemsList = async () => {

  		const formData = new FormData();
  		formData.append('id', orderId);
  		
  		const apiSalesRouteUrl = `${config.API_URL}/purchases/getpurches_items`; 
  		try {
  			const response = await fetch(apiSalesRouteUrl, {
  				method : 'POST',
  				headers : {
  					authorization : `Bearer ${accessToken}`,
  				},
  				body: formData,
  			});
  			const data = await response.json();

  			console.log(data);

 			const productData = data.purchase;
  			setGrnnumber(data.commonData[0].reference_no);
  			setSupplierName(data.commonData[0].name);
  			setSupplierId(data.commonData[0].supplierId);

  			setProductData(data.ProductData);
  			const aggregatedProductData = data.ProductData.reduce((acc, item) => {
		
			  if (!acc[item.product_id]) {
			    acc[item.product_id] = {
			      expiry_date: item.expiry_date,
			      piece_stock: item.type == 1 ? parseInt(item.box_stock) : parseInt(item.piece_stock),
			    };
			  }

			  if (item.expiry_date < acc[item.product_id].expiry_date) {
			    acc[item.product_id].expiry_date = item.expiry_date;
			  }

			  acc[item.product_id].piece_stock += parseInt(item.piece_stock);

			  return acc;
			}, {});

			const aggregatedProductDataArray = Object.keys(aggregatedProductData).map(product_id => ({
			  product_id,
			  ...aggregatedProductData[product_id]
			}));

			setNewProductList(aggregatedProductDataArray);

  			const pdtRows = productData.map((item) => {
	            // Determine unit cost

	            const regex = /^(\d+)x/;

	            const sizeMatch = item.size.match(regex);
				const sizeValue = sizeMatch ? parseFloat(sizeMatch[1]) : NaN;


	            const unitCost = item.split === '0' || item.split === '2'
	                ? parseFloat(item.unit_cost)
	                : parseFloat(item.piece_cost);

	            const salePrice = item.split === '0' || item.split === '2'
	                ? parseFloat(item.price)
	                : parseFloat(item.split_price);

				const productInfo = aggregatedProductDataArray.find(
				    (product) => product.product_id === item.product_id
				);

			  	const expiryData = productInfo
			    	? [{ expiryDate: productInfo.expiry_date, batchQty: productInfo.piece_stock }]
			    	: [{ expiryDate: "", batchQty: "" }];
            
	            
	            return {

	                id: item.id,
					product_id:item.product_id,
					product_code : item.product_code,
	                price: item.price,
	                piece_cost: item.piece_cost,
	                split: item.split,
	                type: item.split === '0' || item.split === '2' ? 'box' : 'piece',
	                split_quantity: item.split_quantity,
	                split_price: item.split_price,
	                name: item.product_name,
	                size: item.size,
	                cost: item.unit_cost,
	                rate: item.rate,
	                stockCount: item.split === '0' ? parseInt(item.current_stock) : parseInt(item.split_quantity),
	                pdtcost: unitCost.toFixed(2),
	                salePrice : salePrice.toFixed(2),
	                order_qty:  parseInt(item.quantity),
	                profit: (salePrice - unitCost).toFixed(2),
	                expiryData,
	               
	            };
	        });

        	setTableRows(pdtRows);

        	//console.log(pdtRows);
        	

  		}catch(error){
  			console.error('api fail error fetching sales routes');
  		}
  	};*/

  	const fetchPurchesItemsList = async () => {
	  const formData = new FormData();
	  formData.append('id', orderId);
	  
	  const apiSalesRouteUrl = `${config.API_URL}/purchases/getpurches_items`; 
	  try {
	    const response = await fetch(apiSalesRouteUrl, {
	      method: 'POST',
	      headers: {
	        authorization: `Bearer ${accessToken}`,
	      },
	      body: formData,
	    });
	    const data = await response.json();
	    console.log(data);

	    const productData = data.purchase;
	    setGrnnumber(data.commonData[0].reference_no);
	    setSupplierName(data.commonData[0].name);
	    setSupplierId(data.commonData[0].supplierId);
	    setProductData(data.ProductData);

	    // Aggregating product data (with null safety checks)
	    const aggregatedProductData = data.ProductData.reduce((acc, item) => {
	      if (!acc[item.product_id]) {
	        acc[item.product_id] = {
	          expiry_date: item.expiry_date || "", // Fallback if expiry_date is null
	          piece_stock: item.type == 1 ? parseInt(item.box_stock) : parseInt(item.piece_stock || 0), // Handle null piece_stock
	        };
	      }
	      if (item.expiry_date < acc[item.product_id].expiry_date) {
	        acc[item.product_id].expiry_date = item.expiry_date;
	      }
	      acc[item.product_id].piece_stock += parseInt(item.piece_stock || 0); // Handle null piece_stock

	      return acc;
	    }, {});

	    const aggregatedProductDataArray = Object.keys(aggregatedProductData).map(product_id => ({
	      product_id,
	      ...aggregatedProductData[product_id],
	    }));

	    setNewProductList(aggregatedProductDataArray);

	    const pdtRows = productData.map((item) => {

	      const sizeMatch = item.size ? item.size.match(/^(\d+)x/) : null;
	      const sizeValue = sizeMatch ? parseFloat(sizeMatch[1]) : NaN;

	      const unitCost = item.split == '0' || item.split == '2'
	        ? parseFloat(item.unit_cost) || 0 
	        : parseFloat(item.piece_cost) || 0; 

	      const salePrice = item.split == '0' || item.split == '2'
	        ? parseFloat(item.price) || 0 
	        : parseFloat(item.split_price) || 0; 

	      // Find product info from aggregated data
	      const productInfo = aggregatedProductDataArray.find(
	        (product) => product.product_id === item.product_id
	      );

	      const expiryData = productInfo
	        ? [{ expiryDate: productInfo.expiry_date || "", batchQty: productInfo.piece_stock || 0 }]
	        : [{ expiryDate: "", batchQty: 0 }]; // Fallback for null expiry_date and piece_stock

	      return {
	        id: item.id,
	        product_id: item.product_id,
	        product_code: item.product_code,
	        price: item.price || 0, // Fallback for null price
	        piece_cost: item.piece_cost || 0, // Fallback for null piece_cost
	        split: item.split || 0, // Fallback for null split
	        type: item.split === '0' || item.split === '2' ? 'box' : 'piece',
	        split_quantity: item.split_quantity || 0, // Fallback for null split_quantity
	        split_price: item.split_price || 0, // Fallback for null split_price
	        name: item.product_name,
	        size: item.size || "N/A", // Fallback for null size
	        cost: item.unit_cost || 0, // Fallback for null unit_cost
	        rate: item.rate || 0, // Fallback for null rate
	        stockCount: item.split == '0' ? parseInt(item.current_stock || 0) : parseInt(item.split_quantity || 0),
	        pdtcost: unitCost.toFixed(2),
	        salePrice: salePrice.toFixed(2),
	        order_qty: parseInt(item.quantity || 0), // Fallback for null quantity
	        profit: (salePrice - unitCost).toFixed(2),
	        expiryData,
	        current_stock : parseInt(item.current_stock || 0),

	      };
	    });

	    setTableRows(pdtRows);
	  } catch (error) {
	    console.error('API failed while fetching purchase items', error);
	  }
	};

	 const todayDate = new Date().toISOString().split('T')[0];

	  const handleSubmit = async () => {

	  	
		const formData = new FormData(); 

		let formIsValid = true;
	    let newErrors = {}; // Track errors for each row

	    // Validate batch_qty and expiry for each row
	    tableRows.forEach((row, rowIndex) => {
	        row.expiryData.forEach((data, index) => {
	            if (!data.batchQty || !data.expiryDate) {
	                formIsValid = false;
	                if (!newErrors[row.id]) {
	                    newErrors[row.id] = { batchQty: "", expiryDate: "" };
	                }
	                if (!data.batchQty) {
	                    newErrors[row.id].batchQty = "Batch quantity is required.";
	                }else if (!data.expiryDate) {
	                    newErrors[row.id].expiryDate = "Expiry date is required.";
	                } 
	            }
	        });
	    });

	    setErrors(newErrors); // Update the error state

	    if (!formIsValid) {
	        return; // If form is not valid, stop the form submission
	    }
	
		// Append basic purchase data
		formData.append("purchase_id", orderId.trim());
		formData.append("user_id", userId);
		formData.append("invoiceno", ""); 

		if (productData && productData.length > 0) {
            formData.append("is_grn", "Y");
        } else {
            formData.append("is_grn", "N");
        }

		formData.append("supplierId", supplierId); // Use the supplier's ID instead of the name
		formData.append("po_id", orderId);
	
		// Iterate over tableRows to fill the data
		tableRows.forEach((row) => {
			formData.append("type[]", row.split === '0' || row.split === '2' ? 'Box' : 'Piece');
			formData.append("cost[]", row.pdtcost);
			formData.append("sale_price[]", row.salePrice);
			formData.append("profit[]", row.profit);
			formData.append("order_qty[]", row.order_qty);
			formData.append("code[]", row.product_code);
			formData.append("name[]", row.name);
			formData.append("product_id[]", row.product_id);
			formData.append("id[]", row.id);
	
			// Add quantities and expiry dates for each product
			row.expiryData.forEach((expiry) => {
				if (row.product_id) { // Ensure product_id is valid before using it
					const qtyKey = `qty_${row.product_id}[]`.trim();
					const edateKey = `edate_${row.product_id}[]`.trim();
	
					// Append quantities and expiry dates
					formData.append(qtyKey, row.order_qty); // Append order_qty
					formData.append(edateKey, expiry.expiryDate); // Append expiry date
				} else {
					console.warn(`Product ID is undefined for row:`, row); // Log a warning if product_id is not defined
				}
			});
		});
	
		// Prepare the API URL
		const apiPurchaseRouteUrl = `${config.API_URL}/purchases/grnadd`;
	
		try {
			const response = await axios.post(apiPurchaseRouteUrl, formData, {
				headers: {
					authorization: `Bearer ${accessToken}`,
					// 'Content-Type': 'application/json' // Do not set Content-Type when using FormData
				},
			});
			const result = response.data;

			console.log(result);

			const msg = result.message;
          	if (result.success == true){
              Swal.fire({
                title: "Success!",
                text: "GRN added successfully.",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              }).then(() => 
              {
                window.location.href = config.BASE_URL + '/purchases/purchases_order';

                
            
              });
          	}else{
            	Swal.fire({
	                title: "Failed!",
	                text: msg,
	                icon: "error",
	                timer: 2500,
	                showConfirmButton: false,
	            });
          	}
		} catch (error) {
			console.error("Error posting purchase data:", error);
		}
	};

	

	
	
	

  	useEffect(() => {

  		if (accessToken === null){
            window.location.href = '/login'; 
            return;
        }
        
        fetchPurchesItemsList();
  	},[]);

  	const handleCancel = (id) => {
        setTableRows(prevRows => prevRows.filter(row => row.id !== id));
    };
    const handleCostChange = (id, newCost) => {
        setTableRows(prevRows => {
            return prevRows.map(row => {
                if (row.id === id) {
                    const pdtcost = parseFloat(newCost) || 0.00;
                    const salePrice = parseFloat(row.salePrice) || 0.00;
                    const profit = (salePrice - pdtcost).toFixed(2);
                 
                    return {
                        ...row,
                        pdtcost,
                        profit
                    };
                }
                return row;
            });
        });
    };

    const handleOptionChange = (id, pdtType) => {
        console.log(id + '----'+ pdtType);
        setTableRows(prevRows => {
            return prevRows.map(row => {
                if (row.id === id) {
                    const pdtcost = pdtType == 'Box' ? row.price : row.piece_cost;
                    //const salePrice = parseFloat(row.salePrice) || 0.00;
                     const salePrice = pdtType == 'Box' ? parseInt(row.price || 0.00).toFixed(2) : parseInt(row.split_price || 0.00).toFixed(2);
                    const type = pdtType === 'Box' ? 'box' : 'piece';
                    const profit = (salePrice - pdtcost).toFixed(2);

                    const stockCount = pdtType == 'Box' ? parseInt(row.current_stock || 0) : parseInt(row.split_quantity || 0);
                   
                    return {
                        ...row,
                        type,
                        pdtcost,
                        profit,
                        stockCount,
                        salePrice
                    };
                }
                return row;
            });
        });
    };

    const handleSelePriceChange = (id, newCost) => {
        setTableRows(prevRows => {
            return prevRows.map(row => {
                if (row.id === id) {
                    const salePrice = parseFloat(newCost) || 0;
                    const pdtcost = parseFloat(row.pdtcost) || 0;
                    const profit = (salePrice - pdtcost).toFixed(2);
                 
                    return {
                        ...row,
                        salePrice,
                        pdtcost,
                        profit
                    };
                }
                return row;
            });
        });
    };


    /*const handleExpiryDataChange = (e, itemId, index, field) => {
	    const newValue = e.target.value;
	  
	    setTableRows(prevItems => prevItems.map(item => 
	      item.id === itemId 
	        ? { 
	            ...item, 
	            expiryData: item.expiryData.map((data, i) => 
	              i === index ? { ...data, [field]: newValue } : data
	            ) 
	          } 
	        : item
	    ));
	};*/

	const handleExpiryDataChange = (e, itemId, index, field) => {
	  let newValue = e.target.value;

	  if (field === 'batchQty') {
	    if (newValue === "" || /^[0-9]*$/.test(newValue)) {
	      setTableRows(prevItems => prevItems.map(item => 
	        item.id === itemId 
	          ? { 
	              ...item, 
	              expiryData: item.expiryData.map((data, i) => 
	                i === index ? { ...data, [field]: newValue } : data
	              ) 
	            } 
	          : item
	      ));
	    }
	  } else if (field === 'expiryDate') {
	    setTableRows(prevItems => prevItems.map(item => 
	      item.id === itemId 
	        ? { 
	            ...item, 
	            expiryData: item.expiryData.map((data, i) => 
	              i === index ? { ...data, [field]: newValue } : data
	            ) 
	          } 
	        : item
	    ));
	  }
	};



	const addExpiryData = (itemId) => {
        setTableRows(prevItems =>
            prevItems.map(item => 
                item.id === itemId 
                    ? { 
                        ...item, 
                        expiryData: [...item.expiryData, { expiryDate: "", batchQty: "" }] 
                      } 
                    : item
            )
        );
    };

    const removeExpiryData = (itemId, index) => {
    	setTableRows(prevItems =>
        	prevItems.map(item => 
            item.id === itemId 
                ? { 
                    ...item, 
                    expiryData: item.expiryData.filter((_, i) => i !== index) 
                  } 
                : item
              )
        );
    };
    

	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/reports/mis_report">Purchases</Link> 
		                  <Link to="/reports/mis_report">Add Purchases {orderId}</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">
			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>Add GRN </b></p>

	                    <div className="row">
				    		<div className="col-md-9 col-lg-9 col-sm-12">
				    			<div className="col-md-3 cp-lg-3 col-sm-3" style={{float: 'left',  padding: '17px 0px 0px 15px'}}>
								    <label style={{fontWeight: 'bold',paddingBottom: '5px'}}>GRN Number</label>
								    <input type="text" disabled value={grnnumber} className="form-control" />
					                

								</div>

								<div className="col-md-3 cp-lg-3 col-sm-3" style={{float: 'left',  padding: '17px 0px 0px 15px'}}>
								    <label style={{fontWeight: 'bold',paddingBottom: '5px'}} >Suppiler Name</label>
					                <input type="text" disabled value={supplierName}  className="form-control" />
								</div>
								<div className="col-md-3 cp-lg-3 col-sm-3" style={{float: 'left',  padding: '17px 0px 0px 15px'}}>
								    <label style={{fontWeight: 'bold',paddingBottom: '5px'}} >Invoice No</label>
					                <input type="text" className="form-control" />
								</div>
				    		</div>
				    	</div>

				    	<div className="verical-scrl-wrapper common-table autoheight-table" id="style-3" style={{marginTop: '15px'}} >
	                        <table className="table table-bordered dataTable" id="newProductTable">
                                <thead>
                                    <tr>
                                        <th>Code - Name - Size</th>
                                        <th>Type</th>
                                        <th>Cost Price</th>
                                        <th>Sale Price</th>
                                        <th>Profit</th>
                                        <th>Current Stock</th>
                                        <th>Order Qty</th>
                                        <th>Qty - Expiry Date</th>
                                        <th className="text-center">Action</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                   {tableRows.length === 0 ? (
									    <tr style={{ textAlign: "center" }}>
									        <td colSpan={9}>No product added</td>
									    </tr>
									) : (
									    tableRows.map(row => (
									        <tr key={row.id}>
									            <input type='text' name="product_id[]" value={row.product_id} hidden/>
									            <td>{row.product_code}-{row.name} - {row.size}</td>
									            <td className="text-center" style={{width: '80px'}}>
									                {row.split == 2 ? (
									                    <select
									                        onChange={(e) => handleOptionChange(row.id, e.target.value)}
									                        className="form-select"
									                        style={{width: '75px'}}
									                        name="type[]"
									                    >
									                        <option value="Box">Box</option>
									                        <option value="Piece">Piece</option>
									                    </select>
									                ) : (
									                    <>
									                        <input
									                            type="hidden"
									                            value={row.split == 0 ? 'Box' : 'Piece'}
									                            name="type[]"
									                        />
									                        {row.split == 0 ? 'Box' : 'Piece'}
									                    </>
									                )}
									            </td>
									            <td className="text-center" style={{width: '80px'}}>
									                <input
									                    type="number"
									                    value={row.pdtcost}
									                    min="0"
									                    onChange={(e) => handleCostChange(row.id, e.target.value)}
									                    className="form-control order-cost no-arrows"
									                    style={{width: '75px', padding: '5px', textAlign: 'right', margin: '0px auto'}}
									                />
									            </td>
									            <td className="text-center" style={{width: '80px'}}>
									                <input
									                    type="number"
									                    value={row.salePrice}
									                    min="0"
									                    onChange={(e) => handleSelePriceChange(row.id, e.target.value)}
									                    className="form-control order-qty no-arrows"
									                    style={{width: '75px', padding: '5px', textAlign: 'right'}}
									                />
									            </td>
									            <td className="text-end" style={{width: '5%'}}>{row.profit}</td>
									            <td className="text-center" style={{width: '5%'}}>{row.stockCount}</td>
									            <td className="text-center" style={{width: '5%'}}>{row.order_qty}</td>
									            <td style={{width: '15%'}}>
									                {row.expiryData.map((data, index) => (
									                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
									                        <input
									                            type="text"
									                            min="0"
									                            className="form-control batchQty"
									                            name={`batch_qty_${row.product_id}[]`}
									                            value={data.batchQty}
									                            style={{ width: '55px', marginRight: '5px' }}
									                            onChange={(e) => handleExpiryDataChange(e, row.id, index, 'batchQty')}
									                        />
									                        
									                        <input
									                            type="date"
									                            className="form-control expiryDate"
									                            name={`expiry_${row.product_id}[]`}
									                            value={data.expiryDate}
									                            style={{ width: '130px', marginRight: '5px' }}
									                            onChange={(e) => handleExpiryDataChange(e, row.id, index, 'expiryDate')}
									                        />
									                        
									                        {index === 0 ? (
									                            <button 
									                                type="button" 
									                                onClick={() => addExpiryData(row.id)}
									                                style={{padding: '0px', fontSize: '19px', background: 'none', border: 'none', color: 'green', cursor: 'pointer'}}
									                            >
									                                <i className="fa fa-plus-square-o"></i>
									                            </button>
									                        ) : (
									                            <button 
									                                type="button" 
									                                onClick={() => removeExpiryData(row.id, index)} 
									                                style={{padding: '0px', fontSize: '19px', background: 'none', border: 'none', color: 'red', cursor: 'pointer'}}
									                            >
									                                <i className="fa fa-trash-o"></i>
									                            </button>
									                        )}
									                    </div>
									                ))}

									                {errors[row.id]?.batchQty && (
							                            <div className="text-danger" style={{ fontSize: '12px' }}>
							                                {errors[row.id].batchQty}
							                            </div>
							                        )}
							                        {errors[row.id]?.expiryDate && (
							                            <div className="text-danger" style={{ fontSize: '12px' }}>
							                                {errors[row.id].expiryDate}
							                            </div>
							                        )}
									            </td>
									            <td className="text-center" style={{width: '3%'}}>
									                <button
									                    className="btn btn-danger cancel-btn"
									                    onClick={() => handleCancel(row.id)}
									                    style={{padding: '0px', fontSize: '19px', color: '#dc3545', background: 'none', border: 'none'}}
									                >
									                    <i className="fa fa-trash-o"></i>
									                </button>
									            </td>
									        </tr>
									    ))
									)}

                                </tbody>
                            </table>



	                    </div>
						<button onClick={handleSubmit} className="btn btn-primary">
    Submit
</button>
	                </div>
			    </div>
			</div>
	      	<Footer />
		</>
	);

}
export default AddPurchasesGRN;