import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import config from "../common/Config";

const MaxValueOrder = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [newSales, setNewSales] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  const [totalOrder, setTotalOrder] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [average, setAverage] = useState('');




  let dateRange = '';
  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/login";
      return;
    }

    fetchPageData();
  }, []);

  const fetchPageData = async () => {
    const formData = new FormData();
    formData.append("draw", draw);
    formData.append("start", start);
    formData.append("length", length);
    formData.append("searchByFromdate", startDate ? startDate : "");
    formData.append("searchByTodate", endDate ? endDate : "");

    const apiUrl = `${config.API_URL}/getCustomerOrder`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Not Order Report");
      }
      const data = await response.json();
      //	console.log(data.data);
      setNewSales(data.data);

      setTotalOrder(data.total_order);
      setTotalAmount(data.total_amount);
      setAverage(data.average_amount_per_order);




    } catch (error) {
      console.error("api fail Error fetching Not Order Report:", error);
    }
  };

  const handleSubmit = () =>{
    fetchPageData();
  }

  useEffect(() => {
    const table = $("#neworderTable").DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      data: newSales,
      columns: [
        {
            data: null, 
            render: function(data, type, row, meta) {
              return meta.row + 1;
            },
            createdCell: function(td) {
              $(td).css("text-align", "center"); 
            }
          },
        { data: "accound_no",
            createdCell: function(td) {
              $(td).css("text-align", "center"); 
            } },        
        { data: "name" },
        { data: "total_orders",
            createdCell: function(td) {
              $(td).css("text-align", "center"); 
            } },
        { data: "total_amount" }
      ],
      columnDefs: [
        {
          targets: [1, 2, 3, 4],
          orderable: true,
        },
      ],
    });
    return () => {
      table.destroy();
    };
  }, [newSales]);

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="#">Sales</Link>
              <Link to="#">Max Order Value</Link>
            </div>
          </div>
        </div>
      </div>
     
      <div className="main-container">
        <div className="container-fluid">
        <div className="col-md-12 col-lg-12 col-xs-12">
            <div className="col-md-12 col-xs-12">
            <div className="row">
		        	<div className="col-md-12 col-lg-12 col-sm-12">
		        		{/* <div className="date-range-picker col-md-2 col-lg-2 col-sm-3" style={{float: 'left'}}>	
		            		<label style={{marginBottom: '5px'}} ><b>From Date</b></label>
		            		<input
                        type="date"
                        name="fromDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="form-control"/>
		            </div>
		            <div className="date-range-picker col-md-2 col-lg-2 col-sm-3" style={{float: 'left', paddingLeft: '15px'}}>	
		            		<label style={{marginBottom: '5px'}} ><b>To Date</b></label>
		            		<input
                        type="date"
                        name="toDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="form-control"
                      />
		            </div>
		            <div>
                  <button type="submit" onClick={handleSubmit}  className="acceptbtn" style={{float: 'left', margin: '26px 0 0 15px'}} >Submit</button>
								</div> */}
                <div className="col-md-12 col-xs-12 " style={{paddingTop : '7px'}}>
                <div className="col-md-2 cp-lg-2 col-sm-3" style={{float: 'left', textAlign: 'center'}}>	
                    <label style={{marginBottom: '5px', float:'left', width: '100%'}} ><b>Total Order : </b></label>{totalOrder} 
                    </div>
                    <div className="col-md-2 cp-lg-2 col-sm-3" style={{float: 'left', textAlign: 'center'}}>	
                    <label style={{marginBottom: '5px', float:'left', width: '100%'}} ><b>Total Amount : </b></label> {Number(totalAmount).toFixed(2)}
                   </div>
                   <div className="col-md-2 cp-lg-2 col-sm-3" style={{float: 'left', textAlign: 'center'}}>	
                    <label style={{marginBottom: '5px', float:'left', width: '100%'}} ><b>Average : </b></label> {Number(average).toFixed(2)}
                   </div>
                  </div>
              </div>
		        </div>
          </div>
        </div>



          <div
            className="total-manifests-area dashboard"
            style={{ position: "relative" }}>
            <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
              <table className="table table-bordered" id="neworderTable">
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>#</th>
                    <th style={{textAlign:'center'}}>Account No</th>
                    <th style={{textAlign:'center'}}>Customer Name</th>
                    <th style={{textAlign:'center'}}>Total Orders</th>
                    <th style={{textAlign:'center'}}>Total Amount</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MaxValueOrder;
