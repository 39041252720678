import React from 'react';

const SelectDropdown = ({ selectRef, value, handleChange, roleOptions, name, id }) => {
  return (
    <select
      ref={selectRef}
      multiple={true}
      value={value}
      onChange={handleChange}
      className="form-control"
      name={name}
      id={id}
    >
      {roleOptions.map(option => (
        <option key={option.value} value={option.value}>
          {option.key}
        </option>
      ))}
    </select>
  );
};

export default SelectDropdown;
