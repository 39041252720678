import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "./common/Config";

const NewSales = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');



  const [selectedRole, setSelectedRole] = useState('');
  const [newSales, setNewSales] = useState([]); // State to store statistics data
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const accessToken = localStorage.getItem('accessToken'); 


  const handleStartDateChange = (e) => {
    setFromDate(e.target.value);
  };

  // Handler for end date change
  const handleEndDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
   // formData.append('role', selectedRole);

    const apiUrl = `${config.API_URL}/sales/saleslist_test`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      setNewSales(data.data);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const table = $('#neworderTable').DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: true,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      data: newSales,
      columns: [
        {
          data: null,
          render: function () {
            return `<label class="checkbox">
                      <input type="checkbox" />
                      <span class="default"></span>
                    </label>`;
          },
        },
        { data: 'date' },
        {
          data: 'reference_no',
          render: function (data) {
            return `<a href="Edit_New_Invoice">${data}</a>`;
          },
        },
        {
          data: 'customer',
          render: function (data) {
            return `<p class="cut-text">${data}</p>`;
          },
        },
        { data: 'postal_code' },
        { data: 'route_number' },
        { data: 'grand_total' },
        { data: 'prev_due' },
        { data: 'name' },
        {
          data: null,
          render: function () {
            return `<div class="filter verti-popdot">
                      <a class="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                        Action <i class="bi bi-three-dots-vertical"></i>
                      </a>
                      <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <li><a class="deductionbtn" href="#" data-bs-toggle="modal" data-bs-target="#reject-order">Reject Order</a></li>
                        <li><a class="applybtn" href="#" data-bs-toggle="modal" data-bs-target="#change-customer">Change Customer</a></li>
                      </ul>
                    </div>`;
          },
        },
      ],
    });

    return () => {
      table.destroy();
    };
  }, [newSales]);

  return (
    

    <div>

      <div class="modal fade" id="reject-order" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Reject Order</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="rejectorder-popup">
              <form>
                <h4>Sale Details</h4>
                <table class="table table-bordered">
                  <tr>
                    <td>Order Id</td>
                    <td>27A07255</td>
                  </tr>
                  <tr>
                    <td>customer (A/C)</td>
                    <td>FLEET STREET(12)</td>
                  </tr>
                  <tr>
                    <td>Order Amount</td>
                    <td>6388.80</td>
                  </tr>
                  <tr>
                    <td>Previous Dues</td>
                    <td>-2,472.80</td>
                  </tr>
                </table>
                <div class="form-group">
                <label class="col-form-label">Reason Note</label>
                <textarea>Leave Comment</textarea>
                </div>
                <button type="submit" id="submit" class="updatebtn left"> Update</button>

              </form> 
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <div class="modal fade" id="change-customer" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Update Customer</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="rejectorder-popup">
              <form>
                <h4>Sale Details</h4>
                <table class="table table-bordered">
                  <tr>
                    <td>Order Id</td>
                    <td>27A07255</td>
                  </tr>
                  <tr>
                    <td>customer (A/C)</td>
                    <td>FLEET STREET</td>
                  </tr>
                 
                </table>
                <div class="form-group">
                <label class="col-form-label">Select Customer </label>
                <select class="form-select" aria-label="Default select example">
                            <option selected="">Select Customer</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                </div>
                <button type="submit" id="submit" class="updatebtn left"> Update</button>

              </form> 
              </div>
            </div>
            
          </div>
        </div>
      </div>


      <Header />

      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <span class="last-crumb">New Sales</span> 
            </div>
          </div>
        </div>
      </div>


      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">
                <div className="dashform">
                  <div className="date-range-picker" style={{ float: 'left' }}>
                    <label>From Date</label>
                   
                    <input
                      id="fromDate"
                      type="date"
                      value={fromDate}
                      onChange={handleStartDateChange}
                      className="form-control"
                    />

                    <label>To Date</label>
                   
                    <input
                      id="toDate"
                      type="date"
                      value={toDate}
                      onChange={handleEndDateChange}
                      className="form-control"
                    />

                    <button type="submit" onClick={handleSubmit} className="selectdate-btn">
                      Submit
                    </button>
                  </div>
                </div>
                <button type="submit" id="submit" className="accept-btn">
                      Accept order
                    </button>
                <div>
                  {/* <div style={{ float: 'right', width: '100%', textAlign: 'right', marginTop: '25px' }}>
                  
                  </div> */}
                  <div className="verical-scrl-wrapper newsales-table" id="style-3">
                    <table className="table table-bordered dataTable" id="neworderTable">
                      <thead>
                        <tr>
                          <th>Checkbox</th>
                          <th>Order Date</th>
                          <th>Order Id</th>
                          <th>Customers(A/C No)</th>
                          <th>Post Code</th>
                          <th>Route</th>
                          <th>Order Amt</th>
                          <th>Previous Dues</th>
                          <th>Order By</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newSales.map((item, index) => (
                          <tr key={index}>
                           
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewSales;
