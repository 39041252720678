import jsPDF from 'jspdf';
import 'jspdf-autotable';
import config from "../common/Config";
import axios from 'axios';

const generateUniqueFilename = (baseName, extension) => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  return `${baseName}_${timestamp}.${extension}`;
};

const generateOrderSummaryPDF = async (orderId) => {
  const accessToken = localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.set('orderId', orderId);
  const apiUrl = `${config.API_URL}/ws-order-history-details`;

  try {
    const response = await axios.post(apiUrl, formData, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const data = response.data;
    console.log(data);

    if (!data.success || !data.details.orderDetails || data.details.orderDetails.length === 0) {
      console.error("No order details found in the response.");
      return;  // Return early if order details are not available
    }

    const orderDetails = data.details.orderDetails[0];  
    const shopDetails = data.details.shop_details;
    console.log(orderDetails);

    //const totalSTDGoods = parseFloat(orderDetails.grand_total) - parseFloat(orderDetails.total_tax);
    //const totalVAT = parseFloat(orderDetails.total_tax);
    //const orderDiscount = parseFloat(orderDetails.order_discount);
   // const totalAmount = parseFloat(orderDetails.grand_total);


    const totalstd = data.details.product_details
            .filter(item => item.item_tax != '0.00' && item.item_tax != null)
            .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty)), 0);

    const totalzeroGoods = data.details.product_details
            .filter(item => item.item_tax == '0.00' || item.item_tax === null)
            .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty)), 0);

    const totalSTDGoods = parseFloat(totalstd) - parseFloat(totalzeroGoods);
    const orderDiscount = parseFloat(data.details.orderDetails[0].order_discount) || 0;
    const orderDiscount1 = parseFloat(data.details.orderDetails[0].order_discount1) || 0;

    const totalVAT = data.details.product_details
            .reduce((sum, item) => sum + (item.item_tax ? parseFloat(item.item_tax) : 0), 0);

    const totalAmount = totalstd + totalzeroGoods + totalVAT - orderDiscount - (totalstd * orderDiscount1 / 100);

    const doc = new jsPDF('portrait'); // Set to portrait orientation
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Adjust margins: 5px for left and right
    const margin = 5;  // 5px padding
    let y = margin;

    // Header sections
    const headerSections1 = [
      [
        { text: `${shopDetails.shop_name}`, size: 13, bold: true },
        { text: `${shopDetails.address}`, size: 8, bold: false },
        { text: `Phone No : ${shopDetails.phone}`, size: 8, bold: false },
        { text: `Email : ${shopDetails.email}`, size: 8, bold: false },
        { text: `Website : ${shopDetails.website}`, size: 8, bold: false },
      ],
      [
        { text: 'Bank Details', size: 8, bold: true },
        { text: `A/C No: ${shopDetails.bank_ac}`, size: 8, bold: false },
        { text: `Sort Code : ${shopDetails.sort_code}`, size: 8, bold: false },
      ]
    ];

    const headerSections3 = [
      [
        { text: `Collection Address: ${orderDetails.name}`, size: 8, bold: false },
        { text: `${orderDetails.address}`, size: 8, bold: false },
        { text: `${orderDetails.postal_code}`, size: 8, bold: false },
        { text: `${orderDetails.country}`, size: 8, bold: false },
      ],
      [
        { text: `Invoice No : ${orderDetails.reference_no}`, size: 8, bold: false },
        { text: `Order Date: ${orderDetails.date}`, size: 8, bold: false },
      ],
    ];

    const sectionWidth = (pageWidth - margin * 2) / 2; // Adjust for margins

    // Render header sections
    const renderSections = (sections) => {
      sections.forEach((section, index) => {
        const colStartX = margin + (index % 2) * sectionWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 4); // Reduced space between lines
        });

        if (index % 4 === 3) {
          y += 12; // Reduced spacing after every 4 sections
        }
      });
      y += 25; // Add a little space after the last header section
    };

    renderSections(headerSections1);
    renderSections(headerSections3);

    // Render table columns with customized widths
    const tableColumns = [
      { title: 'Product', dataKey: 'product', size: 6, bold: false },
      { title: 'Order Type', dataKey: 'order_type', size: 6, bold: false },
      { title: 'Qty', dataKey: 'qty', size: 6, bold: false },
      { title: 'Unit Price', dataKey: 'unit_price', size: 6, bold: false },
      { title: 'Discount', dataKey: 'discount', size: 6, bold: false },
      { title: 'VAT', dataKey: 'vat', size: 6, bold: false },
      { title: 'Net Amount', dataKey: 'net_amount', size: 6, bold: false },
    ];

    // Calculate the table widths
     const totalWidth = pageWidth - 2 * margin;
    const productWidth = totalWidth * 0.45;  // 45% of the page width for product name/code
    const orderTypeWidth = totalWidth * 0.06;  // 5% for Order Type
    const qtyWidth = totalWidth * 0.06;  // 5% for Qty
    const unitPriceWidth = totalWidth * 0.12;  // 13% for Unit Price
    const discountWidth = totalWidth * 0.08;  // 6% for Discount
    const vatWidth = totalWidth * 0.11;  // 13% for VAT
    const netAmountWidth = totalWidth * 0.12; 

    // Construct table body
    const tableBody = data.details.product_details.map((item) => [
      item.product_code + ' - ' + item.product_name, // Combined product code and name
      item.order_type,
      parseInt(item.order_qty),
      item.unit_price,
      item.is_promoted,
      item.item_tax,
      item.subtotal,
    ]);

    // Add an empty row with totals
     tableBody.push([
      { content: 'Item Total:', colSpan: 6, styles: { halign: 'right' } }, 
      totalSTDGoods.toFixed(2)
    ]);

    tableBody.push([
      { content: 'Total Vat:', colSpan: 6, styles: { halign: 'right' } }, 
      totalVAT.toFixed(2)
    ]);

    tableBody.push([
      { content: 'Order Discount(0%):', colSpan: 6, styles: { halign: 'right' } }, 
      orderDiscount.toFixed(2)
    ]);

    tableBody.push([
      { content: 'Total Amount:', colSpan: 6, styles: { halign: 'right' } }, 
      totalAmount.toFixed(2)
    ]);

    // Render the table with column widths and right-alignment for certain columns
    /*doc.autoTable({
      startY: y,
      head: [tableColumns.map(col => col.title)],
      body: tableBody,
      theme: 'grid',
      headStyles: {
        fillColor: '#f8f9fa',
        textColor: '#404040',
        size: 6,  // Set font size to 6 for table header
      },
      bodyStyles: {
        size: 6,  // Set font size to 6 for table body
      },
      margin: { top: 10, left: margin, right: margin },  // Set left and right margin to 5px
      columnStyles: {
        0: { cellWidth: productWidth },    // 45% width for Product column
        1: { cellWidth: orderTypeWidth },  // 5% width for Order Type
        2: { cellWidth: qtyWidth },        // 5% width for Qty
        3: { cellWidth: unitPriceWidth },  // 13% width for Unit Price
        4: { cellWidth: discountWidth },   // 6% width for Discount
        5: { cellWidth: vatWidth },        // 13% width for VAT (now set to exactly 13%)
        6: { cellWidth: netAmountWidth }, 
        1: { halign: 'center' },
        2: { halign: 'center' },
        3: { halign: 'right' }, 
        4: { halign: 'center' },  // Right align Unit Price
        5: { halign: 'right' },  // Right align VAT
        6: { halign: 'right' },  // Right align Net Amount
      },
    });*/

    doc.autoTable({
  startY: y,
  head: [tableColumns.map(col => col.title)],
  body: tableBody,
  theme: 'grid',
  headStyles: {
    fillColor: '#f8f9fa',
    textColor: '#404040',
    fontSize: 8,  // Ensure font size 6 for table header
    valign: 'middle', // Center-align vertically if needed
  },
  bodyStyles: {
    fontSize: 8,  // Ensure font size 6 for table body rows
    valign: 'middle', // Center-align vertically in rows
  },
  margin: { top: 0, left: margin, right: margin },
  columnStyles: {
    0: { cellWidth: productWidth },
    1: { cellWidth: orderTypeWidth },
    2: { cellWidth: qtyWidth },
    3: { cellWidth: unitPriceWidth },
    4: { cellWidth: discountWidth },
    5: { cellWidth: vatWidth },
    6: { cellWidth: netAmountWidth },
    1: { halign: 'center' },
    2: { halign: 'center' },
    3: { halign: 'right' },
    4: { halign: 'center' },
    5: { halign: 'right' },
    6: { halign: 'right' },
  },
});


    y = doc.autoTable.previous.finalY + 12; // Y position after table

    const addFooterSection = (footerSection) => {
      footerSection.forEach((section, index) => {
        const colStartX = margin + (index % 4) * sectionWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 5);
        });

        if (index % 4 === 3) {
          y += 20; // Move down after every 4 sections
        }
      });
      y += 20; // Add space after each footer section
    };

    const addFooterWithSpaceCheck = (footerSection) => {
      const remainingSpace = pageHeight - y - margin; // Remaining space on the page
      const requiredSpace = footerSection.length * 5 + 20; // Space needed

      if (remainingSpace < requiredSpace) {
        doc.addPage(); // Add a new page if not enough space
        y = margin; // Reset Y position for new page
      }

      addFooterSection(footerSection);
    };

    const footerSections2 = [
      [
        { text: 'Good Receoved & Checked By : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ No of Boxes Received : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', size: 8, bold: false },
        { text: '', size: 8, bold: false },
        { text: 'Amount Paid : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ Cash / Cheque Collected By : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', size: 8, bold: false },
        { text: 'Terms -', size: 8, bold: false },
        { text: `1 - Goods remain the property of ${shopDetails.shop_name} until invoice fully paid`, size: 8, bold: false },
        { text: `2 - Once delivery is received ${shopDetails.shop_name} is not responsible for any damage or loss `, size: 8, bold: false },
      ]
    ];

    addFooterWithSpaceCheck(footerSections2);

    const filename = generateUniqueFilename('Invoice', 'pdf');
    doc.save(filename);

  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

export default generateOrderSummaryPDF;
