import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL } from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import Swal from 'sweetalert2';
import config from "../common/Config";
import { Link } from 'react-router-dom';


const PickingList = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [newSales, setNewSales] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken === null) {
      window.location.href = '/login';
      return;
    }

    const apiUrl = `${API_URL}/picking/list`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      setNewSales(Array.isArray(data.data) ? data.data : []);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

const deletePicking = async (picklistNumber) => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken === null) {
    window.location.href = '/login';
    return;
  }

  // Create FormData object
  const formData = new FormData();
  formData.append('picklist_number', picklistNumber);

  try {
    const response = await fetch(`${API_URL}/remove_picklist`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${accessToken}`,
        // Note: FormData automatically sets Content-Type to multipart/form-data
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to delete picking item');
    }

    await response.json();
    fetchData();
    Swal.fire('Deleted!', 'Your picking item has been deleted.', 'success');
  } catch (error) {
    console.error('Error deleting picking item:', error);
    Swal.fire('Error!', 'There was an error deleting the item.', 'error');
  }
};


  const confirmDelete = async (picklistNumber) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      deletePicking(picklistNumber);
      Swal.fire('Deleted!', 'Your picking item has been deleted.', 'success');
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const table = $('#neworderTable').DataTable({
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      data: newSales,
      columns: [
        {
          data: 'picklist_number',
          width: '12%',
          className: 'text-center',
          render: (data, type, row) => {
            
             return `<a href="${config.BASE_URL}/sales/view_picking_list/${row.picklist_number}">${row.picklist_number}</a>`;
            
          }
        },
        { data: 'total_sales_count', width: '12%' },
        { data: 'route_name', width: '12%', className: 'text-center' },
        { data: 'name' },
        { data: 'print_on', width: '12%', className: 'text-center' },
        { data: 'picking_status', width: '12%', className: 'text-center' },
        {
          data: null,
          width: '5%',
          render: function (data, type, row) {

            if(row.is_lock == 0){
            return `
              <div>
                <a class="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                 <i class="bi bi-three-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <li><a class="deductionbtn" href="edit_picking/${row.picklist_number}">Edit</a></li>
                  <li><a class="applybtn" href="#" onclick="handleDelete('${row.picklist_number}')">Delete</a></li>
                </ul>
              </div>`;
            }else{
              return `<span></span>`
            }
          },
        },
      ],
    });

    // Define handleDelete as a global function accessible by DataTables
    window.handleDelete = confirmDelete;

    return () => {
      table.destroy();
      window.handleDelete = null; // Clean up the global function
    };
  }, [newSales]);

  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i> </a>
              <span className="last-crumb">Picking List</span>
            </div>
          </div>
        </div>
      </nav>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
             

                <div className='total-manifests-area dashboard'>
                  {/* <div style={{ float: 'right', width: '100%', textAlign: 'right', marginTop: '25px' }}>

                  </div> */}


                  <div className="verical-scrl-wrapper newsales-table" id="style-3">
                    <table className="table table-bordered dataTable" id="neworderTable">
                      <thead>
                        <tr>
                          <th>Picklist No</th>
                          <th>Invoices</th>
                          <th>Route Name</th>
                          <th>Picker</th>
                          <th>Picklist Date</th>
                          <th>Picking Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* DataTables will handle rendering of rows */}
                      </tbody>
                    </table>
                  </div>


                </div>
              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PickingList;
