import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL, BASE_URL } from './Config';


function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        
        if (!username || !password) {
            setError('Please enter both username and password');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);
            

            const response = await fetch( `${API_URL}/ws-login-v2`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Login failed');
            }

            const data = await response.json();        
            console.log('Login successful:', data);

            localStorage.setItem('accessToken', data.prefix); 
            localStorage.setItem('user_role', data.role); 
            localStorage.setItem('userId', data.profile_details.id);
            localStorage.setItem('site_name', data.site_name); 
            localStorage.setItem('first_name', data.profile_details.first_name); 
            localStorage.setItem('last_name', data.profile_details.last_name); 

                    
           //navigate('/dashboard/');
           if(data.role==8)
           {
           navigate(`/dashboard/${data.prefix}/${data.profile_details.id}`);
            setError(null);
        }
        if(data.role==18)
           {
           navigate(`/sales/Epos/`);
            setError(null);
        }
        } catch (error) 
        {
            setError(error.message || 'Login failed');
            console.error('Login error:', error);
        }
    };

    return (
        <div className="login-body d-flex flex-column h-100">
            <div className="login-container">
                <div className="login-content">
                    <div className="login-content_header">
                        <span className="logo">
                            <img src="assets/img/ayt_logo.png" alt="Ebusso Logo" />
                        </span>
                        <p>Please login to your account.</p>
                    </div>
                    <div style={{ float: 'left', width: '100%' }}>
                        <form className="login-form" onSubmit={handleSubmit}>
                            <input type="text" placeholder="Username" value={username} onChange={handleUsernameChange} />
                            <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                            {error && <p className="text-danger">{error}</p>}
                            <button type="submit" className="login-submit-btn">Login</button>
                        </form>
                    {/*      <div className="login-networks">
                            <p>Forgot your password?<br />Don't worry! <a href="#">Click here</a> to reset</p>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
