import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { API_URL, BASE_URL } from '../Config'; // Import the base URL from Config

const ConfirmShortQty = () => {
  const { picklistNo, productId, orderType } = useParams();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [newSales, setNewSales] = useState([]);
  const [setPickQtyAndReason, setPickQty] = useState([]);

  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);
    formData.append('picklist_number', picklistNo);
    formData.append('product_id', productId);
    formData.append('order_type', orderType);

    const apiUrl = `${API_URL}/ws-view-short-list`;

    const accessToken = localStorage.getItem('accessToken');      
    if (!accessToken) {
      window.location.href = '/login'; 
      return;
    }

    try {
      console.log(`Fetching data from ${apiUrl}`);
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'authorization': `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();     
      setNewSales(data.data || []);
      setPickQty(data.product || []);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole, draw, start, length, picklistNo, productId, orderType]);

  useEffect(() => {
    const initializeDataTable = () => {
      $('#neworderTable').DataTable({
        paging: true,
        lengthMenu: [10, 20, 50],
        searching: true,
        ordering: false,
        info: true,
        responsive: true,
        autoWidth: false,
        language: {
          paginate: {
            first: 'First',
            last: 'Last',
            next: 'Next',
            previous: 'Previous',
          },
          search: 'Search:',
        },
        data: newSales,
        columns: [
          { data: 'reference_no', width: '8%', className: 'text-center' },
          { data: 'customer', width: '12%', className: 'text-center' },
          { data: 'code', width : '4%', className: 'text-start' },
          { data: 'name', width: '20%', className: 'text-start' },
          { data: null, width: '7%', className: 'text-center',
            render : function(data, type, row){
                return `<span>${parseInt(row.quantity)}</span>`;
              } 

          },
          {
            data: null, 
            width: '5%',
            className: 'text-center',
            render: function (data, type, row) {
              const pickedQty = setPickQtyAndReason.find(item => item.id === row.id)?.picked_qty || 0;
              return `
                <input type="text" class="form-control confirmed-qty" data-id="${row.id}" 
                value="${data['confirmed_qty'] || ''}" 
                data-picked-qty="${pickedQty}" 
                oninput="validateQty()" />
                <input type="hidden" class="product-id" value="${row.product_id || ''}" />
                <input type="hidden" class="row-id" value="${row.id || ''}" />`;
            }
          },
          { data: 'order_type', width: '5%', className: 'text-center' },
          {
            data: null, 
            width: '15%',
            className: 'text-center',
            render: function (data, type, row) {       
              const reason = setPickQtyAndReason.find(item => item.id === row.id)?.no_pick_reason || 'N/A';
              return reason;
            }
          }
        ],
      });

      window.validateQty = function() {
        validateQuantities();
      };
    };

    initializeDataTable();

    return () => {
      const dataTable = $('#neworderTable').DataTable();
      if (dataTable) {
        dataTable.destroy();
      }
    };
  }, [newSales, setPickQtyAndReason]);
const validateQuantities = () => {
  // Convert totalPickedQty to a number if it's a string
  const totalPickedQty = parseFloat(setPickQtyAndReason.reduce((sum, item) => sum + (parseFloat(item.picked_qty) || 0), 0)) || 0;

  // Get all input boxes
  const inputs = Array.from(document.querySelectorAll('.confirmed-qty'));

  // Calculate total confirmed quantity and check individual inputs
  let totalConfirmedQty = 0;
  let isValid = true;

  inputs.forEach(input => {
    const value = parseFloat(input.value) || 0;
    const pickedQty = parseFloat(input.getAttribute('data-picked-qty')) || 0;  

    totalConfirmedQty += value;
  });

  console.log('Total Confirmed Quantity:', totalConfirmedQty);
  console.log('Total Picked Quantity (as Float):', totalPickedQty);
  console.log('Type of Total Picked Quantity:', typeof(totalPickedQty));

  // Compare total confirmed quantity with total picked quantity
  if (totalConfirmedQty  !==  totalPickedQty) {
   // alert(`Total confirmed quantity cannot exceed total picked quantity of ${totalPickedQty}.`);
    isValid = false;
  }

  // Update button disabled state based on validation result
  setIsSubmitDisabled(!isValid);
  if (totalConfirmedQty == totalPickedQty)
  {
  return isValid;
}
};



  const handleSubmit = async () => {
    if (!validateQuantities()) return;

    const formData = new FormData();
    $('#neworderTable').find('tbody tr').each(function () {
      const confirmedQty = $(this).find('.confirmed-qty').val();
      const pickedQty = $(this).find('.confirmed-qty').data('picked-qty');
      const productId = $(this).find('.product-id').val();
      const rowId = $(this).find('.row-id').val();

      // if (parseFloat(confirmedQty) > parseFloat(pickedQty)) {
      //   alert(`Confirmed quantity for Order ID ${rowId} cannot exceed picked quantity of ${pickedQty}.`);
      //   return false;
      // }

      if (confirmedQty && productId && rowId) {
        formData.append('order_id[]', rowId);
        formData.append('product_id[]', productId);
        formData.append('confirm_qty[]', confirmedQty);
        formData.append('picklist_number', picklistNo);
      }
    });

    const apiUrl = `${API_URL}/update_short_quantity`;
    const accessToken = localStorage.getItem('accessToken');      
    if (!accessToken) {
      window.location.href = '/login'; 
      return;
    }

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData, // Use FormData as the request body
        headers: {
          'authorization': `Bearer ${accessToken}`, // Include authorization header
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.status === 'true') {
        alert('Quantities successfully updated!');
        window.location.href = `${BASE_URL}/picking/view_short_qty/${picklistNo}`;
      } else {
        alert('Failed to update quantities.');
      }
    } catch (error) {
      console.error('Error submitting confirmed quantities:', error);
    }
  };

  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i> </a>
              <span className="last-crumb">Short Qty</span>
            </div>
          </div>
        </div>
      </nav>
      <div className="text-center">Picked QTY : {setPickQtyAndReason.reduce((sum, item) => sum + item.picked_qty, 0) || 'N/A'}</div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">
                <div className="verical-scrl-wrapper newsales-table" id="style-3">
                  <table className="table table-bordered dataTable" id="neworderTable">
                    <thead>
                      <tr>
                        <th>Order Id</th>
                        <th>Customer Name</th>
                        <th>Code</th>
                        <th>Product</th>
                        <th>Order Qty</th>
                        <th>Confirmed Qty</th>
                        <th>Item Type</th>
                        <th>Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Rows will be dynamically inserted by DataTable */}
                    </tbody>
                  </table>
                </div>
                <button className="btn btn-primary mt-3" onClick={handleSubmit} disabled={isSubmitDisabled}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ConfirmShortQty;
