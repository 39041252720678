import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import Swal from 'sweetalert2';


const PurchasesAddFrom = () => {
    
	const [supplierOptions, setSupplierOptions] = useState([]);
    const [supplierFilteredOptions, setSupplierFilteredOptions] = useState([]);
    const [supplierValue, setSupplierValue] = useState('');
    const [supplierInputValue, setSupplierInputValue] = useState('');

  	const [productOptions, setProductOptions] = useState([]);
    const [productFilteredOptions, setProductFilteredOptions] = useState([]);
    const [productValue, setProductValue] = useState(null);  
    const [productInputValue, setProductInputValue] = useState('');
    
  	const accessToken = localStorage.getItem('accessToken'); 
  	const [newProductList, setNewProductList] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const tableRef = React.useRef(null);
    const [note, setNote] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState('');


  	const supplierHandleChange = (selectedOption) => {
        setSupplierValue(selectedOption);
        setTableRows([]);
        if(selectedOption){

            fetchProductList(selectedOption.value);
            fetchSupplierProductInfo(selectedOption.value);
        }else{
            setProductOptions([]);
            setProductFilteredOptions([]);
            setProductValue('');
            setTableRows([]);
        }
       
    };

    const supplierHandleInputChange = (inputValue) => {
        setSupplierInputValue(inputValue);

        const newFilteredOptions = supplierOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setSupplierFilteredOptions(newFilteredOptions);
    };

    const productHandleChange = (selectedOption) => {
        
        if (selectedOption ) {
            setProductValue(selectedOption);
            fetchAddProductInfo(selectedOption.value);
        } else {
            setProductValue(null); 
        }
    };

    const productHandleInputChange = (inputValue) => {
        setProductInputValue(inputValue);
        const newFilteredOptions = productOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setProductFilteredOptions(newFilteredOptions);
    };
  	
  	const formatNumber = (num) => {
	    return parseFloat(num).toFixed(2);
	};
  	
    /*const fetchSupplierProductInfo = async(supplierid) => {
        const formData = new FormData();


        formData.append('supplier_id', supplierid);
        formData.append('product_id', '');
        const apiUrl = `${config.API_URL}/purchases/get_order_datails`; 
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch new product');
            }

            const data = await response.json();
            const productData = data[0];

            setNewProductList(data.productData);

            const unitCost = productData.split === '0' || productData.split === '2'
                ? parseFloat(productData.price)
                : parseFloat(productData.piece_cost);


            const newRow = {
                code: productData.code,
                id: productData.id,
                price: productData.price,
                piece_cost: productData.piece_cost,
                split: productData.split,
                type :  productData.split === '0' || productData.split === '2' ? 'box' : 'piece',
                split_quantity: productData.split_quantity,
                split_price: productData.split_price,
                name: productData.name,
                size: productData.size,
                cost: productData.cost,
                rate: productData.rate,
                quantity: productData.quantity,
                pdtcost : unitCost.toFixed(2),
                order_qty: 1,
                subtotal: (unitCost * 1).toFixed(2),
                vat: ((unitCost * 1) * formatNumber(productData.rate) / 100).toFixed(2),
                total: ((unitCost * 1) + ((unitCost * 1) * formatNumber(productData.rate) / 100)).toFixed(2),
            };

            console.log(newRow);

            setTableRows((prevRows) => [...prevRows, newRow]);

        } catch (error) {
            console.error('API fetch error:', error);
        }
    }*/

    const fetchSupplierProductInfo = async (supplierid) => {
        const formData = new FormData();
        formData.append('supplier_id', supplierid);
        formData.append('product_id', '');
        
        const apiUrl = `${config.API_URL}/purchases/get_order_datails`; 
        
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch new product');
            }

            const data = await response.json();

            // Set the entire product data to newProductList
            setNewProductList(data);  // Now this will hold all the products, not just the first one

            // Loop through the API response and create a new row for each product
            const newRows = data.map(productData => {
                const unitCost = productData.split === '0' || productData.split === '2'
                    ? parseFloat(productData.cost)
                    : parseFloat(productData.piece_cost);

                return {
                    code: productData.code,
                    id: productData.id,
                    price: productData.price,
                    piece_cost: productData.piece_cost,
                    split: productData.split,
                    type: productData.split === '0' || productData.split === '2' ? 'box' : 'piece',
                    split_quantity: productData.split_quantity,
                    split_price: productData.split_price,
                    name: productData.name,
                    size: productData.size,
                    cost: productData.cost,
                    rate: productData.rate,
                    quantity: productData.quantity,
                    pdtcost: unitCost.toFixed(2),
                    order_qty: 1,
                    subtotal: (unitCost * 1).toFixed(2),
                    vat: ((unitCost * 1) * formatNumber(productData.rate) / 100).toFixed(2),
                    total: ((unitCost * 1) + ((unitCost * 1) * formatNumber(productData.rate) / 100)).toFixed(2),
                    stockCount: productData.split == '0' || productData.split == '2' ? parseInt(productData.quantity) : parseInt(productData.split_quantity),
                };
            });

            // Update tableRows state with all the new rows
            setTableRows(prevRows => [...prevRows, ...newRows]);

        } catch (error) {
            console.error('API fetch error:', error);
        }
    }


    const fetchAddProductInfo = async (selectedOption) => {
        const formData = new FormData();

        if(selectedOption){
            formData.append('product_id', selectedOption);
            const existingProduct = tableRows.find(item => item.id === selectedOption);
            if (existingProduct) {
               
               Swal.fire({
                  title: 'Error',
                  text: 'Product already exists in the list, Please Add Quantity.',
                  icon: 'error',
                  showConfirmButton: false, 
                  timer: 2000,               
                  timerProgressBar: true,     
                });
                return;
            }
        }

        formData.append('supplier', supplierValue.value);
        const apiUrl = `${config.API_URL}/purchases/get_order_datails`; 
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch new product');
            }

            const data = await response.json();
            const productData = data[0];

            setNewProductList(data.productData);

            const unitCost = productData.split === '0' || productData.split === '2'
                ? parseFloat(productData.cost)
                : parseFloat(productData.piece_cost);


            const newRow = {
                code: productData.code,
                id: productData.id,
                price: productData.price,
                piece_cost: productData.piece_cost,
                split: productData.split,
                type :  productData.split === '0' || productData.split === '2' ? 'box' : 'piece',
                split_quantity: productData.split_quantity,
                split_price: productData.split_price,
                name: productData.name,
                size: productData.size,
                cost: productData.cost,
                rate: productData.rate,
                quantity: productData.quantity,
                pdtcost : unitCost.toFixed(2),
                order_qty: 1,
                subtotal: (unitCost * 1).toFixed(2),
                vat: ((unitCost * 1) * formatNumber(productData.rate) / 100).toFixed(2),
                total: ((unitCost * 1) + ((unitCost * 1) * formatNumber(productData.rate) / 100)).toFixed(2),
               stockCount: productData.split == '0' || productData.split == '2' ? parseInt(productData.quantity) : parseInt(productData.split_quantity),
            };

            console.log(newRow);

            setTableRows((prevRows) => [...prevRows, newRow]);

        } catch (error) {
            console.error('API fetch error:', error);
        }
    };



	const fetchSupplierList = async () => {
  		const apiSalesRouteUrl = `${config.API_URL}/purchases/getSupplier`;
  		try {
  			const response = await fetch(apiSalesRouteUrl, {
  				method : 'POST',
  				headers : {
  					authorization : `Bearer ${accessToken}`,
  				}
  			});
  			const data = await response.json();
  			const formattedOptions = data.supplier.map(item => ({
                label: item.company,  
          		value: item.id,    
            }));
            setSupplierOptions(formattedOptions);
            setSupplierFilteredOptions(formattedOptions);

  		}catch(error){
  			console.error('api fail error fetching sales routes');
  		}
  	};


  	const fetchProductList = async (supplierId) => {

        const formData = new FormData();
        formData.append('supplier_id', supplierId);
        const apiSalesRouteUrl = `${config.API_URL}/purchases/getProducts`;
        try {
            const response = await fetch(apiSalesRouteUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            const data = await response.json();
            const formattedOptions = data.products.map(item => ({
                label: item.name,
                value: item.id,
            }));
            setProductOptions(formattedOptions);
            setProductFilteredOptions(formattedOptions);

        } catch (error) {
            console.error('api fail error fetching products');
        }
    };


  	useEffect(() => {

  		if (accessToken === null){
            window.location.href = '/login'; 
            return;
        }
        fetchSupplierList();
        //fetchProductList();

  	},[]);

    const handleQuantityChange = (id, newQty) => {
        setTableRows(prevRows => {
            return prevRows.map(row => {
                if (row.id === id) {
                    const order_qty = parseFloat(newQty) || 0;
                    const unitCost = parseFloat(row.pdtcost) || 0;
                    const rate = parseFloat(row.rate) || 0;

                    const subtotal = (order_qty * unitCost).toFixed(2);
                    const vat = ((order_qty * unitCost) * rate / 100).toFixed(2);
                    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

                    return {
                        ...row,
                        order_qty,
                        subtotal,
                        vat,
                        total
                    };
                }
                return row;
            });
        });
    };

    const handleCostChange = (id, newCost) => {
        setTableRows(prevRows => {
            return prevRows.map(row => {
                if (row.id === id) {
                    const pdtcost = parseFloat(newCost) || 0;
                    const order_qty = parseFloat(row.order_qty) || 0;
                    const rate = parseFloat(row.rate) || 0;

                    const subtotal = (order_qty * pdtcost).toFixed(2);
                    const vat = ((order_qty * pdtcost) * rate / 100).toFixed(2);
                    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

                    return {
                        ...row,
                        pdtcost,
                        subtotal,
                        vat,
                        total
                    };
                }
                return row;
            });
        });
    };

    const handleOptionChange = (id, pdtType) => {
        console.log(id + '----'+ pdtType);
        setTableRows(prevRows => {
            return prevRows.map(row => {
                if (row.id === id) {
                    const pdtcost = pdtType === 'Box' ? row.cost : row.piece_cost;
                    const order_qty = parseFloat(row.order_qty) || 0;
                    const rate = parseFloat(row.rate) || 0;
                    const subtotal = (order_qty * pdtcost).toFixed(2);
                    const vat = ((order_qty * pdtcost) * rate / 100).toFixed(2);
                    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);
                    const type = pdtType === 'Piece' ? 'box' : 'piece';
                    const stockCount = pdtType == 'Box' ? parseInt(row.quantity || 0) : parseInt(row.split_quantity || 0);
                    
                    return {
                        ...row,
                        type,
                        pdtcost,
                        subtotal,
                        vat,
                        total,
                        stockCount
                    };
                }
                return row;
            });
        });
    };
    const handleCancel = (id) => {
        setTableRows(prevRows => prevRows.filter(row => row.id !== id));
    };

    const handleChange = (event) => {
        setNote(event.target.value);
    };

    const submitFormData = async() =>{
        

        if (!supplierValue.value) {
            
            Swal.fire({
                title: "Error!",
                text: "Please select a supplier.",
                icon: "error",
                timer: 1500,
                showConfirmButton: false,
            });
            return; 
        }

        if (tableRows.length === 0) {
            
            Swal.fire({
                title: "Error!",
                text: "Please add at least one product.",
                icon: "error",
                timer: 1500,
                showConfirmButton: false,
            });
            return; 
        }

        const filteredTableRows = tableRows.map(row => ({
            code: row.code,
            name: row.name,
            order_qty: row.order_qty,
            subtotal: row.subtotal,
            vat: row.vat,
            total: row.total,
            type: row.type,
        }));

        
        const form = document.getElementById('addproductform');
        const formData = new FormData(form);
        formData.append('supplier', supplierValue.value);
        //formData.append('tableRows', JSON.stringify(filteredTableRows));
        formData.append('note', note);
        formData.append('warehouse', '1');

        const apiSalesRapUrl = `${config.API_URL}/purchases/purchasesAdd`;
        try {
            const response = await fetch(apiSalesRapUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            const data = await response.json();
            console.log(data);
           if (data.success == true){
                Swal.fire({
                  title: "Success!",
                  text: "Purchase Added successfully.",
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                }).then(() => 
                {
                  
                    window.location.href = `${config.BASE_URL}/purchases/purchases_order/`;
                });
            }else{
              Swal.fire({
                  title: "Failed!",
                  text: "Purchase  Failed",
                  icon: "error",
                  timer: 1500,
                  showConfirmButton: false,
              });
            }
        } catch (error) {
          console.error('Error fetching api:', error);
        }

        setTableRows([]);
        setSupplierValue('');
        setNote('');
    }

	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/reports/mis_report">Purchases</Link> 
		                  <Link to="/reports/mis_report">Add Purchase</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">
			    	<div className="row">
			    		<div className="col-md-9 col-lg-9 col-sm-12">

			    			<div className="col-md-4 cp-lg-4 col-sm-4" style={{float: 'left',  padding: '17px 0px 0px 0px'}}>
							    

				                <Select className='abovedd'
						            isClearable
						            onInputChange={supplierHandleInputChange}
						            onChange={supplierHandleChange}
						            options={supplierFilteredOptions}
						            placeholder="Select Supplier"
						            value={supplierValue}
						        />

							</div>

							<div className="col-md-4 cp-lg-4 col-sm-4" style={{float: 'left',  padding: '17px 0px 0px 15px'}}>
							    
				                <Select className='abovedd'
						            isClearable
						            onInputChange={productHandleInputChange}
						            onChange={productHandleChange}
						            options={productFilteredOptions}  // Pass the filtered options here
						            placeholder="Select Product"
						            value={productValue}
						        />
							</div>

			    		</div>
			    	</div>

			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>Order Items </b></p>
	                    <form id='addproductform'>
    	                    <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
    	                        <table className="table table-bordered dataTable" id="newProductTable">
                                    <thead>
                                        <tr>
                                            <th>Code-Name-Size</th>
                                            <th>Type</th>
                                            <th>Cost Price</th>
                                            <th>Current Stock</th>
                                            <th>Order QTY</th>
                                            <th>Sub Total</th>
                                            <th>VAT</th>
                                            <th>Total</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableRows.length === 0 ? (
                                            <tr style={{ textAlign: "center" }}>
                                                <td colSpan={9}>No product added</td>
                                            </tr>
                                        ) : (
                                            tableRows.map(row => (
                                                <tr key={row.id}>
                                                <input type="hidden" value={row.id} name="product_id[]" />
                                                <input type="hidden" value={row.code} name="code[]" />
                                                <input type="hidden" value={row.name} name="name[]" />

                                                
                                                    <td>{row.code} - {row.name} - {row.size}</td>
                                                    <td className="text-center" style={{width: '80px'}}>

                                                        {row.split == 2 ? (
                                                            <select
                                                                
                                                               onChange={(e) => handleOptionChange(row.id, e.target.value)}
                                                                className="form-select"
                                                                name="type[]"
                                                                style={{width: '75px'}}
                                                            >
                                                                <option value="Box">Box</option>
                                                                <option value="Piece">Piece</option>
                                                            </select>
                                                        ) : (
                                                            
                                                            

                                                            <>
                                                                {row.split == 0 ? 'Box' : 'Piece'}
                                                                <input type="hidden" value={row.split == 0 ? 'Box' : 'Piece'} name="type[]" />
                                                            </>
                                                        )}

                                                    </td>
                                                    <td style={{width: '80px'}}>
                                                        
                                                        <input
                                                            type="number"
                                                            value={row.pdtcost}
                                                            min="0"
                                                            name="cost[]"
                                                            onChange={(e) => handleCostChange(row.id, e.target.value)}
                                                            className="form-control order-cost  no-arrows"
                                                             style={{width: '75px',padding: '5px', textAlign : 'right'}}
                                                        />
                                                    </td>
                                                    <td style={{width: '80px'}} className="text-center">
                                                        {row.stockCount}
                                                    </td>
                                                    <td style={{width: '80px'}}>
                                                        <input
                                                            type="text"
                                                            name="order_qty[]"
                                                            value={row.order_qty}
                                                            min="0"
                                                            onChange={(e) => handleQuantityChange(row.id, e.target.value)}
                                                            className="form-control order-qty text-center"
                                                             style={{width: '75px',padding: '5px'}}
                                                        />
                                                    </td>
                                                    <td style={{width: '80px'}} className="text-end">{row.subtotal}</td>
                                                    <input type="hidden" name="subtotal[]" value={row.subtotal} />
                                                     <input type="hidden" name="vat[]" value={row.vat} />
                                                      <input type="hidden" name="total[]" value={row.total} />
                                                    <td style={{width: '80px'}} className="text-end" >
                                                        {row.vat}
                                                    </td>
                                                    <td style={{width: '80px'}} className="text-end" >
                                                        {row.total}
                                                    </td>
                                                    <td style={{width: '3%'}} className="text-center">
                                                        <button
                                                            className="btn btn-danger cancel-btn"
                                                           onClick={() => handleCancel(row.id)}

                                                           style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                        >
                                                        <i class="fa fa-trash-o" ></i>
                                                            
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
    	                    </div>
                        </form>

                        <div className="row">
                            <div className="col-12">
                                <label><b>Note</b></label>
                                <textarea
                                    name="note"
                                    cols="40"
                                    rows="10"
                                    className="form-control"
                                    id="ponote"
                                    style={{ marginTop: '10px', height: '100px' }}
                                    value={note}        
                                    onChange={handleChange} 
                                />
                            </div>

                            <div className="col-12" style={{paddingTop: '20px'}}>
                               
                                    <button onClick={submitFormData} type="button" class="acceptbtn">Add Order</button>
                               
                            </div>
                        </div>
	                </div>
			    </div>
			</div>

	      	<Footer />
		</>
	);
}
export default PurchasesAddFrom;