import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import CustomDateRangePicker from '../common/CustomDateRangePicker';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import AccountService from '../../services/AccountServices';

const MyLadger = () =>{

	const accessToken = localStorage.getItem('accessToken');
	const user_id = localStorage.getItem('userId');
	const [dates, setDates] = useState([null, null]);
	const [reportrange, setReportrange] = useState('');

	const [myLadger, setMyLadger] = useState([]);

	const searchFormData = () => {
	    let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
	    let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
	    setReportrange(startDate ? startDate + ' - ' + endDate : '');
	    loadMyLadger(startDate, endDate);
	};
	const loadMyLadger = async (startDate, endDate) => {
		try {
			const data = await AccountService.fetchMyLadger(startDate, endDate, accessToken, user_id);
			console.log(data.data.customer_ledger);
			setMyLadger(data.data.customer_ledger);
		} catch (error) {
			console.log('Failed to load Payment ledger', error);
		}
	};

	useEffect(() => {

  		if (accessToken === null){
            window.location.href = '/login'; 
            return;
        }

        loadMyLadger();

  	},[]);

  	useEffect(() => {
		const table = $('#myLadgerList').DataTable({
			pageLength: 10,
			paging: true,
			lengthMenu: [10, 20, 50],
			searching: true,
			ordering: false,
			info: true,
			responsive: true,
			autoWidth: false,
			language: {
				paginate: {
					first: 'First',
					last: 'Last',
					next: 'Next',
					previous: 'Previous',
				},
				search: 'Search:',
			},
			data: myLadger,
			columns: [
				
				
				{ data: 'date', className: 'text-start', width: '10%'  },
				{ data: 'particulars' },
				{ 
					data: null,
					render : function (data, type, row){
						
						if(row.payment_type == 'c'){
							 return parseFloat(row.amount).toFixed(2);
						}else{
							return `0.00`;
						}
					} 
				},
				{ 
					data: null,
					render : function (data, type, row){
						
						if(row.payment_type == 'd'){
							 return parseFloat(row.amount).toFixed(2);
						}else{
							return `0.00`;
						}
					} 
				},
				{ 
					data: null,
					render : function (data, type, row){
						
						return parseFloat(row.balance).toFixed(2);
					} 
				},


			],
			columnDefs: [

			]
		});

		


		return () => {
			table.destroy();
		};
	}, [myLadger]);

	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/account/my_ledger">Account</Link> 
		                  <Link to="/account/my_ledger">My Ledger</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">
			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>My Ledger </b></p>

	                    <div className="row" >
	              			<div className="col-md-9 col-lg-9 col-sm-12">
	                			<CustomDateRangePicker dates={dates} onChange={setDates} onSubmit={searchFormData} />
	              			</div>
		            	</div>


		            	<div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
							<table className="table table-bordered dataTable" id="myLadgerList">
								<thead>
									<tr>
										<th>Date</th>
										<th>Particulars</th>
										<th>Credit</th>
										<th>Debit</th>
										<th>Balance</th>
									</tr>
								</thead>
								<tbody>

								</tbody>
							</table>
						</div>

	                </div>
	            </div>
	        </div>
	      	<Footer />
		</>
	);

}
export default MyLadger;