import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import config from "../common/Config";

const SaleOrder = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [newSales, setNewSales] = useState([]);
  const accessToken = localStorage.getItem("accessToken");

  const s = localStorage.getItem('startDate');
  const e = localStorage.getItem('endDate');

  let dateRange = '';
  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/login";
      return;
    }

    fetchPageData();
  }, []);

  const fetchPageData = async () => {
    const formData = new FormData();
    formData.append("draw", draw);
    formData.append("start", start);
    formData.append("length", length);
    formData.append("start_date", s ? s : "");
    formData.append("end_date", e ? e : "");

    const apiUrl = `${config.API_URL}/get_dashboard_sales_order`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Not Order Report");
      }
      const data = await response.json();
      setNewSales(data.data);
    } catch (error) {
      console.error("api fail Error fetching Not Order Report:", error);
    }
  };

  const handleSubmit = async () => {
    fetchPageData();
  };

  useEffect(() => {
    const table = $("#neworderTable").DataTable({
      pageLength: 500,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      columns: [
        {
          data: "date",
          render: function (data, type, row) {
            const [year, month, day] = row.date.split("-");
            const formattedDate = `${day}-${month}-${year}`;
            return formattedDate;
          },
          createdCell: function (td) {
            $(td).css("text-align", "center");
          },
        },
        {
          data: "reference_no",
          render: function (data, type, row) {
            return `${row.reference_no}`;
          },
          createdCell: function (td) {
            $(td).css("text-align", "center");
          },
        },
        { data: "customer" },
        { data: "grand_total" },
        { data: "spc" },
        { data: "prev_due" },
        {
          data: "route_number",
          createdCell: function (td) {
            $(td).css("text-align", "center");
          },
        },
        { data: "name" },
      ],
      columnDefs: [
        {
          targets: [0], // Make sure the checkbox column is non-orderable
          orderable: false,
        },
        {
          targets: [1, 2, 3, 4, 5, 6, 7], // Allow sorting on other columns
          orderable: true,
        },
      ],
    });
  
    // Whenever newSales data is updated, update the DataTable
    if (newSales && newSales.length > 0) {
      table.clear(); // Clear the existing table data
      table.rows.add(newSales); // Add the new data
      table.draw(); // Redraw the table to reflect the changes
    }
  
    // Cleanup when the component unmounts
    return () => {
      table.destroy();
    };
  }, [newSales]);
  

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="#">Sales</Link>
              <Link to="#">Sales Order</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">

        {/* <div className="col-md-12 col-lg-12 col-xs-12">
            <div className="col-md-12 col-xs-12">
            <div className="row">
		        	<div className="col-md-12 col-lg-12 col-sm-12">
		        		<div className="date-range-picker col-md-2 col-lg-2 col-sm-3" style={{float: 'left'}}>	
		            		<label style={{marginBottom: '5px'}} ><b>From Date</b></label>
		            		<input
                        type="date"
                        name="fromDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="form-control"/>
		            </div>
		            <div className="date-range-picker col-md-2 col-lg-2 col-sm-3" style={{float: 'left', paddingLeft: '15px'}}>	
		            		<label style={{marginBottom: '5px'}} ><b>To Date</b></label>
		            		<input
                        type="date"
                        name="toDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="form-control"
                      />
		            </div>
		            <div>
                  <button type="submit" onClick={handleSubmit}  className="acceptbtn" style={{float: 'left', margin: '26px 0 0 15px'}} >Submit</button>
								</div>
              </div>
		        </div>
          </div>
        </div> */}


          <div className="total-manifests-area dashboard"  style={{ position: "relative" }} >
            <div  className="verical-scrl-wrapper common-table autoheight-table"  id="style-3"  >
            
              <table className="table table-bordered" id="neworderTable">
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>Order Date</th>
                    <th style={{textAlign:'center'}}>Order Id</th>
                    <th style={{textAlign:'center'}}>Customers (A/C No)</th>
                    <th style={{textAlign:'center'}}>Order Amt</th>
                    <th style={{textAlign:'center'}}>Rep Cash</th>
                    <th style={{textAlign:'center'}}>Previous Dues</th>
                    <th style={{textAlign:'center'}}>Route</th>
                    <th style={{textAlign:'center'}}>Order By</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SaleOrder;
