import React from 'react';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';


const Proforma_Invoice = ({ tableRows, totalVal, customerDetails,companyAddress}) => {
  const today = new Date();
  const formattedDate = today.toLocaleDateString();

  const handlePrint = (e) => {
    e.preventDefault();
    
    // Hide the print button before printing
    const printButton = document.getElementById('print-button');
    if (printButton) {
      printButton.style.display = 'none';
    }

    // Store the original contents of the body
    const originalContents = document.body.innerHTML;

    // Get the content to print
    const printContents = document.getElementById('modal-body-content').innerHTML;

    // Replace body content with the invoice content
    document.body.innerHTML = printContents; 
    window.print(); // Trigger print dialog
    document.body.innerHTML = originalContents; // Restore original content

    // Reload the page to reset modal (optional)
    window.location.reload();
  };

  return (
    <div className="container">
      <div className="modal fade" id="proforma_invoice" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Proforma Invoice</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" id="modal-body-content">
              <div className="performa-invoice-popup">
                <a href="#" id="print-button" onClick={handlePrint} style={{ textDecoration: 'none', color: 'blue' }}>Print</a>

                <div className="sales-header">
                  <div className="row">
                    <div className="col-6">
                      <p>{companyAddress.shop_name}<br />
                         {/* Address Line 1<br />
                         Address Line 2<br />
                         City, Postal Code<br />
                         Tel: +44 75855 91189<br />
                         VAT No: VAT01<br />
                         Company Reg No: AWRSXEAW000000116976 */}
                         {companyAddress.address}
                      </p>
                    </div>
                    <div className="col-6">
                      <ul>
                        <li>PLEASE PHONE THE OFFICE TO PAY BY CREDIT/DEBIT CARD</li>
                        <li>Bank A/C: 111111111</li>
                        <li>Sort Code: 222222</li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-6">
                      <p><strong>Customer:</strong><br />
                        {customerDetails.name}<br />
                        {customerDetails.address}<br />
                        {customerDetails.city}, {customerDetails.postal_code}
                      </p>
                    </div>
                    <div className="col-6">
                      <ul>
                        <li><strong>Invoice No:</strong> INV27A07130</li>
                        <li><strong>Proforma Invoice Date:</strong> {formattedDate}</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="sales-view-table">
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Unit Price</th>
                        <th>VAT Amount</th>
                        <th>Net Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableRows.length > 0 ? (
                        tableRows.map((row, index) => (
                          <tr key={row.id}>
                            <td>{index + 1}</td>
                            <td>{row.code}-{row.name}</td>
                            <td>{row.order_qty}</td>
                            <td>{row.price}</td>
                            <td>{row.vat}</td>
                            <td>{row.subtotal}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="sales-footer">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td rowSpan="6" className="text-center">Quantity Received: {totalVal.boxTotal} Box/ {totalVal.pieceTotal} PCs</td>
                        <td className="text-end"><strong>Total Std Goods</strong></td>
                        <td className="text-end">{totalVal.totalGoods}</td>
                      </tr>
                      <tr>
                        <td className="text-end"><strong>VAT Amount</strong></td>
                        <td className="text-end">{totalVal.totalTax}</td>
                      </tr>
                      <tr>
                        <td className="text-end"><strong>Total Zero Goods</strong></td>
                        <td className="text-end">{totalVal.totalzeroGoods}</td>
                      </tr>
                      <tr>
                        <td className="text-end"><strong>Promotion Discount</strong></td>
                        <td className="text-end">0.00</td>
                      </tr>
                      <tr>
                        <td className="text-end"><strong>Order Discount</strong></td>
                        <td className="text-end">{totalVal.orderDiscount}</td>
                      </tr>
                      <tr>
                        <td className="text-end"><strong>Invoice Total</strong></td>
                        <td className="text-end">{totalVal.grandTotal}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* Optional modal footer can be added here */}
          </div>
        </div>
      </div>

      <style jsx>{`
        @media print {
          .modal-content {
            border: none !important; /* Remove border from modal content */
          }
          
          #print-button {
            display: none !important; /* Hide the print button */
          }

          /* Additional print-specific styles can be added here */
        }
      `}</style>
    </div>
  );
};

export default Proforma_Invoice;
