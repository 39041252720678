import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../common/Config";
import generatePickerListPDF from './PickerListPDF';

const ViewPickingList = () =>{

	const { picklistNo } = useParams();
	const accessToken = localStorage.getItem('accessToken');

	const [headerData, setHeaderData] = useState({
	    customerName: '',
	    orderNumber: '',
	    accountNumber: '',
	    routeNumber : '',
	    pickingList : '',
	    deliveryDate : '',
	    printBy : '',
	    printDate : '',
	});
	const [pickList, setPickList] = useState([]);
	const [pickComment, setPickComment] = useState([]);
	const [totalQty, setTotalQty] = useState([]);


	const formatDate = (date) => {
	    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero
	    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed) and pad
	    const year = date.getFullYear(); // Get year

	    return `${day}-${month}-${year}`; // Format as DD-MM-YYYY
	};


	const handleGeneratePickerListPDF = () => {
	    generatePickerListPDF(picklistNo);
	}

	const fetchViewPickerList = async () => {
		const  formData = new FormData();
	    formData.set('picklist_number', picklistNo);

	    const apiUrl = `${config.API_URL}/get_view_picklist`;
    	try {
		    const response = await fetch(apiUrl, {
		        method: 'POST',
		        headers: {
		          authorization: `Bearer ${accessToken}`,
		        },
		        body: formData,
		    });

		    if (!response.ok) {
		        throw new Error('Failed to fetch Picker List');
		    }

		    const data = await response.json();
		    
		    console.log(data.data);
		    setHeaderData({
		        customerName: data.data.reference_no[0].customer,
			    orderNumber: data.data.reference_no[0].reference_no,
			    accountNumber: data.data.reference_no[0].accound_no,
			    routeNumber : data.data.reference_no[0].route_number,
			    pickingList : data.data.picklist_number,
			    deliveryDate : data.data.reference_no[0].deliverydate,
			    printBy : data.data.reference_no[0].print_by,
			    printDate: data.data.reference_no[0].print_on,
		    });
		    setPickList(data.data.packing_details);
		    setPickComment(data.data.accound_no);

		    let tQty = data.data.packing_details.reduce((acc, product) => acc + parseInt(product.quantity), 0);
	    	setTotalQty(tQty);
		   
		} catch (error) {
		      console.error('Error fetching Picker List data:', error);
		}

	    

	};

	useEffect(() => {
    
	    if (accessToken === null) {
	      window.location.href = '/login';
	      return;
	    }

	    fetchViewPickerList();
	  }, []);

	return(
		<>
			<Header />

      		<div class="breadcrumbs">
			  	<div class="container-fluid">
			    	<div class="row">
			      		<div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <span class="last-crumb">Picking List :- {picklistNo}</span> 
			      		</div>
			    	</div>
			  	</div>
			</div>
			<div className="row">
				<div className="col-7">
					
					<button onClick={(e) => handleGeneratePickerListPDF()}>
					  Generate PDF
					</button>
				</div>
			</div>
			<div className="row">
        		<div className="col-12">
        			<div className="show-order-area dashboard">
        				<div className="sales-view-area" style={{ width: '98%', border: 'none' }}>

        					<div className="row page-header" style={{ paddingTop: '10px' }}>
        						<div className="col-4">
        							<ul>
								        <li><b>Customer Name :</b> {headerData.customerName}</li>
								        <li><b>Order Number  :</b> {headerData.orderNumber}</li>
								        <li><b>Account Number :</b> {headerData.accountNumber}</li>
								        <li><b>Route Number :</b> {headerData.routeNumber}</li>
		        					</ul>
        						</div>
	        					<div className="col-4">
		        					<ul>
								        <li><b>Picking List :</b> {headerData.pickingList}</li>
								        <li><b>Delivery Date :</b> {headerData.deliveryDate}</li>
								        <li><b>Print by :</b> {headerData.printBy}</li>
								        <li><b>Print Date :</b> {headerData.printDate}</li>
								        
		        					</ul>
	        					</div>
        					</div>

        					<div className="sales-view-table">
						       	<table className="table table-bordered" id="purchaseProforma">
								    <thead>
								        <tr>
								            <th style={{ backgroundColor: '#eee9e9', width: '10%' }}>Product Code</th>
								            <th style={{ backgroundColor: '#eee9e9' }}>Product Name</th>
								            <th style={{ backgroundColor: '#eee9e9' }}>Product Type</th>
								            <th style={{ backgroundColor: '#eee9e9' }}>Quantity</th>
								            

								            
								        </tr>
								    </thead>
						   			<tbody>
						   				{pickList.map((item, index) => (
						                    <tr key={item.code}>
						                      
						                        <td>{item.code}</td>
						                        <td>{item.name}</td>
						                        <td>{item.order_type}</td>
						                        <td>{parseInt(item.quantity)}</td>
						                    </tr>
						                ))}
							           
						   			</tbody>
						   			<tfoot>
									    <tr>
									        

									        <td colSpan={2}><span></span></td>
									        

									        <td><span>Total</span></td>
									        <td><span>{totalQty}</span></td>
									    </tr>
									</tfoot>
						   		</table>
						   	</div>

						   	<div className="sales-view-table">
						       	<table className="table table-bordered" id="purchaseProforma">
								    <thead>
								        <tr>
								            <th style={{ backgroundColor: '#eee9e9', width: '10%' }}>A/c No</th>
								            <th style={{ backgroundColor: '#eee9e9' }}>Comment</th>
								            <th style={{ backgroundColor: '#eee9e9' }}>Comment By</th>
								            

						
								        </tr>
								    </thead>
						   			<tbody>
						   				{pickComment.map((item) => {
						                    const rows = []; 

						                    // Check for staff_note
						                    if (item.staff_note) {
						                        rows.push(
						                            <tr key={`${item.id}-staff-note`}>
						                                <td>{item.accound_no}</td>
						                                <td>{item.staff_note}</td>
						                                <td>{item.name}</td>
						                            </tr>
						                        );
						                    }

						                    // Check for note
						                    if (item.note) {
						                        rows.push(
						                            <tr key={`${item.id}-note`}>
						                                <td>{item.accound_no}</td>
						                                <td>{item.note}</td>
						                                <td>{item.name}</td>
						                            </tr>
						                        );
						                    }

						                    return rows; 
						                })}
							           
						   			</tbody>
						   		</table>
						   	</div>


        				</div>
        			</div>
        		</div>
        	</div>

			<Footer />

		</>

	);
}
export default ViewPickingList;