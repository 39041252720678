import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import config from "../common/Config";

const ManifestGenerated = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [newSales, setNewSales] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  let dateRange = '';
  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/login";
      return;
    }

    fetchPageData();
  }, []);

  const s = localStorage.getItem('startDate');
  const e = localStorage.getItem('endDate');

  const fetchPageData = async () => {
    const formData = new FormData();
    formData.append("draw", draw);
    formData.append("start", start);
    formData.append("length", length);
    formData.append("start_date", s ? s : "");
    formData.append("end_date", e ? e : "");

    const apiUrl = `${config.API_URL}/manifest_generated`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Not Order Report");
      }
      const data = await response.json();
      //	console.log(data.data);
      setNewSales(data.data);
    } catch (error) {
      console.error("api fail Error fetching Not Order Report:", error);
    }
  };

  useEffect(() => {
    const table = $("#neworderTable").DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      data: newSales,
      columns: [
        { data: null,
          render:function(data, type, row, meta)
          {
            return meta.row+1;
          }
        },
        {
          data: "date",
          render: function(data, type, row) {
            const date = new Date(data);
            const day = String(date.getDate()).padStart(2, '0'); 
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
          }
        },        
        {
          data: "manifest_id",
          render: function(data, type, row) {
            // Use template literals correctly for the href link
            return `<a href="${config.BASE_URL}/manifest_list/mark_delivery/${row.manifest_id}">${row.manifest_id}</a>`;
          },
          createdCell: function(td) {
            // Set the text alignment of the cell to center
            $(td).css("text-align", "center");
          }
        },              
        { data: "route_number" },
        { data: "model_name" },
        { data: null,
          render: function(data, type, row)
          {
            return row.first_name+' '+row.last_name;
          }
         },
      ],
      columnDefs: [
        {
          targets: [1, 2, 3, 4, 5],
          orderable: true,
        },
      ],
    });
    return () => {
      table.destroy();
    };
  }, [newSales]);

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="#">Manifest List</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div
            className="total-manifests-area dashboard"
            style={{ position: "relative" }}
          >
            <div
              className="verical-scrl-wrapper common-table autoheight-table"
              id="style-3"
            >
              <table className="table table-bordered" id="neworderTable">
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>#</th>
                    <th style={{textAlign:'center'}}>Date</th>
                    <th style={{textAlign:'center'}}>Manifest Number</th>
                    <th style={{textAlign:'center'}}>Route Number</th>
                    <th style={{textAlign:'center'}}>Vehicle Name</th>
                    <th style={{textAlign:'center'}}>Driver Name</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ManifestGenerated;
