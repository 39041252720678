import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import config from "../../common/Config";

const ExportUserList = ({ totalUsers, urlLink }) => {
  
  const accessToken = localStorage.getItem('accessToken');

  const exportUserList = async () => {
    const formData = new FormData();
    formData.append('draw', '1');
    formData.append('start', '0');
    formData.append("length", totalUsers);

    const apiActivePdtUrl = `${config.API_URL}/${urlLink}`; 
    
    try {
      const response = await fetch(apiActivePdtUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch Products');
      }

      const data = await response.json();

      const exportData = data.data.map(user => ({
        "User Name": user.username || '',
        "First Name": user.first_name || '',
        "Last Name": user.last_name || '', 
        Email: user.email || '',
        Company: user.company || '',
        "Roles" : user.roles || '',
        Status: user.active === '1' ? 'Active' : 'Inactive' 
      }));

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Active Products');

      // Get current date and time for the filename (e.g., '2024-11-07-14-30-45')
      const now = new Date();
      const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
      const formattedTime = `${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
      const filename = `user-list-${formattedDate}-${formattedTime}.xlsx`;

      
      XLSX.writeFile(wb, filename);
    } catch (error) {
      console.error('Error exporting Active Product:', error);
    }
  };

  return (
    <div>
      <button className="create-catalogue-btn" onClick={exportUserList} style={{textTransform : 'capitalize'}}>
        Export Users
      </button>
    </div>
  );
};

export default ExportUserList;
