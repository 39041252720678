// Footer.js
import React from 'react';

function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="copyright">© 2024 Ebusso</div>
      </div>
    </footer>
  );
}

export default Footer;
