import React, { useState, useEffect } from 'react';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import 'datatables.net';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";

const CrossOffReport = () => {
	const [draw, setDraw] = useState(1);
	const [start, setStart] = useState(0);
	const [length, setLength] = useState(50);
	const now = new Date();
	const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
	const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
	const [dates, setDates] = useState([startOfMonth, endOfMonth]);
	const [reportrange, setReportrange] = useState('');
	const [reportAmount, setReportAmount] = useState('');


	//const [pageOption, setPageOption] = useState('');

	const [pageCndDataOptions, setPageCndDataOptions] = useState([]);
	const [pageCndData, setPageCndData] = useState('');


	const [showProductWise, setShowProductWise] = useState(false);
	const [productWiseOptions, setProductWiseOptions] = useState([]);
	const [productWise, setProductWise] = useState('');
	const [productReport, setProductReport] = useState([]);


	const [showpickListOption, setShowpickListOption] = useState(false);
	const [pickListOptions, setPickListOptions] = useState([]);
	const [pickListWise, setPickListWise] = useState('');


	const [showSalesRepOption, setShowSalesRepOption] = useState(false);
	const [salesRepOptions, setSalesRepOptions] = useState([]);
	const [salesRepWise, setSalesRepWise] = useState('');

	const accessToken = localStorage.getItem('accessToken');


	const handleDateChange = (value) => {
		setDates(value);
	};
	const handleChangeOption = (event) => {
		const value = event.target.value;
		setPageCndData(value);
	};
	const handleChangePdt = (event) => {
		const value = event.target.value;
		setProductWise(value);
	};

	const handleChangePickOption = (event) => {
		const value = event.target.value;
		setPickListWise(value);
	};

	const handleChangeSRapOption = (event) => {
		const value = event.target.value;
		setSalesRepWise(value);
	};

	const searchFormData = () => {
		let startDate = format(dates[0], 'MM/dd/yyyy');
		let endDate = format(dates[1], 'MM/dd/yyyy');
		setReportrange(startDate + ' - ' + endDate);
		fetchProductWiseData();
	};


	const fetchProductOptions = async () => {
		let startDate = format(dates[0], 'MM/dd/yyyy');
		let endDate = format(dates[1], 'MM/dd/yyyy');
		let pdtrange = startDate + ' - ' + endDate;
		const pdtData = new FormData();
		pdtData.append('reportrange', pdtrange);

		const apiPdtOptionUrl = `${config.API_URL}/getproduct_for_report`;
		try {
			const response = await fetch(apiPdtOptionUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				body: pdtData,
			});
			const data = await response.json();
			const formattedOptions = data.product.map(item => ({
				key: `${item.name}`,
				value: item.id,
			}));
			setProductWiseOptions(formattedOptions);
		} catch (error) {
			console.error('api fail Error fetching Not Order Report:', error);
		}
	};

	const fetchPickListOptions = async () => {
		let startDate = format(dates[0], 'MM/dd/yyyy');
		let endDate = format(dates[1], 'MM/dd/yyyy');
		let pdtrange = startDate + ' - ' + endDate;
		const pdtData = new FormData();
		pdtData.append('reportrange', pdtrange);

		const apiPickListUrl = `${config.API_URL}/getpicklist`;
		try {
			const response = await fetch(apiPickListUrl, {
				method: 'POST',
				headers: {
					// 'Content-Type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
				body: pdtData,
			});

			const data = await response.json();
			const formattedOptions = data.picklist.map(item => ({
				key: `${item.picklist_number}`,
				value: item.picklist_number,
			}));
			setPickListOptions(formattedOptions);
		} catch (error) {
			console.error('api fail Error fetching Not Order Report:', error);
		}
	};

	const fetchSalesRepOptions = async () => {
		let startDate = format(dates[0], 'MM/dd/yyyy');
		let endDate = format(dates[1], 'MM/dd/yyyy');
		let pdtrange = startDate + ' - ' + endDate;
		const pdtData = new FormData();
		pdtData.append('reportrange', pdtrange);

		//const apiPickListUrl = `${config.API_URL}/getpicklist`;
		const apiPickListUrl = `${config.API_URL}/getSalesRep`;
		try {
			const response = await fetch(apiPickListUrl, {
				method: 'POST',
				headers: {
					// 'Content-Type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
				body: pdtData,
			});

			const data = await response.json();
			const formattedOptions = data.data.map(item => ({
				key: `${item.first_name}`,
				value: item.id,
			}));
			setSalesRepOptions(formattedOptions);
		} catch (error) {
			console.error('api fail Error fetching Not Order Report:', error);
		}
	};

	const fetchProductWiseData = async () => {

		let startDate = format(dates[0], 'MM/dd/yyyy');
		let endDate = format(dates[1], 'MM/dd/yyyy');
		let pdtrange = startDate + ' - ' + endDate;
		const pdtData = new FormData();
		let ptype = pageCndData;
		console.log(ptype)

		pdtData.append('draw', draw);
		pdtData.append('start', start);
		pdtData.append('length', length);
		pdtData.append('reportrange', pdtrange);
		pdtData.append('type', ptype ? ptype : 'product_wise');
		pdtData.append('product_id', productWise);
		pdtData.append('pick_list', pickListWise);
		pdtData.append('sales_rep_id', salesRepWise);

		
		const apiMissingProductUrl = `${config.API_URL}/get_missing_order_report`;

		try {
			const response = await fetch(apiMissingProductUrl, {
				method: 'POST',
				headers: {
					// 'Content-Type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
				body: pdtData,
			});

			if (!response.ok) {
				throw new Error('Failed to fetch Not Order Report');
			}
			const data = await response.json();
			data.type = ptype ? ptype : 'product_wise';
			console.log(data);
			setProductReport(data);
			setReportAmount(data.order_total + ' | Missing Amt : ' + data.missing_total);
		} catch (error) {
			console.error('api fail Error fetching Not Order Report:', error);
		}
	};



	useEffect(() => {

		if (accessToken === null) {
			window.location.href = '/login';
			return;
		}

		const options = [
			{ value: 'product_wise', key: 'Product Wise' },
			{ value: 'order_wise', key: 'Order Wise' },
			{ value: 'pick_list_wise', key: 'Pick List Wise' },
			{ value: 'sales_rep_wise', key: 'Sales Rep Wise' },
		];
		setPageCndDataOptions(options);

		fetchProductOptions();
		fetchProductWiseData();
		fetchPickListOptions();
		fetchSalesRepOptions();
	}, []);


	useEffect(() => {
		if (pageCndData) {
			fetchProductWiseData();
		}
	}, [pageCndData]);


	const getColumnsForType = (type) => {
		switch (type) {
			case 'product_wise':
				return [
					{ data: null },
					{ data: 'product_name' },
					{ data: 'order_type' },
					{ data: 'total_items' },
					{ data: 'missing_total' }
				];
			case 'order_wise':
				return [
					{ data: null },
					{ data: 'date' },
					{ data: 'reference_no' },
					{ data: 'customer' },
					{ 
						data: null,
						render : function(data, type, row){
							return `<span>${parseFloat(row.order_total).toFixed(2)}</span>`;
						} 
					},
					{ data: 'missing_total' },
					{ data: 'return_total' },
					{ data: 'deliverd_total' },
					{ data: 'return_total' },
					{ data: 'sale_status' }
				];
			case 'pick_list_wise':
				return [
					{ data: null },
					{ data: 'date' },
					{ data: 'reference_no' },
					{ data: 'customer' },
					{ 
						data: null,
						render : function(data, type, row){
							return `<span>${parseFloat(row.order_total).toFixed(2)}</span>`;
						} 
					},
					{ data: 'missing_total' },
					{ data: 'return_total' },
					{ data: 'deliverd_total' },
					{ data: 'return_total' },
					{ data: 'sale_status' }
				];
			case 'sales_rep_wise':
				return [
					{ data: null },
					{ data: 'date' },
					{ data: 'reference_no' },
					{ data: 'customer' },
					{ 
						data: null,
						render : function(data, type, row){
							return `<span>${parseFloat(row.order_total).toFixed(2)}</span>`;
						} 
					},
					{ data: 'missing_total' },
					{ data: 'return_total' },
					{ data: 'deliverd_total' },
					{ data: 'return_total' },
					{ data: 'sale_status' }
				];
			default:
				return [];
		}
	};

	useEffect(() => {

		const clearTable = () => {
			if ($.fn.DataTable.isDataTable('#CrossReport')) {
				$('#CrossReport').DataTable().destroy();
				$('#CrossReport').empty(); // Clear existing data and headers
			}
		};

		const initializeDataTable = () => {
			const columns = getColumnsForType(productReport.type);
			const headers = getHeadersForType(productReport.type);
			clearTable();

			const table = $('#CrossReport').DataTable({
				pageLength: 50,
				paging: true,
				lengthMenu: [10, 20, 50],
				searching: true,
				ordering: true,
				info: true,
				responsive: true,
				autoWidth: false,
				language: {
					paginate: {
						first: 'First',
						last: 'Last',
						next: 'Next',
						previous: 'Previous',
					},
					search: 'Search:',
				},
				data: productReport.data,
				columns: columns,
				drawCallback: function (settings) {
					var api = this.api();
					var startIndex = api.page.info().start;
					api.column(0, { page: 'current' }).nodes().each(function (cell, i) {
						cell.innerHTML = startIndex + i + 1;
					});
				}
			});

			// Update the table header
			$('#CrossReport thead').html(headers.join(''));
		};

		if (productReport.type === "product_wise" || productReport.type === "order_wise" || productReport.type === "pick_list_wise" || productReport.type === "sales_rep_wise") {
			initializeDataTable();
		}

		// Cleanup DataTable on component unmount or when `productReport` changes
		return () => {
			clearTable();
		};
	}, [productReport]);


	const getHeadersForType = (type) => {
		switch (type) {
			case 'product_wise':
				return ["<tr>",
					"<th>#</th>",
					"<th>Product (Code)</th>",
					"<th>Order Type</th>",
					"<th>Qty</th>",
					"<th>Missing Amt</th>",
					"</tr>"
				];
			case 'order_wise':
				return ["<tr>",
					"<th>#</th>",
					"<th>Order Date</th>",
					"<th>Order Id</th>",
					"<th>Customers (A/c No)</th>",
					"<th>Order Amt</th>",
					"<th>Missing Qty</th>",
					"<th>Missing Amt</th>",
					"<th>Delivered Amt</th>",
					"<th>Return Amt</th>",
					"<th>Status</th>",
					"</tr>"
				];
			case 'pick_list_wise':
				return ["<tr>",
					"<th>#</th>",
					"<th>Order Date</th>",
					"<th>Order Id</th>",
					"<th>Customers (A/c No)</th>",
					"<th>Order Amt</th>",
					"<th>Missing Qty</th>",
					"<th>Missing Amt</th>",
					"<th>Delivered Amt</th>",
					"<th>Return Amt</th>",
					"<th>Status</th>",
					"</tr>"
				];
			case 'sales_rep_wise':
				return ["<tr>",
					"<th>#</th>",
					"<th>Order Date</th>",
					"<th>Order Id</th>",
					"<th>Customers (A/c No)</th>",
					"<th>Order Amt</th>",
					"<th>Missing Qty</th>",
					"<th>Missing Amt</th>",
					"<th>Delivered Amt</th>",
					"<th>Return Amt</th>",
					"<th>Status</th>",
					"</tr>"
				];
			default:
				return [];
		}
	};

	/*useEffect(() => {
		if (productReport.type == "product_wise") {
			setShowProductWise(true);
		} else {
			setShowProductWise(false);
		}

		if (productReport.type == "pick_list_wise") {
			setShowpickListOption(true);
		} else {
			setShowpickListOption(false);
		}

		if (productReport.type == "sales_rep_wise") {
			setShowSalesRepOption(true);
			console.log("show sales_rep_wise");
		} else {
			setShowSalesRepOption(false);
		}
		console.log(productReport.type);
	}, [productReport.type]);*/

	useEffect(() => {
	   
	    setShowProductWise(false);
	    setShowpickListOption(false);
	    setShowSalesRepOption(false);

	    
	    
	    switch (pageCndData) {
	        case "product_wise":
	            setShowProductWise(true);
	            break;
	        case "pick_list_wise":
	            setShowpickListOption(true);
	            break;
	        case "sales_rep_wise":
	            setShowSalesRepOption(true);
	            console.log("show sales_rep_wise");
	            break;
	        default:
	            break; 
	    }
	    if(pageCndData == ""){
	    	 setShowProductWise(true);
	    }

	    console.log(pageCndData);
	}, [pageCndData]);


	return (
		<div>
			<Header />
			<div className="breadcrumbs">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-lg-12 col-sm-12">
							<Link to="/Dashboard"><i className="fa fa-home"></i></Link>
							<Link to="/reports/missing_order">Reports</Link>
							<Link to="/reports/missing_order">Cross off report</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="main-container">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-sm-12">
							<div className="col-md-2 cp-lg-2 col-sm-2" style={{ float: 'left' }}>
							<label style={{marginBottom: '5px'}} ><b>Select Date</b></label>
								<DateRangePicker value={dates}
									onChange={handleDateChange}
									format="MM/dd/yyyy"
									character=" - "
									style={{ width: '100%' }}
								/>
							</div>
							<div className="col-md-2 col-lg-2 col-sm-2" style={{ float: 'left', padding: '0px 0px 0px 15px' }}>

							<label style={{marginBottom: '5px'}} ><b>&nbsp;</b></label>

								<select value={pageCndData} onChange={handleChangeOption} className="form-control selectinput" name="" id="" >

									{pageCndDataOptions.map(option => (
										<option key={option.value} value={option.value}>
											{option.key}
										</option>
									))}
								</select>


							</div>
							{showProductWise && (
							<div className="col-md-2 col-lg-2 col-sm-2" style={{ float: 'left', padding: '0px 0px 0px 15px' }}>

<label style={{marginBottom: '5px'}} ><b>&nbsp;</b></label>

									<select value={productWise} onChange={handleChangePdt} className="form-control selectinput" name="" id="" >
										<option value="">Select Product</option>
										{productWiseOptions.map(option => (
											<option key={option.value} value={option.value}>
												{option.key}
											</option>
										))}
									</select>

								
							</div>
							)}
							{showpickListOption && (
							<div className="col-md-2 col-lg-2 col-sm-2" style={{ float: 'left', padding: '0px 0px 0px 15px' }}>
								
								<label style={{marginBottom: '5px'}} ><b>&nbsp;</b></label>
									<select value={pickListWise} onChange={handleChangePickOption} className="form-control selectinput" name="" id="" >
										<option value="">Select Pick List</option>
										{pickListOptions.map(option => (
											<option key={option.value} value={option.value}>
												{option.key}
											</option>
										))}
									</select>

								
							</div>
							)}
							{showSalesRepOption && (
							<div className="col-md-2 col-lg-2 col-sm-2" style={{ float: 'left', padding: '0px 0px 0px 15px' }}>
								
								<label style={{marginBottom: '5px'}} ><b>&nbsp;</b></label>
									<select value={salesRepWise} onChange={handleChangeSRapOption} className="form-control selectinput" name="" id="" >
										<option value="">Select Sales Rap</option>
										{salesRepOptions.map(option => (
											<option key={option.value} value={option.value}>
												{option.key}
											</option>
										))}
									</select>

								

							</div>
							)}
							<div className="col-md-2 col-lg-2 col-sm-2" style={{ float: 'left', padding: '25px 0 0 15px' }} >
								<button type="button" onClick={searchFormData} className="acceptbtn" style={{ float: 'left', margin: '3px 0 0 0px' }}>Submit</button>
							</div>





						</div>
					</div>

					<div className="total-manifests-area dashboard">
						<p className="text-center" style={{ border: 'none', padding: '0px', margin: '0px' }} ><b>Order Amt : {reportAmount} </b></p>
						<div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
							<table className="table table-bordered" id="CrossReport">
								<thead>

								</thead>
								<tbody>

								</tbody>

							</table>
						</div>
					</div>

				</div>
			</div>


			<Footer />
		</div>
	);
}

export default CrossOffReport;